import {Dropdown, notification } from "antd";
import {useContext, useState} from "react";
import {ConfiguratorContext } from "../../context";
import SourcewellLetter from "./SourcewellLetter";
import {MoreOutlined } from "@ant-design/icons";
import {Permission, SOURCEWELL_INCENTIVE_ID} from "../../api/models";
import ExportQuotePdf from "./ExportQuotePdf";
import ExportSalesOrder from "./ExportSalesOrder";
import BMButton from "../BMButton";
import ExportLinesetButton from "./ExportButtons/ExportLinesetButton";
import ExportRevisionToEpicorButton from "./ExportButtons/ExportRevisionToEpicorButton";
import ExportBOMButton from "./ExportButtons/ExportBOMButton";
import GenerateOrderConfirmation from "./GenerateOrderConfirmation";
import { useQuoteContext } from "../../contexts/QuoteContext";
import { Link } from "react-router-dom";
import { ItemType } from "antd/lib/menu/hooks/useItems";

const QuoteExportsDropDown = (props: { 
  selectedRevisionId: number | undefined
  pendingChanges: boolean,
  allSelected: boolean,
  disabled?:boolean
} ) => {
  const { pendingChanges, selectedRevisionId, allSelected } = props;

  const configurator = useContext(ConfiguratorContext);

  const { quoteAsync } = useQuoteContext();
  const quote = quoteAsync?.val;

  const [isExporting, setIsExporting] = useState<boolean>();

  const isSourcewell = quote?.incentivePrograms.map(v => v.id).includes( SOURCEWELL_INCENTIVE_ID );

  const actionMenuItems:ItemType[]  = [ 
    {
        key: "quotePdf",
        label: <ExportQuotePdf
              pendingChanges={pendingChanges}
              onExporting={setIsExporting}
            />
      } ,
    {
        key: "onePage",
        label: <Link style={{paddingLeft: "1rem"}} to={`/inventory/${quote?.quoteId}`} target="_blank">One Page View</Link>
      } 
  ];

  if ( configurator.isEngineering() ) {
    actionMenuItems.push( {
      key: "exportBom",
      label: <ExportBOMButton quote={quote} onExporting={setIsExporting} pendingChanges={pendingChanges} />
    } );
  }

  if (isSourcewell && ( configurator.isSourcewellManager() || configurator.isAdmin() ) ) {
    actionMenuItems.push( {
      key: 1.3,
      label: <SourcewellLetter
        quote={quote}
        pendingChanges={pendingChanges}
        onExporting={setIsExporting}
      />
    } );
  }

  if ( configurator.isAdmin() || configurator.isEngineering() ) {

    actionMenuItems.push( {
      key: 1.4,
      label: <ExportRevisionToEpicorButton type="text" className="ghostBmButton" quote={quote} pendingChanges={pendingChanges} onExporting={setIsExporting} />
    } );
  }

  if(configurator.hasPermission(Permission.SALES_ORDER_WRITE)) {
    actionMenuItems.push( {
      key: 1.5,
      label: <ExportSalesOrder type="text" className="ghostBmButton" quote={quote} revisionId={selectedRevisionId}/>
    } );
  }

  if ( configurator.hasPermission(Permission.LINESET_TICKET_READ) ) {
    actionMenuItems.push( {
      key: 1.6,
      label: <ExportLinesetButton type="text" className="ghostBmButton" quote={quote} pendingChanges={pendingChanges} allSelected={allSelected} />
    } );
  }

  if ( configurator.hasPermission(Permission.GENERATE_ORDER_CONFIRMATION) ) {
    actionMenuItems.push( {
      key: 1.7,
      label: <GenerateOrderConfirmation type="text" className="ghostBmButton"  quote={quote} onExporting={setIsExporting} />
    } );
  }

  const handleDisabledClick = () => {
    const disabledMsg = !quote ? "The quote must be saved before exporting."
      : pendingChanges ? "There are pending changes. Please save the quote first."
        : undefined;
        notification.warning({message:disabledMsg});
  }

  return <Dropdown trigger={["click"]}
      menu={{items:actionMenuItems}}
      destroyPopupOnHide={true}
      forceRender={true}
      disabled={!quote || pendingChanges}
    >
    {/* this div is to avoid a warning with strict mode */}
    <div>
    <BMButton 
      onDisabledClick={handleDisabledClick} 
      type="primary" 
      disabled={!quote || pendingChanges}
      icon={<MoreOutlined />} 
      loading={isExporting}>Export</BMButton>
    </div>
    </Dropdown>

}

export default QuoteExportsDropDown;

