import React, {ReactElement, useContext, useMemo} from "react";
import { CategoryIdAssembliesIdMap, CustomOptionType } from "../api/models";
import { SelectedModelInfo } from "../components/Quote/ModelSelectionWizard";

export interface QuoteFormContextType {
  selectedModel?:SelectedModelInfo
  selectedOptions?:CategoryIdAssembliesIdMap
  selectedCustomOptions?:CustomOptionType[]
}
const QuoteFormContext = React.createContext<QuoteFormContextType>({
});

export const useQuoteFormContext = () : QuoteFormContextType => {
    return useContext(QuoteFormContext);
}

// Data provider component
const QuoteFormContextProvider = (props:{ value:QuoteFormContextType, children: ReactElement | ReactElement[] }) => {

  const quoteContext = useMemo(() => props.value, [
      props.value.selectedModel,
      props.value.selectedOptions,
      props.value.selectedCustomOptions 
    ]);

  return <QuoteFormContext.Provider value={quoteContext}>{props.children}</QuoteFormContext.Provider>;
};
  
export default QuoteFormContextProvider;

