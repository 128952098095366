import "../util/mobile-table.css";
import styles from "./quotes.module.css";
import Title from "antd/lib/typography/Title";
import { Table, Input, Space, TablePaginationConfig, notification, Card, Row, Col, Collapse, Form } from "antd";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext } from "../context";
import { Link } from "react-router-dom";
import { NumberParam, NumericArrayParam, StringParam, useQueryParam } from "use-query-params";
import { BaseQuote, QuoteFilter, SortDirection, Truck } from "../api/models";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import { debounce } from 'lodash';
import { ColumnType } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import useCheckMobileScreen from '../hook/useCheckMobileScreen';
import AssemblySelector from "../components/assembly_selector";
import ExportQuotesModal from "../components/Quote/ExportQuotesModal";
import {useForm} from "antd/es/form/Form";
import _ from "lodash";
import IncentiveProgramSelector from "../components/incentive_program_selector";
import Descriptions, { DescriptionsItemType } from "antd/es/descriptions";
import axios, {CancelTokenSource} from "axios";
import { useIntl } from "react-intl";
import { AsyncData } from "../api/async_data";
import dayjs from "dayjs";
import { AdvancedSearchConfig } from "../components/QuoteFilterControls";

type BaseQuoteSort = SorterResult<BaseQuote> | SorterResult<BaseQuote>[]
type TableOnChangeFn = (p: TablePaginationConfig, f: Record<string, FilterValue | null>, s: BaseQuoteSort) => void

const AXIOS_CANCEL_MSG = "axios.cancel";

const stockFilterDefaults = {
  stock:true
}

const Inventory = () => {
  const isMobile = useCheckMobileScreen();
  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [_quoteList, quoteListAsync] = useAsyncState<BaseQuote[]>([]);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string | undefined | null>("filter", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number | undefined | null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number | undefined | null>("p", NumberParam);
  const [filterAssemblies, setAssembliesFilter] = useQueryParam("assemblies", NumericArrayParam);
  const [incentiveProgramsParam, setIncentiveProgramsParam] = useQueryParam<string|undefined|null>("incentivePrograms", StringParam);
  const [sort, setSort] = useState<BaseQuoteSort>({
    columnKey: 'updatedAt',
    order: 'descend'
  });
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const [quoteTrucks, setQuoteTrucks] = useState<Record<number,AsyncData<Truck[]>>>();

  const [filter, setFilter] = useState<QuoteFilter>({
    search: searchFilterParam || undefined, //silly fix for null
    incentivePrograms: incentiveProgramsParam?.split(",") || undefined, //silly fix for null
    filterAssemblies: filterAssemblies as number[] || undefined,
  });


  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? 20 : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
  });


  useEffect(() => {
    if (quoteListAsync.isFail()) {
      notification.error({ message: "Quotes failed to load. " + quoteListAsync.err });
    }
  }, [quoteListAsync.state]);

  useEffect(() => {
    loadQuotes(quoteListAsync,  pagination, filter, sort);
  }, [pagination.pageSize, pagination.current, filter, sort]);

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current]);


  const loadQuotes = useCallback(debounce((quoteListAsync:AsyncState<BaseQuote[]>,  pagination: TablePaginationConfig, filter: QuoteFilter, sorter:BaseQuoteSort) => {

    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || "updatedAt",
      direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
    }));

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    quoteListAsync.setLoading();
    configurator.api.listQuotes({
        ...filter,
        ...stockFilterDefaults,
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize || 20,
        sort,
      },
      cancelSource.token
    )
    .then(resp => {
      quoteListAsync.setDone(resp.data.content);
      setPagination({ ...pagination, total: resp.data.totalElements });
    },
      (e: any) => {
        const id = e.response?.data?.message || e.message ;
        if ( id !== AXIOS_CANCEL_MSG ) {
          const errorMsg = intl.formatMessage({ id });

          quoteListAsync.setFail(errorMsg);
        }
      })
    .finally(() => {
      cancelTokenSourceRef.current = undefined;
    });

  }, 600), []);

  const loadQuoteTrucks = async (truckLstAsync:AsyncData<Truck[]>, quoteRevisionId:number | undefined ) : Promise<AsyncData<Truck[]> | undefined> => {

    if ( !quoteRevisionId ) return;

    truckLstAsync.loading();
    setQuoteTrucks( {...quoteTrucks, [quoteRevisionId]:truckLstAsync.loading() } );

    try {
      const resp = await configurator.api.getTrucks(quoteRevisionId);
      return truckLstAsync.done(resp.data);

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch trucks. " + errorMsg });
      truckLstAsync.fail(e.message);
    }

    return truckLstAsync;
  }

  const handleExpand = (expanded: boolean, record: BaseQuote) => {
    if ( expanded ) {

      const quoteRevisionId = record.displayRevisionId;
      const truckLstAsync = quoteTrucks?.[ quoteRevisionId ] || new AsyncData<Truck[]>();
      loadQuoteTrucks(truckLstAsync, quoteRevisionId )
        .then( rslt => rslt && setQuoteTrucks( {...quoteTrucks, [quoteRevisionId]:rslt } ) );
    }
  }


  const tableOnChange = (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: BaseQuoteSort) => {
    setPagination(pagination);

    setSort(sorter);
  };

  const onFilterChange = debounce( (_values: Record<string, any>, filter:QuoteFilter) => {
    setSearchFilterParam(filter.search);
    setAssembliesFilter(filter.filterAssemblies);
    setIncentiveProgramsParam(filter.incentivePrograms?.join(","));
    setPagination({ ...pagination, current: 1 });
    setFilter(filter);
  }, 300);

  return (
    <div className="site-layout-background">
      <Space direction="vertical" size="small" style={{ display: 'flex' }}>
        <div>
          <Title level={2}>Inventory</Title>
          <Title level={5} style={{marginTop: 0}}>If any of these trucks meet your needs, reach out to your regional sales manager for more information.</Title>
        </div>
        <FilterControls
          filter={filter}
          onFilterChange={onFilterChange}
        />
        {isMobile
          ? <MobileTable
            quoteListAsync={quoteListAsync}
            tableOnChange={tableOnChange}
            pagination={pagination}
          />
          : <DesktopTable
            quoteListAsync={quoteListAsync}
            tableOnChange={tableOnChange}
            pagination={pagination}
            filter={filter}
            handleExpand={handleExpand}
            quoteTrucks={quoteTrucks}
            sort={sort}
          />
        }
      </Space>
    </div>
  );
};

const FilterControls = (props: {
  filter?: QuoteFilter
  onFilterChange: (_values: Record<string, any>, filter:QuoteFilter) => void
}) => {
  const { filter } = props;

  const [filterForm] = useForm();
  const showAdvancedPanel = filter?.filterAssemblies?.length ||
    filter?.quoteStatus ||
    filter?.salespersons?.length ||
    filter?.engineers?.length ||
    filter?.ordersOnly ||
    filter?.incentivePrograms?.length ||
    filter?.includingCancelledOrder;

  return <>
    <Form 
      initialValues={props.filter}
      form={filterForm} 
      onValuesChange={props.onFilterChange}
    >
    <div className={styles["filter-controls"]}>
      <div className={styles["line1"]}>
        <Form.Item name="search" >
        <Input
          allowClear
          value={filter?.search}
          placeholder="Search by quote name, ID, and more."
        />
        </Form.Item>
      </div>

      <Collapse
        style={{ width: '100%', marginTop: "-1rem" }} size="small"
        defaultActiveKey={showAdvancedPanel ? "advanced-search" : undefined }
        {...AdvancedSearchConfig}
        items={[{
          key: "advanced-search",
          label: <span style={{color: "#1677FF"}}>Advanced Search</span>,
          forceRender: true,
          children: <Space direction="vertical" style={{ width: '100%' }} size="middle">
            <Row gutter={[32, 8]}>
              <Col xs={22} xxl={7}>
                <Form.Item
                  name="filterAssemblies"
                  label="BOM Filter"
                >
                  <AssemblySelector style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              <Col xs={22} xxl={7}>
                <Form.Item
                  name="incentivePrograms"
                  label="Incentive Programs"
                >
                  <IncentiveProgramSelector style={{ width: '100%' }} />
                </Form.Item>

              </Col>

            </Row>

          </Space>
        }]}
      />
    </div>
    </Form>
  </>
}

const gotoQuote = (q:BaseQuote) => window.open("/inventory/" + encodeURI(q.quoteId), "_blank");

const DesktopTable = (props: {
  quoteListAsync: AsyncState<BaseQuote[]>
  tableOnChange: TableOnChangeFn
  pagination: TablePaginationConfig
  setSelectedQuote?: (q: BaseQuote) => void
  filter:QuoteFilter
  sort:BaseQuoteSort
  handleExpand:(o:boolean, r:BaseQuote) => void
  quoteTrucks: Record<number,AsyncData<Truck[]>> | undefined
}) => {
  const { quoteListAsync, tableOnChange, pagination, setSelectedQuote } = props;
  const quoteList = quoteListAsync.val;

  const columns: ColumnType<BaseQuote>[] = [
    {
      title: "Truck",
      key: "quoteId",
      sorter: true,
      render: (q) => <><Link to={"/inventory/" + encodeURI(q.quoteId)}
        onClick={(e) => {
          gotoQuote(q);
          e.preventDefault();
        }}
      >{q.truckDescription}</Link></>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "latestRevision.quantity",
      sorter: true,
      width: "4rem",
    },
    {
      title: "Serial Number",
      dataIndex: "serialNumberStr",
      width: "15rem"
    },
  ];

  return <>
    <Space direction="vertical" size="small" style={{ display: 'flex' }}>
      <div style={{width: "100%", display: "flex", flexDirection:"row-reverse", padding: "0rem .3rem 0rem .3rem" }}>
      <ExportQuotesModal sort={props.sort} filter={props.filter} />
      </div>
      <Table
        data-testid="quoteListTable"
        loading={quoteListAsync.isLoading()}
        onChange={tableOnChange}
        bordered
        pagination={pagination}
        dataSource={quoteList}
        columns={columns}
        rowKey="quoteId"
        onRow={(rec) => ({ onDoubleClick: () => setSelectedQuote?.(rec) })}
        expandable={{
          onExpand: props.handleExpand,
          expandedRowRender: (q:BaseQuote) => {
            const items:DescriptionsItemType[] = [];
            const trucks = props.quoteTrucks?.[ q.displayRevisionId ].val;

            items.push(
              {
                span: 4,
                label: "Trucks",
                children: <Descriptions key={"truckInfo"} style={{width: "50rem"}} size="small" column={4}
                  items={trucks?.map( (t) => [
                    { key:"vin-" + t.truckSerialNumberStr,              label: "VIN",                 children: t.vin },
                    { key:"productionDate-" + t.truckSerialNumberStr,   label: "Est Production Date", children: (t.productionDate ? dayjs(t.productionDate).format("M/DD/YY") : "N/A") },
                    { key:"shipDate-" + t.truckSerialNumberStr,         label: "Est Ship Date",       children: (t.shipDate ? dayjs(t.shipDate).format("M/DD/YY") : "N/A") },
                    { key:"physicalLocation-" + t.truckSerialNumberStr, label: "Physical Location",   children: (t.physicalLocation || "N/A") },
                  ]).flat()}
                />,
              }
            );

            return <>
              <div style={{display: "flex", gap: "10rem", marginBottom: "1rem" }}>
                <Descriptions key={"expandedInfo"} 
                  className={styles['expandedInfo-description']}
                  column={2}
                  layout="vertical" size="small" colon={false}
                  items={items}
                />
              </div>
            </>}
        }}

      />
    </Space>
  </>
}

const MobileTable = (props: {
  quoteListAsync: AsyncState<BaseQuote[]>
  tableOnChange: TableOnChangeFn
  pagination: TablePaginationConfig
  setSelectedQuote?: (q: BaseQuote) => void
}) => {
  const { quoteListAsync, tableOnChange, pagination, setSelectedQuote } = props;
  const quoteList = quoteListAsync.val;

  const columns: ColumnType<BaseQuote>[] = [
    {
      render: (_val, quote, _ndx) => {
        return <>
          <Card
            hoverable={true}
            onClick={() => setSelectedQuote?.(quote)}
            title={<>
              <div className={styles["quote-name"]} style={{whiteSpace: "normal"}}>{quote.truckDescription}</div>
              <div className={styles["quote-id"]}>({quote.quoteId})</div>
            </>
            }
          >
            <div style={{ display: "flex", flexWrap: "wrap", gap: "3rem" }}>
              {quote?.vin &&
                <div className="section">
                  <div>VIN:</div>
                  <div><span>{quote?.vin}</span></div>
                </div>
              }

              {quote?.serialNumberStr &&
                <div className="section">
                  <div>Serial No:</div>
                  <div><span>{quote?.serialNumberStr}</span></div>
                </div>
              }

            </div>
          </Card>
        </>;
      },
    },

  ];

  return <>
    <div style={{ position: "relative" }}>
      <Table
        data-testid="quoteListTable"
        loading={quoteListAsync.isLoading()}
        onChange={tableOnChange}
        pagination={pagination}
        dataSource={quoteList}
        columns={columns}
        rowKey="id"
        className="mobile-table"
      />
    </div>
  </>


}




export default Inventory;
