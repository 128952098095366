import { Button, Col, Form, Input, Row } from "antd";
import BMReadOnly from "../BMReadOnly";
import { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useQuoteContext } from "../../contexts/QuoteContext";


const QuoteNote = (props: {isReadOnly: boolean }) => {

  const {isReadOnly} = props;

  const [expandNote, setExpandNote] = useState<boolean>(true);
  const { quoteAsync } = useQuoteContext();
  const quote = quoteAsync?.val;

  return (
    <Row gutter={8}>
      <Col span={18}>
        <Form.Item
          label="Notes"
          name="quoteNotes"
        >
          <BMReadOnly readOnly={isReadOnly} placeholder="None" style={{whiteSpace: "pre-wrap", textAlign:"left"}} dataTestId="quote-notes" >
            <Input.TextArea autoSize={expandNote ? { minRows: 2 } : { minRows: 2, maxRows: 2 }} />
          </BMReadOnly>
        </Form.Item>
      </Col>
      {(!isReadOnly && !!quote?.notes?.length) &&
      <Col >
        <Button icon={ expandNote ? <UpOutlined />:<DownOutlined />} shape="circle" onClick={() => setExpandNote((prev) => (!prev))}></Button>
      </Col>
      }
    </Row>
  );
}

export default QuoteNote;
