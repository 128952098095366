import {
  notification,
  Button,
  Col,
  Form,
  Row,
  Input,
  Space,
  Upload,
  UploadFile,
  UploadProps,
  Card,
  Checkbox,
} from "antd";
import { useContext, useEffect } from "react";
import { ConfiguratorContext, S3UrlMapContext } from "../context";
import { useForm } from "antd/lib/form/Form";
import { QuoteComment, CommentTopic, NewQuoteComment } from "../api/models"
import _ from "lodash";
import { useIntl } from "react-intl";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import {  DownloadOutlined, PlusOutlined, EditFilled } from "@ant-design/icons";
import dayjs from "dayjs";

export function asQuoteComment(comment: NewQuoteComment | QuoteComment | undefined) : QuoteComment | undefined {
  return !isNewQuoteComment(comment) ? comment as QuoteComment : undefined;
}
export function isNewQuoteComment(comment: QuoteComment | NewQuoteComment | undefined) : boolean {
  if ( !comment ) return false;
  return !('id' in comment) || comment.id === undefined;
}

interface UploadedDocumentAsset extends UploadFile {
  id?  : number
  assetUri: string
}
const QuoteCommentCard = (props: {
  comment:QuoteComment
  onEdit: (id:number) => void
}) => {

  const configurator = useContext(ConfiguratorContext);
  const { comment } = props;
  const handleDownloadDocument = (file:UploadFile) => {
    const document = file.response || file;
    if ( document.url ) {
      configurator.api.downloadFile( document.url, {
        mimeType: document.type,
        filename: document.name
      })
    }
  }

  const handleEditComment = () => {
    props.onEdit( comment.id );
  }
  const { s3UrlMapAsync } = useContext(S3UrlMapContext);
  const s3UrlMap = s3UrlMapAsync?.val;

  const canEdit = ( comment.createdBy?.id === configurator.userInfo?.id || configurator.isAdmin() ) && comment.topic !== CommentTopic.SystemActivity;

  const documents = comment.documents?.map( d => ({
    uid: d.assetUri,
    id: d.id,
    assetUri: d.assetUri,
    name: d.filename,
    type: d.contentType,
    status: 'done',
    downloadUrl: s3UrlMap?.[ d.assetUri ],
    url: s3UrlMap?.[ d.assetUri ],
  })) as UploadFile[];

  const backgroundColor = comment.hidden ? "#f9f9f9" : undefined;
  
  const hasEdit = comment.createdAt !== comment.updatedAt || comment.createdBy?.id !== comment.updatedBy?.id;

  return <Card
    headStyle={{minHeight: "auto", padding: ".4rem .4rem .4rem .4rem" }}
    bodyStyle={{padding: ".4rem", backgroundColor }}
    title={<>
      <Row justify={"space-between"}>
        <Col style={{overflowX: "clip", maxWidth: "18rem", fontWeight:"500"}}>
          {[comment.createdBy?.name, dayjs(comment.createdAt).format('MM/DD/YYYY h:mm a')].filter(v=>v).join(" - " )}
        </Col>
        {canEdit &&
        <Col><Button size="small" onClick={handleEditComment} shape="circle" icon={<EditFilled />} /></Col>
        }
      </Row>
    </>}
  >
    {!!comment.text?.length &&
    <div>{comment.text}</div>
    }

    {!!documents?.length &&
      <div style={{marginTop: ".2rem"}} >
        <Upload
          defaultFileList={documents}
          listType="picture-card"
          showUploadList={ {
            showDownloadIcon: true,
            downloadIcon: <DownloadOutlined />,
            showPreviewIcon: true,
            showRemoveIcon: false,
          }}
          onDownload={handleDownloadDocument}
          isImageUrl={(f) => !!f.type?.startsWith("image")  }
        / >
      </div>
    }

    {(hasEdit || comment.internal ) && 
      <Row style={{marginTop: ".2rem"}} justify={"space-between"} gutter={8}>
        <Col>
          {hasEdit &&
          <span style={{fontStyle: "italic", fontSize: "smaller", }}>
            edit: {[comment.updatedBy?.name, dayjs(comment.updatedAt).format('MM/DD/YYYY h:mm a')].filter(v=>v).join(" - " )}
          </span>
          }
        </Col>
        <Col>
          {comment.internal &&
          <span style={{fontStyle: "italic", fontSize: "smaller", }}>
            internal
          </span>
          }
        </Col>
      </Row>
    }
          
  </Card>;

}




type QuoteCommentForm = Omit<QuoteComment, "documents"> & { 
  documents?: UploadFile[] 
}
export const EditQuoteCommentCard = (props: {
  quoteId:string | undefined
  reset?:boolean
  comment?:QuoteComment | NewQuoteComment
  onCancel:() => void
  onSave:(c:QuoteComment) => void
}) => {

  const {quoteId} = props;

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [form] = useForm();
  const [_comment, commentAsync] = useAsyncState<QuoteComment>();

  useEffect(() => {
    form.resetFields();
  }, [props.reset, props.comment] );

  const createComment = async (commentAsync:AsyncState<QuoteComment>, quoteId:string | undefined, comment:NewQuoteComment) : Promise<QuoteComment | undefined> => {
    if (!quoteId) return;

    commentAsync.setLoading()
    try {
      const resp = await configurator.api.createQuoteComment(quoteId, comment);
      commentAsync.setDone(resp.data)
      return resp.data;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to save comment. " + errorMsg });
      commentAsync.setFail(e.message);
    };

    return;
  }

  const updateComment = async (commentAsync:AsyncState<QuoteComment>, quoteId:string | undefined, comment:QuoteComment | undefined) : Promise<QuoteComment | undefined> => {
    if (!comment) return;
    if (!quoteId) return;

    commentAsync.setLoading()
    try {
      const resp = await configurator.api.updateQuoteComment(quoteId, { ...comment })

      commentAsync.setDone(resp.data)
      return resp.data;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to save comment. " + errorMsg });
      commentAsync.setFail(e.message);
    };

    return;
  }

  const handleSetHidden = async (comment:QuoteComment | undefined, hidden:boolean):Promise<void> => {
    if (!comment) return;

    const updated = await updateComment( commentAsync, quoteId, { ...comment, hidden } );
    if ( updated ) {
      props.onSave?.(updated);
    }
  }

  const handleCancel = async () => {
    props.onCancel();
  }
  const handleSave = async ():Promise<QuoteComment | undefined> => {

    try {

      const values = await form.validateFields() as QuoteCommentForm;
      const comment = {
        ...values,
        documents: values.documents?.map( d => d.response ? d.response : d ) 
        .map( (d:UploadedDocumentAsset) => ({
          id: d.id,
          assetUri: d.assetUri,
          filename: d.name,
          contentType: d.type,
        }) ) 
      } as QuoteComment;
      const savedComment = await updateComment( commentAsync, quoteId, comment ) 
 
      if ( savedComment ) {
        props.onSave(savedComment);
      }
    }
    catch(validationErrors) {
      notification.error({message: "Please fix validation errors." });
    }

    return;
  }

  const handleAdd = async () => {
    try {

      const values = await form.validateFields() as QuoteCommentForm;
      const comment = {
        ...values,
        documents: values.documents?.map( d => d.response ? d.response : d ) 
        .map( (d:UploadedDocumentAsset) => ({
          id: d.id,
          assetUri: d.assetUri,
          filename: d.name,
          contentType: d.type,
        }) ) 
      } as QuoteComment;

      const savedComment = await createComment( commentAsync, quoteId, comment );
      if ( savedComment ) {
        props.onSave(savedComment);
      }
    }
    catch(validationErrors) {
      notification.error({message: "Please fix validation errors." });
    }

    return;
  }

  const uploadDocumentUrl = quoteId && configurator.api.getUploadCommentDocumentUrl(quoteId);

  const handleUploadChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    return newFileList;
  }

  return <Card style={{marginBottom: ".4rem"}}
    bodyStyle={{padding: ".4rem .4rem 1rem .4rem"}}
  >
    <Form 
      initialValues={props.comment}
      form={form}
    >
      <Form.Item name="id" hidden={true}>
        <Input/>
      </Form.Item>

      <Form.Item name="topic" hidden={true}>
        <Input/>
      </Form.Item>

      <Form.Item name="text">
        <Input.TextArea autoSize={{ minRows: 2 }} placeholder="Type a comment here" />
      </Form.Item>

      <div style={{display: "flex", flexDirection: "row-reverse", marginTop: "-1.5rem", marginBottom: "-1.5rem"}}>
        <Form.Item
          name="internal"
          valuePropName="checked"
        >
          <Checkbox>Internal</Checkbox>
        </Form.Item>
      </div>


      <Form.Item name="documents" >
        <Form.Item
          name="documents"
          getValueFromEvent={handleUploadChange}
          valuePropName="fileList"
          noStyle
        >
          <Upload
            action={uploadDocumentUrl}
            name="document"
            listType="picture-card"
            withCredentials={true}
            showUploadList={ {
              showDownloadIcon: false,
              downloadIcon: <DownloadOutlined />,
              showPreviewIcon: false,
              showRemoveIcon: true,
            }}
            isImageUrl={(f) => !!f.type?.startsWith("image")  }
          >
            <div style={{ marginTop: 16 }}>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload Document</div>
            </div>
          </Upload>
        </Form.Item>

      </Form.Item>

      <Row justify={"space-between"} gutter={8}>
        <Col>
          {asQuoteComment(props.comment)?.hidden 
            ?<Button size="small" onClick={() => handleSetHidden(asQuoteComment(props.comment), false)}>Show</Button>
            :<Button size="small" onClick={() => handleSetHidden(asQuoteComment(props.comment), true)}>Hide</Button>}
        </Col>
        <Col>
          {(props.comment?.topic !== CommentTopic.SystemActivity) && <>
            {!isNewQuoteComment(props.comment)
              ? <Space>
                <Button size="small" onClick={handleCancel}>Cancel</Button>
                <Button size="small" onClick={handleSave} type="primary">Save</Button>
              </Space>
              : <Space>
                <Button size="small" onClick={handleCancel}>Cancel</Button>
                <Button size="small" onClick={handleAdd} type="primary">Add</Button>
              </Space> }
          </>}
        </Col>
      </Row>
    </Form>
  </Card>
}

export default QuoteCommentCard;

