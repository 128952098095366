import { ChangeLeadTime, DAYS_IN_WEEK, Permission, Truck } from "../../api/models"
import { ConfiguratorContext } from "../../context";
import Paragraph from "antd/lib/typography/Paragraph";
import { Button, Input, Table, Tooltip, message, notification, Result, Space, Checkbox, Form, Descriptions } from "antd";
import Modal from "antd/es/modal/Modal";
import dayjs from "dayjs";
import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import {useAsyncState} from "../../hook/useAsyncState";
import {useIntl} from "react-intl";
import { WarningOutlined, DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import Utils from "../../util/util";
import _ from "lodash";
import { useQuoteContext } from "../../contexts/QuoteContext";
import Wizard, { getWizardFooter, getWizardTitle, WizardInstance, WizardStep } from "../Wizard";
import BMButton from "../BMButton";
import { useForm } from "antd/es/form/Form";
import BMReadOnly from "../BMReadOnly";
import { ColumnType } from "antd/es/table";

const QuoteTrucksButtonModal = (props:{
  hideCopy?:boolean | undefined
  labelText?: ReactNode | string | undefined
}) => {
  const { quoteAsync, loadQuoteOnly } = useQuoteContext();
  const quote = quoteAsync?.val;
  const [isOpen, setIsOpen] = useState(false);
  const [isEditSerials, setIsEditSerials] = useState(false);
  const [isEditTruck, setIsEditTruck] = useState(false);
  const [isEditTruckPoNumber, setIsEditTruckPoNumber] = useState(false);
  const [leadTime, leadTimeAsync] = useAsyncState<ChangeLeadTime>();
  const configurator = useContext(ConfiguratorContext);
  const [trucks, trucksAsync] = useAsyncState<Truck[]>(quote?.trucks);
  const [editTruck, setEditTruck] = useState<Truck>();
  const intl = useIntl();

  const [activeKey, setActiveKey] = useState<React.Key>();

  //note: reference used because steps change visibility
  const wizardInstance = useRef<WizardInstance>();

  //note: required to handle change in visibility
  useEffect(() => {
    if ( isEditSerials || isEditTruck || isEditTruckPoNumber ) {
      wizardInstance.current?.nextStep();
    }
  }, [isEditSerials, isEditTruck, isEditTruckPoNumber]);

  const { isSplitOrder, isReadOnly } = Utils.getQuoteState(configurator, quoteAsync );

  function getSerialNumberLabel() {
    if(!trucks?.length) return "N/A";

    if((quote?.serialNumberStr.length || 0 ) <= 16) {
      return quote?.serialNumberStr;
    }

    if(trucks.length > 2) {
      return (trucks[0].truckSerialNumberStr + ', ' + trucks[1].truckSerialNumberStr + ', and ' + (trucks.length - 2) + ' more');
    }
    else {
      return trucks.map(t => t.truckSerialNumberStr).join(', ');
    }
  }

  const getLeadTime = async(quoteId:number | undefined) => {

    leadTimeAsync.setLoading();
    try {
      const resp = await configurator.api.getLeadTime(quoteId);
      leadTimeAsync.setDone(resp.data);
    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get lead time " + errorMsg });
      leadTimeAsync.setFail(e.message);
    }
  }


  const getTrucks = async (quoteRevisionId:number | undefined) : Promise<Truck[] | undefined> => {
    if ( !quoteRevisionId ) return;

    try {
      trucksAsync.setLoading();
      const resp = await configurator.api.getTrucks(quoteRevisionId);
      const trucks = resp.data
      trucks.sort((a,b) => a.truckSerialNumberStr.localeCompare(b.truckSerialNumberStr))
      trucksAsync.setDone(trucks);
      return resp.data;
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to fetch trucks. " + errorMsg;
      notification.error( { message: msg });
      trucksAsync.setFail(msg);
    }

    return;
  }

  const serialNumberLbl = props.labelText || getSerialNumberLabel();

  const handleCancel = () => {
    setIsOpen(false);
  }

  const notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }

  const getDisabledEditTruckSerial = () : string | undefined => {
    return !quote?.trucks?.length ? "There are no trucks."
      : isSplitOrder ? "Serial numbers cannot be edited on a split quote."
      : isReadOnly ? "The quote is currently read-only" 
      : undefined;
  }

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    wizardInstance.current = instance;
  };

  const handleEditSerials = () => {
    setIsEditSerials(true);
  }

  const handleEditSerialsCancel = () => {
    wizardInstance.current?.nextStep();
    setIsEditSerials(false);
  }

  const handleEditSerialsChange = () => {
    wizardInstance.current?.nextStep();
    setIsEditSerials(false);
    getTrucks(quote?.displayRevisionId);
    loadQuoteOnly?.();
  }

  const handleEditTruck = (truck:Truck) => {
    setIsEditTruck(true);
    setEditTruck(truck);
    wizardInstance.current?.nextStep();
    loadQuoteOnly?.();
  }
  const handleEditTruckCancel = () => {
    wizardInstance.current?.nextStep();
    setIsEditTruck(false);
    setEditTruck(undefined);
  }

  const handleEditTruckChange = () => {
    wizardInstance.current?.nextStep();
    setIsEditTruck(false);
    setEditTruck(undefined);
    getTrucks(quote?.displayRevisionId);
  }

  const handleEditTruckPoNumber = (truck:Truck) => {
    setIsEditTruckPoNumber(true);
    setEditTruck(truck);
    wizardInstance.current?.nextStep();
  }
  const handleEditTruckPoNumberCancel = () => {
    wizardInstance.current?.nextStep();
    setIsEditTruckPoNumber(false);
    setEditTruck(undefined);
  }

  const handleEditTruckPoNumberChange = () => {
    wizardInstance.current?.nextStep();
    setIsEditTruckPoNumber(false);
    setEditTruck(undefined);
    getTrucks(quote?.displayRevisionId);
  }

  const steps:WizardStep[] = [ 
    {
      key: 1,
      title: "Trucks",
      body: (_nav) => <div>
        <div style={{display: "flex", flexDirection: "row-reverse", marginBottom: "1rem"}}>
          {configurator.hasPermission(Permission.TRUCK_SERIAL_NUMBERS_WRITE) &&
            <BMButton 
              type='primary'
              size="small"
              disabled={!!getDisabledEditTruckSerial()}
              onDisabledClick={() => notifyDisabled(getDisabledEditTruckSerial())}
              onClick={handleEditSerials}
            >Edit Serial Numbers
            </BMButton>}
        </div>

        <TruckListingModal 
          loading={trucksAsync.isLoading()}
          trucks={trucks}
          leadTime={leadTime}
          onEditTruck={handleEditTruck}
          onEditTruckPoNumber={handleEditTruckPoNumber}
        />
      </div> ,
      footer:() => <div style={{display: "flex", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Button key="done" type="primary" onClick={handleCancel}>Done</Button>
      </div>
    },
    useEditSerialWizardStep({
      key:2,
      hidden: !isEditSerials,
      onCancel:handleEditSerialsCancel,
      onChange:handleEditSerialsChange,
    }),
    useEditTruckStep({
      key:3,
      value: editTruck,
      hidden: !isEditTruck,
      onCancel: handleEditTruckCancel,
      onChange: handleEditTruckChange,
    }),
    useEditTruckPoNumberStep({
      key:4,
      value: editTruck,
      hidden: !isEditTruckPoNumber,
      onCancel: handleEditTruckPoNumberCancel,
      onChange: handleEditTruckPoNumberChange,
    }),

  ];

  const bodyStyle = {paddingTop: "2rem"}
  const activeStep = steps?.find( s => s.key === activeKey );
  const title = getWizardTitle( wizardInstance.current, activeStep);
  const footer = getWizardFooter( wizardInstance.current, activeStep);

  return <>
    <div style={{ display: 'flex', alignItems: "baseline" }}>
      <Button data-testid="serialNumberStr" className="ghostBmButton"
        style={{alignItems: 'start', textAlign: 'left', padding: 0, border: "none", boxShadow: "none"}} 
        onClick={() => setIsOpen(true)} 
      ><span style={{borderBottom: "1px solid black"}}>{serialNumberLbl}</span></Button>
      {!props.hideCopy && <>
        <Paragraph copyable={{text: quote?.serialNumberStr}}></Paragraph>
      </>}
    </div>
    <Modal 
      open={isOpen}
      width={"90rem"}
      onCancel={handleCancel}
      title={title}
      footer={footer}
      bodyStyle={bodyStyle}
      destroyOnClose={true}
      afterOpenChange={(open) => {
        if ( open ) {
          getTrucks(quote?.displayRevisionId);
          getLeadTime(quote?.id);
        }
      }}
    >
      <Wizard 
        showSteps={false}
        steps={steps}
        onStep={handleStep}
      />
    </Modal>
  </> 
}

const TruckListingModal = (props: {
  trucks: Truck[] | undefined
  loading: boolean | undefined
  leadTime:ChangeLeadTime | undefined
  onEditTruck?: (t:Truck) => void
  onEditTruckPoNumber?: (t:Truck) => void
}) => {

  const {trucks, leadTime} = props;

  const [exporting, setExporting] = useState<number | undefined>(undefined);
  const configurator = useContext(ConfiguratorContext);
  const isAdminOrEngineering = configurator.isAdmin() || configurator.isEngineering();
  const { quoteAsync } = useQuoteContext();
  const quote = quoteAsync?.val;

  const { isSplitOrder, isReadOnly, isEngineeringLocked } = Utils.getQuoteState(configurator, quoteAsync );
  const canChangeTrucks = ( configurator.isSalesDesk() || configurator.isEngineering() || configurator.isAdmin() ) && !isEngineeringLocked;

  const revId = quote?.displayRevisionId;

  const getProductionDateInfo = (productionDate:Date | undefined) : ReactNode => {

    if ( productionDate ) return dayjs(productionDate).format('M/D/YYYY');

    const leadTimeDays = _.max([leadTime?.categoryLeadTimeDays, (leadTime?.standardLeadTimeWeeks || 0) * DAYS_IN_WEEK]);
    if( leadTimeDays ) {
      const dt = dayjs().add(leadTimeDays, 'day').format('M/D/YYYY');
      const categories = leadTime?.leadTimeCategories?.map(c => Utils.stripSortingPrefix(c.name)).join(", ");
      const leadTimeDescription = leadTimeDays === leadTime?.categoryLeadTimeDays
        ? `Production lead time of ${leadTime?.categoryLeadTimeDays} days due to: ${categories}.`
        : `Standard production lead time of ${leadTime?.standardLeadTimeWeeks} weeks.`;

      return <>{dt}
        <Tooltip title={leadTimeDescription}>
          <WarningOutlined style={{color:"orange", marginLeft: ".4rem"}} />
        </Tooltip>
      </>
    }

    return "Not Available";
  }

  const onExportParFile = async (truck: Truck) => {
    if ( !revId ) return;

    const url = configurator.api.getBendixParFileExportUrl(revId, Number(truck.id));
    setExporting( Number(truck.id) );
    try {
      await configurator.api.downloadCsv( url )
    }
    catch(e:any) {
      message.error( e.message );
    }
    setExporting( undefined );

  };

  const notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }

  const getDisabledEditTruckSerial = () : string | undefined => {
    return !quote?.trucks?.length ? "There are no trucks."
      : isSplitOrder ? "Serial numbers cannot be edited on a split quote."
      : !canChangeTrucks ? "The quote is currently read-only" 
      : undefined;
  }

  const editTruckBtnStyle = !!getDisabledEditTruckSerial() 
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

  const columns:ColumnType<Truck>[] = [
    {
      key: 'truckSerialNumberStr',
      title: 'Truck Sn',
      render: (t:Truck) => 
        <BMButton
          className="ghostBmButton"
          type="text" 
          onClick={() => props.onEditTruck?.(t)} 
          disabled={!!getDisabledEditTruckSerial()}
          onDisabledClick={() => notifyDisabled(getDisabledEditTruckSerial())}
        ><span style={editTruckBtnStyle}>{t.truckSerialNumberStr}</span></BMButton>
    },
    {
      title: 'Engine Sn',
      dataIndex: 'engineSerialNumber'
    },
    {
      title: 'Transmission Sn',
      dataIndex: 'transmissionSerialNumber'
    },
    {
      title: 'VIN',
      dataIndex: 'vin'
    },
    {
      key: 'productionDate',
      title: 'Production Date',
      render: (t: Truck) => getProductionDateInfo(t.productionDate)
    },
    {
      key: 'shipDate',
      title: 'Ship Date',
      render: (t: Truck) => {
        return (t.shipDate ? dayjs(t.shipDate).format('M/D/YYYY') : ' - ');
      }
    },
    {
      key: 'readyToShip',
      title: 'Ready to Ship Date',
      render: (t: Truck) => {
        return (t.readyToShip ? dayjs(t.shipDate).format('M/D/YYYY') : ' - ');
      }
    },
    {
      dataIndex: 'physicalLocation',
      title: 'Physical Location',
    },
    {
      dataIndex: 'notes',
      title: 'Note',
      width: "18rem",
    },
    {
      key: 'purchaseOrder',
      title: 'PO Number',
      render: (t: Truck) => 
        <BMButton
          className="ghostBmButton"
          type="text" 
          onClick={() => props.onEditTruckPoNumber?.(t)} 
        ><span style={{borderBottom: "1px solid black"}}>{t.purchaseOrder || "Add PO Number"}</span></BMButton>
    },
    {
      title: 'Steer Axle 1 Sn',
      dataIndex: 'steerAxle1'
    },
    {
      title: 'Steer Axle 2 Sn',
      dataIndex: 'steerAxle2'
    },
    {
      title: 'Steer Axle 3 Sn',
      dataIndex: 'steerAxle3'
    },
    {
      title: 'Pusher Axle 1 Sn',
      dataIndex: 'pusherAxle1'
    },
    {
      title: 'Drive Axle 1 Sn',
      dataIndex: 'driveAxle1'
    },
    {
      title: 'Drive Axle 2 Sn',
      dataIndex: 'driveAxle2'
    },
    {
      title: 'Drive Axle 3 Sn',
      dataIndex: 'driveAxle3'
    },
    {
      title: 'Tag Axle 1 Sn',
      dataIndex: 'tagAxle'
    }
  ];

  if (isAdminOrEngineering) {
    columns.push({
      key: 'export',
      title: 'Par File',
      render: (t: Truck) => exporting === t.id ? <LoadingOutlined /> : 
        <Button
          type="primary"
          size="small"
          shape="circle"
          icon={<DownloadOutlined />}
          onClick={() => onExportParFile(t)}
        />

    });
  }

  return <Table
          bordered
          rowKey="id"
          loading={props.loading}
          dataSource={trucks}
          columns={columns}
          scroll={{ x: true }}
        />
};

const useEditSerialWizardStep = (props:{
  key: React.Key
  hidden?: boolean
  onChange?:() => void
  onCancel?:() => void
}) : WizardStep => {

  const configurator = useContext(ConfiguratorContext);
  const { quoteAsync } = useQuoteContext();
  const quote = quoteAsync?.val;

  const [snStrStatus, setSnStrStatus] = useState<string | undefined>()
  const [exportToEpicor, setExportToEpicor] = useState<boolean>(false)

  const quoteSerialNumbers = quote?.trucks?.map( t => t.truckSerialNumberStr ) || []
  const quoteSerialNumberStr = Utils.buildSerialNumberStr( quoteSerialNumbers );
  const [snStr, setSnStr] = useState<string>(quoteSerialNumberStr)

  const [activeKey, setActiveKey] = useState<React.Key>();
  const [wizardInstance, setWizardInstance] = useState<WizardInstance>();

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    setWizardInstance(instance);
  };

  const handleChangeSerialNumber = (e:any) => {
    setSnStr( e.target.value );
  }

  const handleUpdateSerials = (nav:WizardInstance) => {

    try {

      const snLst = Utils.expandTruckSerialNumberStr( snStr );

      if ( snLst.length > quoteSerialNumbers.length ) throw new Error( `Too many serial numbers! Remove ${snLst.length - quoteSerialNumbers.length} serial numbers.` );
      if ( snLst.length < quoteSerialNumbers.length ) throw new Error( `Not enough serial numbers! Provide ${quoteSerialNumbers.length - snLst.length} more serial numbers.` );

      setSnStr( snStr );
      setSnStrStatus( undefined );

      nav.nextStep();
    }
    catch( e:any ) {
      setSnStrStatus( e.message );
    }
  }

  const handleReset = (nav:WizardInstance) => {

    //reset ui
    setSnStr( quoteSerialNumberStr );
    setSnStrStatus( undefined );
    nav.resetSteps();
  }

  const handleConfirm = async (nav:WizardInstance) => {
    if ( !quote ) return;
    if ( !quoteAsync ) return;

    const snLst = Utils.expandTruckSerialNumberStr( snStr );
    quoteAsync.setLoading();
    try {
      const resp = await configurator.api.saveTruckSerials( quote.id, quote.displayRevisionId, snLst, exportToEpicor );
      quoteAsync.setDone( resp.data );
      nav.nextStep();
    }
    catch(e:any) {
      quoteAsync.setFail( e.message );
      nav.prevStep();
    }
  }

  const err = snStrStatus || quoteAsync?.err;

  const steps:WizardStep[] = [
    {
      key: "1",
      body:(_nav) =>  <div>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

          <div style={{marginTop: ".4rem", marginBottom: "1rem"}} >Serial numbers can be described as a comma separated list, ranges (inclusive), or a combination. For example: 1,2-4,5 is 1,2,3,4,5</div>

          <Form.Item label="Serial Number Range">
          <Input 
            value={snStr}
            status={snStrStatus && "error"}
            onChange={handleChangeSerialNumber}
          />
          </Form.Item>

          <div style={{color: "red", maxHeight: "5.5rem", overflow:"auto", whiteSpace:"pre-line" }}>{err}</div>

        </div>
      </div>,
      footer: (nav) => <div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
        <Space>
        <Button key="cancel" onClick={props.onCancel}>Cancel</Button>
        <Button key="next" type="primary" 
          disabled={snStr === quoteSerialNumberStr}
          onClick={() => handleUpdateSerials(nav)}
        >Next</Button>
        </Space>
        <Button key="reset" onClick={() => handleReset(nav)}>Reset</Button>
      </div>
    },
    {
      key: "2",
      body:(_nav) => <div>
        <Space direction="vertical">
          <Form.Item label="Serial Numbers" >
            <BMReadOnly readOnly={true} renderValue={() => snStr}>
              <Input  />
            </BMReadOnly>
          </Form.Item>
          <div><Checkbox onChange={(e) => setExportToEpicor(e.target.checked)}>Export to Epicor</Checkbox></div>
        </Space>
      </div>,
      footer: (nav) => <div style={{display: "flex", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Space>
          <Button key="back" onClick={nav.prevStep}>Back</Button>
          <Button key="submit" type="primary" onClick={() => handleConfirm(nav)}>Confirm</Button>
        </Space>
      </div>
    },
    {
      key:"3",
      body: (_nav) => <div>
        <Result
          status="success"
          title="Serial Numbers Changed"
          subTitle={`The serial numbers for quote ${quote?.quoteId} have been changed to ${quoteSerialNumberStr}.`}
          extra={[
            <Button type="primary" key="done" onClick={props.onChange}>Ok</Button>,
          ]}
          style={{padding: "0"}}
        />
      </div>,
      footer: (_nav) => <></>
    }
  ];

  const activeStep = steps?.find( s => s.key === activeKey );
  const title = getWizardTitle( wizardInstance, activeStep);
  const footer = getWizardFooter( wizardInstance, activeStep);

  return {
    key: props.key,
    hidden: props.hidden,
    body: (_nav) => 
      <Wizard 
        key="editSerialsWizard"
        steps={steps}
        onStep={handleStep}
      />,
    title: (_nav) => title,
    footer: (_nav) => footer,
  }
}

type TruckFormValues = Truck;
const useEditTruckStep = (props: {
  key: React.Key
  hidden?: boolean
  value: Truck | undefined
  onChange?:(t:Truck) => void
  onCancel?:() => void
}) : WizardStep => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const [form] = useForm();
  const [initialValues, setInitialValues] = useState<TruckFormValues>();
  const [_truck, trucksAsync] = useAsyncState<Truck>(props.value);

  const { quoteAsync } = useQuoteContext();
  const { isReadOnly } = Utils.getQuoteState(configurator, quoteAsync );

  useEffect(() => {
    form.resetFields();
  }, [initialValues])

  useEffect(() => {
    setInitialValues(props.value);
  }, [props.value])

  const handleSave = async () => {
    try {
      const formValues = await form.validateFields() as TruckFormValues;
      const savedTruck = await saveTruck(formValues);
      if ( savedTruck ) {
        props.onChange?.(savedTruck);
      }
    }
    catch(e:any) {
    }
  }

  const saveTruck = async (truck: TruckFormValues | undefined) : Promise<Truck | undefined> => {
    if (!truck) return;

    trucksAsync.setLoading();
    try {
      const resp = await configurator.api.saveTruck(truck.id, truck);
      notification.success({message: "Successfully saved truck."});
      trucksAsync.setDone( resp.data );
      return resp.data;
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to save truck. " + errorMsg;
      notification.error( { message: msg });
      trucksAsync.setFail( errorMsg );
    }
    return;
  }


  return {
    key: props.key,
    hidden: props.hidden,
    title: (_nav) => "Edit Truck",
    body: (_nav) => <div>
    <style>
    {`
      .truck-ro-descriptions.ant-descriptions .ant-descriptions-item-container {
        align-items: baseline;
      }
    `}
    </style>
      <Form 
        form={form}
        layout="vertical"
        initialValues={initialValues} 
      >
        <Form.Item 
          label="id"
          name="id" hidden={true}>
          <Input />
        </Form.Item>
        <Descriptions 
          className="truck-ro-descriptions"
          column={2}
          items={[
            {
              span: 2,
              label: "VIN",
              children: <Form.Item 
                name="vin" >
                <BMReadOnly readOnly={true} >
                  <Input />
                </BMReadOnly>
              </Form.Item>
            },
            {
              label:"Production Date",
              children: <Form.Item 
                name="productionDate" >
                <BMReadOnly readOnly={true} renderValue={(v:Date | undefined) => v ? dayjs(v).format('M/D/YYYY') : "Not Available"} >
                  <Input />
                </BMReadOnly>
              </Form.Item>
            },
            {
              label:"Ready To Ship Date",
              children: <Form.Item 
                name="shipDate" >
                <BMReadOnly readOnly={true} renderValue={(v:Date | undefined) => v ? dayjs(v).format('M/D/YYYY') : "Not Available"} >
                  <Input />
                </BMReadOnly>
              </Form.Item>
            },
            {
              label:"Engine Sn",
              children: <Form.Item 
                name="engineSerialNumber" >
                <BMReadOnly readOnly={true} placeholder={"N/A"}>
                  <Input />
                </BMReadOnly>
              </Form.Item>
            },
            {
              label:"Transmission Sn",
              children: <Form.Item 
                name="transmissionSerialNumber" >
                <BMReadOnly readOnly={true} placeholder={"N/A"} >
                  <Input />
                </BMReadOnly>
              </Form.Item>
            },
          ]}
        />

        <Form.Item 
          label="Truck Sn."
          name="truckSerialNumberStr" >
          <BMReadOnly readOnly={isReadOnly}>
          <Input />
          </BMReadOnly>
        </Form.Item>

        <Form.Item 
          label="PO Number"
          name="purchaseOrder" >
          <Input />
        </Form.Item>

        <Form.Item 
          label="Physical Location"
          name="physicalLocation" >
          <Input />
        </Form.Item>

        <Form.Item 
          label="Notes"
          name="notes" >
          <Input.TextArea />
        </Form.Item>
      </Form>

    </div>,
    footer: (_nav) => <div style={{display: "flex", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
      <Space>
      <Button key="cancel" onClick={props.onCancel}>Cancel</Button>
      <Button key="save" type="primary" onClick={handleSave} >Save</Button>
      </Space>
    </div>
  }
}

interface TruckPoNumberFormValues {
  id: React.Key
  purchaseOrder: string | undefined
}
const useEditTruckPoNumberStep = (props: {
  key: React.Key
  hidden?: boolean
  value: Truck | undefined
  onChange?:(t:Truck) => void
  onCancel?:() => void
}) : WizardStep => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const [form] = useForm();
  const [initialValues, setInitialValues] = useState<TruckPoNumberFormValues>();
  const [_truck, trucksAsync] = useAsyncState<Truck>(props.value);

  useEffect(() => {
    form.resetFields();
  }, [initialValues])

  useEffect(() => {
    if ( props.value ) {
      setInitialValues({
        id: props.value.id,
        purchaseOrder: props.value.purchaseOrder,
      });
    }
  }, [props.value])

  const handleSave = async () => {
    try {
      const formValues = await form.validateFields() as TruckPoNumberFormValues;
      const savedTruck = await saveTruckPoNumber(formValues);
      if ( savedTruck ) {
        props.onChange?.(savedTruck);
      }
    }
    catch(e:any) {
    }
  }

  const saveTruckPoNumber = async (truck: TruckPoNumberFormValues | undefined) : Promise<Truck | undefined> => {
    if (!truck) return;

    trucksAsync.setLoading();
    try {
      const resp = await configurator.api.saveTruckPurchaseOrder(truck.id, truck.purchaseOrder);
      notification.success({message: "Successfully saved po number."});
      trucksAsync.setDone( resp.data );
      return resp.data;
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to save po number. " + errorMsg;
      notification.error( { message: msg });
      trucksAsync.setFail( errorMsg );
    }
    return;
  }


  return {
    key: props.key,
    hidden: props.hidden,
    title: (_nav) => "Edit PO Number",
    body: (_nav) => <div>
    <style>
    {`
      .truck-ro-descriptions.ant-descriptions .ant-descriptions-item-container {
        align-items: baseline;
      }
    `}
    </style>
      <Form 
        form={form}
        layout="vertical"
        initialValues={initialValues} 
      >
        <Form.Item 
          label="id"
          name="id" hidden={true}>
          <Input />
        </Form.Item>

        <Descriptions 
          style={{marginBottom: "1rem"}}
          className="truck-ro-descriptions"
          column={2}
          items={[
            {
              label: "VIN",
              children: props.value?.vin
            },
            {
              label:"Truck Sn",
              children: props.value?.truckSerialNumberStr
            },
          ]}
        />

        <Form.Item 
          label="PO Number"
          name="purchaseOrder" >
          <Input />
        </Form.Item>

      </Form>

    </div>,
    footer: (_nav) => <div style={{display: "flex", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
      <Space>
      <Button key="cancel" onClick={props.onCancel}>Cancel</Button>
      <Button key="save" type="primary" onClick={handleSave} >Save</Button>
      </Space>
    </div>
  }
}

export default QuoteTrucksButtonModal;


