import "./approval_action.css";
import styles from "./approval_action.module.css";
import { Alert, Button, Col, Divider, Form, Modal, notification, Result, Row, Space, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Title from "antd/lib/typography/Title";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { NotFoundError } from "../api/errors";
import { ApprovalContext, ApprovalContextType, ConfiguratorContext } from "../context";
import dayjs from 'dayjs'
import { useForm } from "antd/lib/form/Form";
import { RevisionType, ChangeOrderStep, CustomOptionType, Approval, ApprovalAction, Quote, PricingBreakdown, ApprovalDiff, 
  QuoteApprovalStep, CustomOptionProcessingStatus, MajorTableExcludedDiff, 
  PendingSplitChange,
  ApprovalRequest} from "../api/models";
import CustomOptionApproval from "../components/Quote/CustomOptionApproval";
import ApprovalDiffTable from "../components/Table/ApprovalDiffTable";
import {useAsyncState} from "../hook/useAsyncState";
import Utils from "../util/util";
import { useHistory } from "react-router-dom";
import {useIntl} from "react-intl";
import useCheckMobileScreen from "../hook/useCheckMobileScreen";
import React from "react";
import ApprovalTransitionInfo from "../components/ApprovalTransitionInfo";

const NOT_FOUND = "quote.notFound";

const ApprovalResult = (props:{
  approval:Approval | undefined
  quoteStatus:string | undefined
  showApprovalResults:boolean
  setShowApprovalResults:(b:boolean)=>void
}) => {
  const { approval, showApprovalResults, setShowApprovalResults, quoteStatus } = props;
  const action = approval?.action === ApprovalAction.APPROVED ? "Approved" : "Rejected";
  const title = `Approval ${action}!`;
  const history = useHistory();

  if ( !approval?.action ) return <></>;

  const handleDone = () => {
    history.push("/approvals");
  }

  return <>
    <Alert type="info" message={`This quote has already been ${action.toLowerCase()}.`} />
    <Modal
      open={showApprovalResults}
      onCancel={() => setShowApprovalResults(false)}
      footer={null}
    >
      <Result
        status="info"
        title={title}
        subTitle={<>The quote status is now {Utils.snakeCaseToFirstLetterCapitalized(quoteStatus)}.</> }
        extra={ <Button type="primary" key="console" onClick={handleDone}>Done</Button> }
      />
    </Modal>
  </>

}


const ApprovalActionPage = () => {
  const intl = useIntl();
  const params = useParams<{approvalId?:string|undefined}>();
  const configurator = useContext(ConfiguratorContext);
  const [allCustomOptionsOnOriginalQuote, setAllCustomOptionsOnOriginalQuote] = useState<CustomOptionType[]>([]);
  const [allCustomOptionsOnSplitQuote, setAllCustomOptionsOnSplitQuote] = useState<CustomOptionType[]>([]);
  const approvalContext = useContext(ApprovalContext);
  const [approval, approvalAsync] = useAsyncState<Approval>();
  const [showApprovalResults, setShowApprovalResults] = useState<boolean>(false);
  const [form] = useForm();
  const [quote, quoteAsync] = useAsyncState<Quote>();
  const actionNotes = Form.useWatch<string | undefined>('actionNotes', form);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const isMobile = useCheckMobileScreen();
  const allCustomOptions = allCustomOptionsOnOriginalQuote.concat(allCustomOptionsOnSplitQuote);

  useEffect(() => {
    loadQuoteApproval();
  }, []);

  const loadQuoteApproval = () => {
    if (!params.approvalId) return;

    approvalAsync.setLoading();
    configurator.api.getQuoteApproval(params.approvalId)
    .then(resp => approvalAsync.setDone(resp.data),
          (e: any) => {
            const err = (e instanceof NotFoundError) ? NOT_FOUND : e.message;
            const errorMsg = intl.formatMessage({ id: err });
            notification.error( { message: "Approval failed to load. " + errorMsg });
            approvalAsync.setFail( err );
          });
  };

  const notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }

  const getDisabledReject = () : string | undefined => {

    const values = form.getFieldsValue(true);

    return !!(approval?.action) ? "Quote Approval has already been actioned."
      : disableSubmitButton ? "All related splits are not submitted."
      : !values.actionNotes?.length ? "Please provide rejection notes to submitter."
      : undefined;
  }


  const actionApproval = (approved:boolean) => {
    if ( !approval ) return;

    if ( !approved ) {
      const msg = getDisabledReject();
      if ( msg ) { 
        notifyDisabled(msg);
        return;
      }
    }

    setDisableSubmitButton(true);

    const action = approved ? "Approved" : "Rejected";
    const values = form.getFieldsValue(true);
    approvalAsync.setLoading();
    configurator.api.approvalAction(approval.id, {
      actionNotes: values.actionNotes,
      action: action.toUpperCase(),
      customOptionsRequest: allCustomOptions,
    })
    .then(resp => {
      afterAction( resp.data );
    },
    (e: any) => {
      let msg = (e instanceof NotFoundError) 
        ? "Approval not found."
        : e.response?.data?.message || e.message;

      approvalAsync.setFail(msg);
      notification.error( { message: msg });
      setDisableSubmitButton(false);
    });
  };

  const afterAction = (data: Approval) => {
    approvalContext.getApprovalsCount?.();
    approvalAsync.setDone( data );
    setShowApprovalResults(true);
  }

  const isNullOrUndefined = ( o:any ) : boolean => {
    return o == null || o == undefined;
  }

  const isChangeOrder = approval?.approvalType === RevisionType.CHANGE_ORDER;
  
  const allCustomOptionsActioned = ( allCustomOptions.length === 0 || !allCustomOptions.find( c => isNullOrUndefined( c.approved ) && c.processingStatus !== CustomOptionProcessingStatus.PROCESS_COMPLETED ) );

  const isQuoteApprovalEngineeringStep = quote?.status === QuoteApprovalStep.ENGINEERING_QUOTE_APPROVAL_REVIEW || quote?.status === QuoteApprovalStep.OLD_ENGINEERING_QUOTE_APPROVAL_REVIEW;
  const isChangeOrderEngineeringStep = quote?.status === ChangeOrderStep.ENGINEERING_CHANGE_ORDER_REVIEW || quote?.status === ChangeOrderStep.OLD_ENGINEERING_CHANGE_ORDER_REVIEW;

  const isChangeOrderSalesDeskPoStep = (isChangeOrder && 
    (quote?.status === ChangeOrderStep.SALES_DESK_CHANGE_ORDER_PO_REQUEST || quote?.status === ChangeOrderStep.OLD_SALES_DESK_CHANGE_ORDER_PO_REQUEST));
  const isSalesProcessChangeOrder = (isChangeOrder && quote?.status === ChangeOrderStep.SALES_CHANGE_ORDER_PROCESS); // To be removed later
  const isEngineeringCompleteChange = (isChangeOrder && 
    (quote?.status === ChangeOrderStep.ENGINEERING_CHANGE_ORDER_COMPLETE_CHANGE || quote?.status === ChangeOrderStep.OLD_ENGINEERING_CHANGE_ORDER_COMPLETE_CHANGE));
  const isChangeOrderSalesDeskReviewStep = (isChangeOrder && 
    (quote?.status === ChangeOrderStep.SALES_DESK_CHANGE_ORDER_REVIEW || quote?.status === ChangeOrderStep.OLD_SALES_DESK_CHANGE_ORDER_REVIEW));

  const canSubmitApproval = allCustomOptionsActioned || ( isSalesProcessChangeOrder || isEngineeringCompleteChange || isChangeOrderSalesDeskPoStep ); // last confirm step should be enabled if revision carrying custom option
  
  const isSingleAction = isSalesProcessChangeOrder || isEngineeringCompleteChange;

  const allSplitChangeReady = approval?.pendingSplit == undefined || (approval.pendingSplit?.selfSubmitted && approval.pendingSplit?.partners.every(p => !!p.partnerSubmitted));

  const isEngineeringChange = approval?.approvalType === ApprovalRequest.ENGINEERING_CHANGE;
  const isCancelOrder = approval?.approvalType === ApprovalRequest.CANCEL_ORDER;

  const getSubmitString = (): string | undefined => {
    if (isSalesProcessChangeOrder) return 'Process';
    if (isEngineeringCompleteChange) return 'Release Change';

    return "Submit";
  };

  return <>
    <div key='page' className="site-layout-background approvalActionView">
      <Title level={2}>Approval Request - <span style={{textTransform: "uppercase"}}>{Utils.formatApprovalType(approval?.approvalType)}</span></Title>
      <Spin spinning={approvalAsync.isLoading()}>

        <Space direction="vertical" style={{width: isMobile? "" : "70rem"}}>

          {approval?.pendingSplit && 
          <Alert message={
          <span>
            This approval is for split change. It related to quote 
            <Link style={{marginLeft:"5px", marginRight: "5px"}} to={"/configurator/" + encodeURI(approval.pendingSplit.self)}>{approval.pendingSplit.self}</Link> 
            {approval.pendingSplit.partners.map(partner =>
              <>
                <span>and</span>
                <Link style={{marginLeft:"5px", marginRight: "5px"}} to={"/configurator/" + encodeURI(partner.quoteId)}>{partner.quoteId}</Link>
              </>
            )}
          </span>}
          />}

          <div style={{display: "flex", gap: ".5rem 2rem", flexWrap: "wrap"}}>

            <div className={styles["section"]}>
              <div >{quote?.name}</div>
              <Link to={"/configurator/" + encodeURI(quote?.quoteId || "")} target="_blank">
                {quote?.quoteId}
              </Link>
            </div>

            <div className={styles["section"]}>
              <div >Requested:</div>
              <div ><span>{approval?.requestedBy?.name}</span></div>
              <div ><span>{dayjs(approval?.createdAt).format("MMMM Do YYYY")}</span></div>
            </div>

          </div>

          <ApprovalResult  
            approval={approval}
            quoteStatus={quote?.status}
            showApprovalResults={showApprovalResults}
            setShowApprovalResults={setShowApprovalResults}
          />

          {!!approval?.workflow?.length && <ApprovalTransitionInfo
            quote={quote}
            approval={approval}
            isSingleAction={isSingleAction}
          />}

          <Form form={form}>
            <div style={{fontWeight: "600"}} >Action Notes (Public):</div>
            <Form.Item name="actionNotes" >
              <TextArea rows={5} 
                disabled={!!approval?.action} 
              />
            </Form.Item>
          </Form>

          {approval?.changeSummary && (isEngineeringChange || isCancelOrder) && 
          <div style={{marginBottom: "1rem"}} key = "change-summary">
            <span style={{fontWeight: "bold"}}>{`${isEngineeringChange ? "Change Summary" : "Cancellation Message"}: ` }</span>
            <span>{approval.changeSummary}</span>
          </div>}

          <DiffInApproval
            approval={approval}
            approvalAsync={approvalAsync}
            externalQuoteAsync={quoteAsync}
            quoteId={approval?.quoteId}
            revision={approval?.revision || 0}
            quoteTitle={approval?.pendingSplit ? "Original Order" : ""}
            pendingSplit={approval?.pendingSplit}
            setAllCustomOptions={setAllCustomOptionsOnOriginalQuote}
          />

          {approval?.pendingSplit?.partners.sort((p1, p2) => p1.id - p2.id).map(partner => <>
            <Divider />
            <DiffInApproval
              approval={approval}
              approvalAsync={approvalAsync}
              quoteId={partner.quoteId}
              revision={partner.revision || 0}
              externalQuoteAsync={undefined}
              quoteTitle="New Split Order"
              pendingSplit={approval?.pendingSplit}
              setAllCustomOptions={setAllCustomOptionsOnSplitQuote}
            />
          </>)}

          {!allSplitChangeReady && <Alert style={{marginBottom: ".5rem", marginTop: "2rem"}} type="warning" message="Please submit change order for all orders to continue the process."/>}
          {quote?.reservation && <Alert style={{marginBottom: ".5rem", marginTop: "2rem"}} type="warning" message="This is a production reservation."/>}

          <ActionButton 
            actionApproval={actionApproval}
            approval={approval}
            approvalAsync={approvalAsync}
            disableSubmitButton={disableSubmitButton}
            canSubmitApproval={canSubmitApproval}
            getSubmitString={getSubmitString}
            isSingleAction={isSingleAction}
            isMobile={isMobile}
            showPoConfirm={isChangeOrderSalesDeskPoStep || isChangeOrderSalesDeskReviewStep}
            allCustomOptions={allCustomOptions}
            stepNeedsCustomOptionOperation={isQuoteApprovalEngineeringStep || isChangeOrderEngineeringStep}
            disabled={!allSplitChangeReady}
            actionNotes={actionNotes}
          />
        </Space>
      </Spin>
    </div>
  </> 
};

export default ApprovalActionPage;


const ActionButton = (props: {
  actionApproval: (value: boolean) => void, 
  approval: Approval | undefined,
  disabled?: boolean,
  approvalAsync: any,
  disableSubmitButton: boolean,
  canSubmitApproval: boolean,
  getSubmitString: () => string | undefined,
  isSingleAction: boolean,
  isMobile: boolean,
  showPoConfirm: boolean,
  allCustomOptions: CustomOptionType[],
  stepNeedsCustomOptionOperation: boolean,
  actionNotes:string | undefined
}) => {

  const {isSingleAction, actionApproval, approval, approvalAsync, disableSubmitButton, canSubmitApproval, 
    getSubmitString, isMobile, showPoConfirm, allCustomOptions, stepNeedsCustomOptionOperation, disabled} = props;

  const canApprove = !stepNeedsCustomOptionOperation || allCustomOptions.filter(co => co.processingStatus !== CustomOptionProcessingStatus.PROCESS_COMPLETED).filter(co => co.approved == undefined || co.approved == null).length === 0;

  return (
    <Row justify={"space-between"}>
      <Col>
        {!isSingleAction 
          ?<div style={{display: "flex", gap: "1rem"}}>
            <Button type="primary"
              onClick={() => actionApproval(true)} 
              disabled={!!approval?.action || disableSubmitButton || !canApprove || disabled} 
              loading={approvalAsync.isLoading()}
              data-testid="approve-btn"
            >
              {showPoConfirm ? 'Confirm PO and Approve' : 'Approve'}
            </Button>
            <Button danger type="primary" 
              onClick={() => actionApproval(false)} 
              loading={approvalAsync.isLoading()}
              data-testid="reject-btn"
            >
              Reject
            </Button>
          </div>
          :<>
            <Button onClick={() => actionApproval(true)} disabled={!canSubmitApproval || disableSubmitButton || disabled} type="primary"
              data-testid="approve-btn"
            >
              {getSubmitString()}
            </Button>
          </>
        }
      </Col>
      <Col>
        {isMobile && <Button disabled={disabled}><Link to="/approvals">Back</Link></Button>}
      </Col>
    </Row>
  );
}


export interface ApprovalDiffInterface {
  approval: Approval | undefined;
  approvalAsync: any;
  externalQuoteAsync: any;
  quoteId?: string;
  quoteTitle: string;
  pendingSplit?: PendingSplitChange
  revision: number;
  setAllCustomOptions: (customOptions: CustomOptionType[]) => void;
}

const DiffInApproval = (props: ApprovalDiffInterface) => {

  const [quote, quoteAsync] = useAsyncState<Quote>();
  const [pricingBreakdown, pricingBreakdownAsync] = useAsyncState<PricingBreakdown>();
  const [approvalDiff, approvalDiffAsync] = useAsyncState<ApprovalDiff>();
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const {approval, approvalAsync, quoteId, revision, externalQuoteAsync, quoteTitle, pendingSplit, setAllCustomOptions} = props;

  const approvalTypeWithoutRevisionChange = [RevisionType.CUSTOM_OPTION.toString(), RevisionType.QUOTE_APPROVAL.toString(), RevisionType.ORDER.toString()];

  const quoteApprovalContext = useMemo<ApprovalContextType>( () => ({
    quoteInApproval: quote,
    approval: approval,
  }) , [ quote, approval ]);

  const isSplitOrder = approvalDiff?.revisionType === RevisionType.SPLIT_ORDER;
  const isChangeOrder = approval?.approvalType === RevisionType.CHANGE_ORDER;
  const isEngineeringChange = approval?.approvalType === RevisionType.ENGINEERING_CHANGE;

  const isChangeOrderSalesDeskPoStep = (isChangeOrder && 
    (quote?.status === ChangeOrderStep.SALES_DESK_CHANGE_ORDER_PO_REQUEST || quote?.status === ChangeOrderStep.OLD_SALES_DESK_CHANGE_ORDER_PO_REQUEST));
  const isSalesProcessChangeOrder = (isChangeOrder && quote?.status === ChangeOrderStep.SALES_CHANGE_ORDER_PROCESS); // To be removed later
  const isEngineeringCompleteChange = (isChangeOrder && 
    (quote?.status === ChangeOrderStep.ENGINEERING_CHANGE_ORDER_COMPLETE_CHANGE || quote?.status === ChangeOrderStep.OLD_ENGINEERING_CHANGE_ORDER_COMPLETE_CHANGE));
  const isChangeOrderProcurmentStep = (isChangeOrder &&
    (quote?.status === ChangeOrderStep.PROCUREMENT_CHANGE_ORDER_REVIEW || quote?.status === ChangeOrderStep.OLD_PROCUREMENT_CHANGE_ORDER_REVIEW));

  const isNonCustomOptionChangeOrderSteps = isChangeOrderProcurmentStep || isSalesProcessChangeOrder || isEngineeringCompleteChange || isChangeOrderSalesDeskPoStep;

  const submitted = (quote?.quoteId === pendingSplit?.self && pendingSplit?.selfSubmitted)
  || (pendingSplit?.partners.some(p => p.quoteId === quote?.quoteId && p.partnerSubmitted)); 

  useEffect(() => {
    if (approval?.action !== ApprovalAction.APPROVED && approval?.action !== ApprovalAction.REJECTED) {
      loadFullPricingBreakdown(quoteId, revision);
      loadQuote(quoteId, revision, true);
      loadApprovalDiff(quoteId, revision, true);
    }
  }, [approval]);

  const existAdminOrConfigChange = () => {
    if (!approvalDiff) return false;
    const nonDiscountOptionsEmpty = !((approvalDiff?.nonDiscountOptionsDiff?.newNonDiscountOptions || []).concat(
      approvalDiff?.nonDiscountOptionsDiff?.oldNonDiscountOptions || []).length);

    const dealerAdjustmentsEmpty = !((approvalDiff?.dealerAdjustmentsDiff?.newDealerAdjustments || []).concat(
      approvalDiff?.dealerAdjustmentsDiff?.oldDealerAdjustments || []).length);

    const majorTableNotIncludedDiff = Object.values(MajorTableExcludedDiff).map(key => String(key));
    const includesKeys = Object.keys(approvalDiff || {}).some(key => !majorTableNotIncludedDiff.includes(key));
    const existAdminChange = includesKeys || !nonDiscountOptionsEmpty || !dealerAdjustmentsEmpty;

    const existConfigChange = !!(approvalDiff?.assembliesDiff?.addedAssemblies || []).concat(approvalDiff?.assembliesDiff?.removedAssemblies || []).length;

    return existAdminChange || existConfigChange;
  };

  const loadQuote = async (quoteId: string | undefined, revision: number | undefined, self: boolean): Promise<Quote | undefined> => {

    if (!quoteId) return;

    try {
      quoteAsync.setLoading();

      const resp = await configurator.api.getQuoteByRevision(quoteId, revision);
      const quote = resp.data;

      quoteAsync.setDone(quote);
      if (self && externalQuoteAsync) externalQuoteAsync.setDone(quote);

      return quote;
    } catch (e: any) {

      const err = (e instanceof NotFoundError) ? NOT_FOUND : e.message;
      const errorMsg = intl.formatMessage({ id: err });
      notification.error({ message: "Failed to load quote. " + errorMsg });
      quoteAsync.setFail(err);
    }

    return;
  };

  const loadFullPricingBreakdown = async (quoteId: string | undefined, rev?: number) => {
    if (!quoteId) return;

    pricingBreakdownAsync.isLoading();
    try {
      //fetch new pricing
      const resp = await configurator.api.fetchFullPricingBreakdownByQuote(quoteId, rev);
      pricingBreakdownAsync.setDone(resp.data);
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error({ message: "Failed to update pricing. " + errorMsg });
      pricingBreakdownAsync.setFail(e.message);
    }
  };

  const loadApprovalDiff = async (quoteId: string | undefined, revision: number | undefined, self: boolean) => {
    if (!quoteId) return;
    const requestWithoutDiff = approvalTypeWithoutRevisionChange.some(t => t === approval?.approvalType);
    if (requestWithoutDiff) return;

    approvalDiffAsync.isLoading();
    try {
      const resp = await configurator.api.diffRevisions(quoteId, revision);
      approvalDiffAsync.setDone(resp.data);
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error({ message: "Failed to fetch approval differences. " + errorMsg });
      approvalDiffAsync.setFail(e.message);
    }
  }

  const getQuoteId = () : string => {
    const quoteId = quote?.quoteId || "";
    const alreadySubmitted = submitted ? "Submitted" : undefined;
    const priceProcted = quote?.pendingSplitChange?.priceProtected ? "Price Protected" : undefined;
    if (!alreadySubmitted && !priceProcted) return quoteId;
    return `${quoteId} (${[alreadySubmitted, priceProcted].filter(v => v).join(", ")})`;
  }

  return (
      <>
        <style>
          {`
            .bordered-div {
              border: 3px solid #1677ff;
              border-radius: 10px;
              position: relative;
              padding: 25px 15px;
              margin-bottom: 20px;
              margin-top: 15px;
            }

            .bordered-div::before {
              content: attr(section-title);
              position: absolute; 
              top: -19px;
              left: 12px;
              background-color: white;
              padding: 0 5px;
              font-weight: bold;
              font-size: 20px;
              color: #1677ff;
            }
          `}
        </style>
        {!quote ? 
        <Spin tip={quoteId || ""} className="custom-spin"><div/></Spin>
         : 
        <div className={quoteTitle ? "bordered-div" : ""} section-title={quoteTitle}>

          {quoteTitle && <Title level={5}>{getQuoteId()}</Title>}

          <Spin spinning={pricingBreakdownAsync.isLoading()}>

            <table className="pricingBreakdown" >
              <tbody>
                {(!!quote?.incentivePrograms.length && !!pricingBreakdown?.dealerAdjustments?.length) && 
                <>
                  <tr key="dealer-price"><th >Dealer Price:</th><td >{Utils.formatMoney( pricingBreakdown?.dealerPrice)}</td></tr>

                  {pricingBreakdown.dealerAdjustments.map( (da, ndx) => <React.Fragment key={`da-node-${ndx}`}>
                    <tr>
                      <th >{da.label}</th>
                      <td className="price">{Utils.formatMoney(da.value)}</td>
                    </tr>
                    {!!da.notes && 
                    <tr>
                      <td colSpan={2} style={{fontStyle:"italic"}} title={da.notes}>{da.notes}</td>
                    </tr> }
                  </React.Fragment>)}
                </>}

                <tr key="total-price"><th style={{fontSize: "larger"}}>Total Price:</th><td >{Utils.formatMoney( pricingBreakdown?.totalPrice)}</td></tr>
              </tbody>
            </table>
          </Spin>

          {!existAdminOrConfigChange() && isChangeOrder && 
            <div style={{marginBottom: "1rem"}}>
              <strong>This change order only includes custom option.</strong>
            </div>
          }

          {<ApprovalContext.Provider value={quoteApprovalContext}>
            <CustomOptionApproval
              approval={approval}
              onSetErrorMessage={(msg: string | null) => {
                if (msg) approvalAsync.setFail(msg);
              }}
              onSetCustomOptions={setAllCustomOptions}
              quote={quote}
              disabled={!!approval?.action}
              isNonCustomOptionChangeOrderSteps={isNonCustomOptionChangeOrderSteps}
            />
          </ApprovalContext.Provider>}

          {!!approvalDiff && <div style={{marginBottom: "1rem"}}>

            {isSplitOrder && approvalDiff?.serialNumbersDiff &&
            <strong>SPLIT ORDER</strong>
            }

            {(isChangeOrder || isEngineeringChange) && <ApprovalDiffTable 
              diff={approvalDiff}
              loading={approvalDiffAsync.isLoading()}
            />}
          </div>}

        </div>}
  </>);
}
