import { Select } from "antd";
import { useEffect } from "react";
import { CANADA_PROVINCES, CountryCode, US_STATES } from "../api/models";


const StatesSelector = (
  props: {
    value?: string;
    onChange?: (state?: string ) => void;
    placeholder?: string;
    style?: any;
    disabled?: boolean
    country?: string
  }
) => {

  useEffect(() => {
    if (props.country && props.value)  {
      const states = getStates(props.country).map(s => s.value );
      const selected = states.includes(props.value);
      if ( !selected ) {
        props.onChange?.(undefined);
      }
    }
  }, [props.country]);

  const getStates = (country:string | undefined) => {
    switch (country) {
      case CountryCode.CA: 
      {
        return CANADA_PROVINCES;
      }
      case CountryCode.US:
      default:
      {
        return US_STATES;
      }
    }
  }

  return (
    <Select
      {...props}
      showSearch={true}
      optionFilterProp="value"
      allowClear={true}
      options={getStates(props.country).map(state => ({
        value:state.value,
        label:state.name
      }))}
      />
  );
}

export default StatesSelector;

