import styles from './InventoryDetail.module.css'
import { Badge, Button, Col, Divider, Drawer, DrawerProps, Dropdown, notification, Row } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { NumberParam, useQueryParam } from "use-query-params";
import { CommentTopic, PricingBreakdown, Quote, QuoteComment } from "../api/models";
import { ConfiguratorContext } from "../context";
import { useAsyncState } from "../hook/useAsyncState";
import NotFoundPage from "./not_found";
import { ConfigurationTabContents, DetailsTabContents, QuoteHeader, QuoteQuickViewContextProvider } from '../components/Quote/QuoteQuickView';
import QuoteCommentList from '../components/Quote/QuoteCommentList';
import QuoteContextProvider from '../contexts/QuoteContext';
import ExportQuotePdf from '../components/Quote/ExportQuotePdf';
import {MoreOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import dayjs from "dayjs";


const InventoryDetail = () => {

  const params = useParams<Record<string, string>>();
  const [revisionParam, _setRevisionParam] = useQueryParam<number | undefined | null >("rev", NumberParam);
  const quoteId = params.quoteId;
  const revision = revisionParam || undefined;


  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [_pricing, pricingAsync] = useAsyncState<PricingBreakdown>();
  const [quote, quoteAsync] = useAsyncState<Quote>();
  const exportViewRef = useRef(null);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [quoteCommentCnt, setQuoteCommentCnt] = useState<number | undefined>();
  const topic = [ CommentTopic.UserComment, CommentTopic.SystemActivity ]

  //load data one showing modal and data is init/fail
  useEffect(() => {

    Promise.all([
      loadQuote(quoteId, revision),
      loadPricing(quoteId),
      loadQuoteComments(quoteId)
    ]).then( ([_q, _p, commentLst]) => {
          setQuoteCommentCnt( commentLst?.length );
    });
  }, [quoteId]);


  const loadQuoteComments = async (quoteId:string) : Promise<QuoteComment[] | undefined> => {
    if ( !quoteId ) return;

    try {
      const resp = await configurator.api.fetchQuoteComments(quoteId, {topic});
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch comments " + errorMsg });
    }
    return;
  }

  const loadPricing = async (quoteId:string | undefined) => {
    if ( !quoteId ) return;

    pricingAsync.setLoading()

    try {
      const resp = await configurator.api.fetchFullPricingBreakdownByQuote( quoteId )
      pricingAsync.setDone(resp.data);
    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load pricing. " + errorMsg });
      pricingAsync.setFail(e.message);
    }
  }

  const loadQuote = async (quoteId:string | undefined, revision:number | undefined) : Promise<Quote | undefined> => {
    if ( !quoteId ) return;

    try {
      quoteAsync.setLoading();

      const resp = await configurator.api.getQuoteByRevision(quoteId, revision);
      const quote = resp.data;
      quoteAsync.setDone(quote);

      return quote;
    } 
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to get quote details. " + errorMsg

      notification.error( { message: msg, duration: 0 });
      quoteAsync.setFail(msg);
    }

    return;
  };

  if ( quoteAsync.isFail() ) return <NotFoundPage message="Quote not found" />;

  const quickViewContext = { quoteAsync, pricingAsync };

  const CommentsDrawer = (props:DrawerProps ) => {

    return <>
      <Drawer
        {...props}
        title={<>
          <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}} >
            <div>{quote?.partNumberString} Comment(s)</div>
          </div>
        </>}
      >
        <QuoteCommentList topics={topic} />
      </Drawer>
    </>
  }

  const quoteContext = {
    quoteAsync
  };

  const handleOnePageExport = () => {

    const element = exportViewRef.current;
    if (!element ) return;


    const ts = dayjs().format('YYYY-MM-DD.SSS');

    const doc = new jsPDF();
    doc.html(element, {
      callback: (doc) => {
        doc.save( `${quote?.quoteId}-onepage-${ts}.pdf` );
      },
      html2canvas:  { scale: .12 },
      margin: 10,
    });
  }

  return <div className="site-layout-background">
    <QuoteContextProvider value={quoteContext}>
      <QuoteQuickViewContextProvider value={quickViewContext}>
        <div style={{display: "flex", flexDirection: "row-reverse", gap: ".5rem"}}>

          <Dropdown trigger={["click"]}
            menu={{items:[
              {key: "specPdf", label: <ExportQuotePdf type="text" inventory={true} /> },
              {key: "onePagePdf", label: <Button type="text" onClick={ () => handleOnePageExport()}>One Page</Button> }
            ]}}
            destroyPopupOnHide={true}
            forceRender={true}
          >
            {/* this div is to avoid a warning with strict mode */}
            <div>
              <Button 
                type="primary" 
                icon={<MoreOutlined />} 
              >Export</Button>
            </div>
          </Dropdown>

          {configurator.isInternalSales() && 
          <Button type="primary" onClick={() => setShowComments(true)} 
          >
            Comments<Badge count={quoteCommentCnt} size="small" >&nbsp;</Badge>
          </Button>}
        </div>
        <div ref={exportViewRef}>
          <div className={styles['quote-quick-view-header']}>
            <QuoteHeader  />
          </div>
          <Row>
            <Col>
              <DetailsTabContents  /> 
              <Divider />
              <ConfigurationTabContents  hidePricing={quickViewContext.quoteAsync.val?.stock}/>
            </Col>
            <CommentsDrawer 
              open={showComments} 
              onClose={() => setShowComments(false)} />
          </Row>
        </div>
      </QuoteQuickViewContextProvider>
    </QuoteContextProvider>
  </div>
}

export default InventoryDetail;

