import {Button, Form, Input, notification, Radio, Select} from "antd";
import Table from "antd/es/table";
import dayjs from "dayjs";
import _ from "lodash";
import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Assembly, AssemblyInfo, BaseCategory, CategoryIdAssembliesIdMap, CustomOptionType, PAGINATION_MAX_PAGE_SIZE, QuoteAssemblyException, QuoteAssemblyExceptionOperation, QuoteAssemblyExceptionType} from "../../api/models";
import {ConfiguratorContext, ModelCategoryContext, ModelCategoryContextType} from "../../context";
import { useQuoteContext } from "../../contexts/QuoteContext";
import { useQuoteFormContext } from "../../contexts/QuoteFormContext";
import {useAsyncState} from "../../hook/useAsyncState";
import {QuoteAssemblyExceptionContext, QuoteAssemblyExceptionContextType} from "../../pages/configurator";
import Utils from "../../util/util";
import BMButton, {BMButtonProps} from "../BMButton";
import ModalWizard from "../ModalWizard";
import { WizardInstance } from "../Wizard";

type AssemblyExceptionButtonModalProps =  Omit<BMButtonProps, "id" | "value" | "onChange" > & {
  selectedCategory?:BaseCategory | undefined
  onAdd?:(ae:QuoteAssemblyException) => void
  onDelete?:(ae:QuoteAssemblyException[]) => void
};
const AssemblyExceptionButtonModal = (props: AssemblyExceptionButtonModalProps ) => {

  const {selectedCategory, onAdd, onDelete, ...btnProps} = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [_assemblyLst, assemblyLstAsync] = useAsyncState<Assembly[]>();
  const categoryId = Form.useWatch("categoryId", form); 
  const quoteAssemblyExceptionType = Form.useWatch("type", form); 
  const [selectedAssemblyExceptionLst, setSelectedAssemblyExceptionLst] = useState<QuoteAssemblyException[]>();
  const [categoryOptionLst, categoryOptionLstAsync] = useAsyncState<Assembly[]>();

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const { quoteAssemblyExceptionLstAsync, loadQuoteAssemblyExceptions } = useContext<QuoteAssemblyExceptionContextType>(QuoteAssemblyExceptionContext);
  const quoteAssemblyExceptionLst = quoteAssemblyExceptionLstAsync?.val?.filter( ae => !selectedCategory || ae.assembly.categoryId === selectedCategory.id );

  const { quoteAsync } = useQuoteContext();
  const { selectedModel, selectedOptions, selectedCustomOptions } = useQuoteFormContext();
  const modelId = selectedModel?.modelInfo.id;
  const quote = quoteAsync?.val;
  const quoteId = quote?.quoteId;

  const { modelCategoriesAsync, loadModelCategories} = useContext<ModelCategoryContextType>(ModelCategoryContext);
  const categoryLst = modelCategoriesAsync?.val;

  useEffect(() => {
    if ( isOpen && quoteId && (modelCategoriesAsync?.isInitial() || modelCategoriesAsync?.isFail() )) {
      loadModelCategories?.();
    }
  }, [quoteId, isOpen])

  useEffect(() => {
    if ( quoteId && (quoteAssemblyExceptionLstAsync?.isInitial() || quoteAssemblyExceptionLstAsync?.isFail() )) {
      loadQuoteAssemblyExceptions?.(quoteId);
    }
  }, [quoteId])

  useEffect(() => {
    if ( isOpen && categoryId ) {
      loadAssemblies(categoryId);
    }
    if (isOpen && categoryId && quote?.displayRevisionId && modelId && selectedOptions) {
      reloadCategoryOptions();
    }
    form.setFieldValue( "assemblyId", undefined );
  }, [quoteId, categoryId])

  useEffect(() => {
    form.resetFields();
    form.setFieldValue( "categoryId", selectedCategory?.id );
    form.setFieldValue( "type", quoteAssemblyExceptionType );
  }, [quoteAssemblyExceptionType])


  const handleCancel = () => {
    setIsOpen(false);
  }

  const loadAssemblies = async (categoryId:number) : Promise<Assembly[] | undefined> => {
    assemblyLstAsync.setLoading()

    try {
      const resp = await configurator.api.fetchFilteredAssemblies({categoryId:categoryId, size:PAGINATION_MAX_PAGE_SIZE})
      assemblyLstAsync.setDone(resp.data.content)
      return resp.data.content;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load assemblies. " + errorMsg });
      assemblyLstAsync.setFail(e.message);
    };

    return;
  }

  const saveAssemblyException = async (exception:QuoteAssemblyException) : Promise<QuoteAssemblyException | undefined> => {
    if ( !quoteId ) return;

    try {
      var resp = await configurator.api.saveQuoteAssemblyException(quoteId, exception );
      return resp.data;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to add assembly exception. " + errorMsg });
    };

    return;
  }

  const deleteAssemblyException = async (exceptionLst:QuoteAssemblyException[]) : Promise<QuoteAssemblyException[] | undefined> => {
    if ( !quoteId ) return;

    try {
      await Promise.all(
        exceptionLst.map(async (ae) => {
          if ( !ae.id ) return;

          return configurator.api.deleteQuoteAssemblyException(quoteId, ae.id );
        }).filter(v=>v)
      );

      return exceptionLst;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to remove assembly exception. " + errorMsg });
    };

    return;

  }

  const reloadCategoryOptions = async () : Promise<Assembly[] | undefined> => {
    if ( !quote ) return;
    if ( !modelId ) return;

    return await loadCategoryOptions( categoryId, quote?.displayRevisionId, modelId, selectedOptions, selectedCustomOptions );
  }

  const loadCategoryOptions = async (categoryId:number, quoteRevisionId:number, modelId:number, selectedOptions:CategoryIdAssembliesIdMap | undefined, selectedCustomOptions:CustomOptionType[] | undefined) : Promise<Assembly[] | undefined> => {
    if ( !selectedOptions ) return;

    try {
      categoryOptionLstAsync.setLoading();
      const resp = await configurator.api.fetchCategoryOptions(modelId, categoryId, { 
        selections: Object.values(selectedOptions).flat(),
        customOptions: selectedCustomOptions?.map(co => co.id),
        quoteRevisionId, 
      } );
      categoryOptionLstAsync.setDone( resp.data.options );

      return resp.data.options;

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load category options. " + errorMsg });
      categoryOptionLstAsync.setFail(e.message);
    }

    return;
  }


  const handleAddAssemblyException = async (nav:WizardInstance) => {
    if (!quoteId) return;

    try {
      const values = await form.validateFields();
      const savedException = await saveAssemblyException(values);
      if ( savedException ) {
        await loadQuoteAssemblyExceptions?.(quoteId);
        await reloadCategoryOptions();
        onAdd?.(savedException)
        nav.nextStep();
      }
    }
    catch(validationErrors) {
      notification.error( { message: "Please fix validation errors. " });
    }
  }

  const handleNew = (nav:WizardInstance) => {
    form.resetFields();
    form.setFieldValue( "categoryId", selectedCategory?.id );
    nav.nextStep();
  }

  const handleDelete = async () => {
    if (!quoteId) return;
    if ( !selectedAssemblyExceptionLst ) return;

    const exceptionLst = await deleteAssemblyException(selectedAssemblyExceptionLst);
    if ( exceptionLst?.length ) {
      onDelete?.(exceptionLst)
      loadQuoteAssemblyExceptions?.(quoteId);

      //uncheck
      setSelectedAssemblyExceptionLst( selectedAssemblyExceptionLst?.filter( ae => !exceptionLst.some( e => e.id === ae.id ) ) );
    }
  }

  //for add, show all assemblies in the category except the assemblies already available
  //for remove, show available assemblies
  // console.log( "categoryOptionLst", categoryOptionLst );
  // console.log( "assemblyLstAsync", assemblyLstAsync.val );
  const assemblyLst = quoteAssemblyExceptionType === QuoteAssemblyExceptionType.RULE_OVERRIDE
    ? assemblyLstAsync?.val?.filter( a => !categoryOptionLst?.some( co => co.id === a.id ) )
    : assemblyLstAsync?.val?.filter( a => a.obsoletedAt )

  const getAssemblyLabel = (a:AssemblyInfo) : string | undefined => (!!a.label?.length ? a.label : a.bomDescription);

  const handleSelectRow = (record:QuoteAssemblyException) => {

    //toggle previously selected
    const updated = selectedAssemblyExceptionLst?.filter( ae => ae.id !== record.id );
    if ( updated?.length === selectedAssemblyExceptionLst?.length  ) {
      setSelectedAssemblyExceptionLst( (updated || []).concat(record) );
      return;
    }

    setSelectedAssemblyExceptionLst(updated);
  }

  return <>
    <BMButton {...btnProps}
      onClick={() => setIsOpen(true)} 
      children={btnProps.children || "Assembly Exceptions"}
    />

    <Form form={form} initialValues={{operation: QuoteAssemblyExceptionOperation.ADD}} >
      <ModalWizard
        showSteps={false}
        open={isOpen}
        onCancel={handleCancel}
        style={{minWidth: "60rem"}}
        steps={[
          {
            key:1,
            title: "Select Exception",
            body: (nav) => <div key="step1">
              <div style={{display: "flex", flexDirection: "row-reverse", marginBottom: "1rem", gap: ".4rem"}}>
                <Button type="primary" onClick={() => handleNew(nav)} >New</Button>
                <Button onClick={handleDelete} disabled={!selectedAssemblyExceptionLst}>Delete</Button>
              </div>
              <style>
                {`
                  .dialog-assemblyExceptionLst .ant-table-content { padding: 5px; } /* don't clip corners */
                    .dialog-assemblyExceptionLst .ant-table-cell { border: none !important; } /* remove table cell borders */
                    /* add error border to table */
                    .ant-form-item-has-error .dialog-assemblyExceptionLst .ant-table-content {
                    border: solid 1px #ff4d4f;
                    border-radius: 15px;
                  }
                  `}
              </style>
              <Table 
                className="dialog-assemblyExceptionLst"
                showHeader={false}
                columns={
                  [ {
                  title: "Assembly",
                  render: (ae:QuoteAssemblyException) => <>
                    <div style={{fontWeight: 600}}>{getAssemblyLabel(ae.assembly)}, <span style={{whiteSpace:"nowrap"}}>{ae.assembly.bom}</span></div>
                    <div >{ae.reason} by {Utils.formatUsername(ae.createdBy)} on {dayjs(ae.createdAt).format("MM/DD/YYYY")}</div>
                  </>
                },
                {
                  title: "Category",
                  render: (ae:QuoteAssemblyException) => Utils.stripSortingPrefix(ae.assembly.categoryName)
                },
                {
                  title: "Type",
                  render: (ae:QuoteAssemblyException) => ae.type == QuoteAssemblyExceptionType.OBSOLETE ? "Obsolete" 
                  : ae.type == QuoteAssemblyExceptionType.RULE_OVERRIDE ? "Rule Override" 
                  : ae.type
                },
                ]}
                rowKey="id"
                loading={quoteAssemblyExceptionLstAsync?.isLoading()}
                dataSource={quoteAssemblyExceptionLst}
                onRow={(record, _rowIndex) => {
                  return {
                    onClick: () => handleSelectRow(record)
                  };
                }}
                rowSelection={{
                  type: "checkbox",
                  onSelect: handleSelectRow,
                  selectedRowKeys: (selectedAssemblyExceptionLst?.map( ae => ae.id ) || [] ) as number[],
                }}
              />
            </div>,
            footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="done" type="primary" onClick={handleCancel}>Done</Button>
            </div>,
          },
          {
            key:2,
            title: "Select Exception Type",
            body: (nav) => <div key="step2">
              <div style={{display: "flex", justifyContent: "center", paddingTop: "3rem" }}>
                <Form.Item
                  name="type"
                >
                  <Radio.Group >
                    <div style={{display:"flex", flexDirection: "column", gap: "1rem" }}>
                      <div><Radio value={QuoteAssemblyExceptionType.RULE_OVERRIDE} onClick={nav.nextStep} >Add BOM</Radio></div>
                      <div><Radio value={QuoteAssemblyExceptionType.OBSOLETE} onClick={nav.nextStep}>Enable Obsolete BOM</Radio> </div>
                    </div>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>,
            footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="next" type="primary" onClick={nav.nextStep} disabled={!quoteAssemblyExceptionType}>Next</Button>
              <Button key="back" onClick={nav.prevStep}>Back</Button>
            </div>
          },
          {
            key:3,
            title: "Add Exception" ,
            body:  (nav) => <div  key="step3">
              <div style={{paddingTop: "1rem" }}>

                <Form.Item
                  name="categoryId"
                  hidden={!!selectedCategory}
                >
                  <Select
                    placeholder="Filter Category"
                    loading={modelCategoriesAsync?.isLoading()}
                    showSearch
                    optionFilterProp="label"
                    options={categoryLst?.map(c => ({ label: c.name, value:c.id }))}
                  />
                </Form.Item>

                <Form.Item
                  name="assemblyId"
                  rules={[{
                    required: true,
                    message: `An assembly is required.`,
                  }]}
                >
                  <Select
                    placeholder="Select BOM"
                    loading={assemblyLstAsync.isLoading()}
                    showSearch
                    optionFilterProp="label"
                    options={assemblyLst?.map(asm => ({ label: `${asm.bom} ${asm.label || asm.bomDescription}`, value:asm.id }))}
                  />
                </Form.Item>

                <Form.Item
                  name="operation"
                  hidden={true}
                >
                  <Input />
                </Form.Item>


                <Form.Item
                  name="reason"
                  rules={[{
                    required: true,
                    message: `Please add a reason for this exception.`,
                  }]}
                >
                  <Input placeholder={"Add a reason for this exception"} />
                </Form.Item>
              </div>
            </div>,
            footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="save" type="primary" onClick={() => handleAddAssemblyException(nav)} >Save</Button>
              <Button key="back" onClick={nav.prevStep}>Back</Button>
            </div>
          }
        ]}    
      />
    </Form>
  </>;

}

export default AssemblyExceptionButtonModal;
