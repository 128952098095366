import {Button, Space, Tooltip} from "antd";
import { 
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";


const ToggleApproved = (props:{
  value?: boolean | undefined | null
  onChange?: (v: boolean | null) => void
}) => {

  const onChange = (v:boolean) => {
    if ( !props.onChange ) return;

    if ( v === props.value ) {
      props.onChange( null );
      return;
    }
    props.onChange(v);
  }
  return <Space size="middle">
    <Tooltip title="Reject">
      <Button 
        danger={props.value === false}
        shape="circle" type={props.value === false ? "primary" : "default"}
        onClick={() => onChange(false)} >
        <CloseOutlined /></Button>
    </Tooltip>

    <Tooltip title="Accept">
      <Button 
        shape="circle" type={props.value === true ? "primary" : "default"}
        onClick={() => onChange(true)} >
        <CheckOutlined /></Button>
    </Tooltip>
  </Space>
  
}

export default ToggleApproved;

