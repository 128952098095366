import {Button, Tabs, Image, notification, Row, Col, Form, Divider, Input, Checkbox, Descriptions } from "antd";
import {useForm} from "antd/es/form/Form";
import Table, {ColumnType, TablePaginationConfig} from "antd/es/table";
import {SorterResult} from "antd/es/table/interface";
import {debounce} from "lodash";
import {useCallback, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {ListModelsRequest} from "../../api";
import {ModelInfo, CabStyle, FuelType, FuelTypes, SortDirection, Quote, CabFuelImageLst, FuelTypeColor} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import BMButton, {BMButtonProps} from "../BMButton";
import IncentiveProgramSelector from "../incentive_program_selector";
import ModalWizard from "../ModalWizard";
import {useHistory} from "react-router-dom";
import _ from "lodash";
import {useModelSelection} from "../../hook/useModelInfo";
import { useQuoteContext } from "../../contexts/QuoteContext";

type ModelInfoSort = SorterResult<ModelInfo> | SorterResult<ModelInfo>[]
type ModelFilter = Omit<ListModelsRequest, 'page' | 'size' | 'sort'> ;

const DEFAULT_PAGE_SIZE = 4;

export type NewQuoteInfo = {
  cabStyle?: CabStyle | undefined
  fuelType?: FuelType | undefined
  modelInfo?: ModelInfo | undefined
  quoteName?: string | undefined
  bmSalesReps?: string[] | undefined
}
const NewQuoteWizard = (props: BMButtonProps ) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {adminView} = useQuoteContext();

  const [newQuoteInfo, setNewQuoteInfo] = useState<NewQuoteInfo | undefined>();
  const [_quote, quoteAsync] = useAsyncState<Quote>();
  const [modelSelection] = useModelSelection();

  const intl = useIntl();
  const history = useHistory();
  const configurator = useContext(ConfiguratorContext);

  const isDealer = !!configurator.userInfo?.dealerName;

  const createQuote = async (quoteInfo:NewQuoteInfo, selections:number[] | undefined) : Promise<Quote | undefined> => {

    if (!quoteInfo.quoteName) return;
    if (!quoteInfo.modelInfo?.id) return;
    if (!selections) return;

    try {
      quoteAsync.setLoading();
      const resp = await configurator.api.createQuote({
        modelId: quoteInfo.modelInfo?.id,
        name: quoteInfo.quoteName,
        quantity: 1,
        selections,
      });
      const quote = resp.data;
      quoteAsync.setDone( quote );

      return quote;
    } catch (e: any) {

      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = (!!errorMsg)
        ? "Failed to create quote. " + errorMsg
        : "Failed to create quote";

      notification.error( { message: msg });
      quoteAsync.setFail(msg);
    }

    return;
  }


  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleCancel = () => {
    setIsOpen(false)
  }

  const handleDone = async () => {
    if ( !newQuoteInfo ) return;

    quoteAsync.setLoading();
    const options = await modelSelection.fetch( newQuoteInfo.modelInfo?.id, newQuoteInfo.fuelType, newQuoteInfo.cabStyle );

    const selections = options ? Object.values(options).flat() : [];
    const quote = await createQuote(newQuoteInfo, selections);

    if ( quote ) {
      setIsOpen(false)
      history.push("/configurator/" + encodeURI(quote.quoteId));
    }
  }

  const handleSelectedModel = (model:ModelInfo | undefined) => {
    if ( model?.id === newQuoteInfo?.modelInfo?.id ) return;

    setNewQuoteInfo({
      ...newQuoteInfo,
      modelInfo: model
    });
  }

  const handleChangeQuoteName = (quoteName:string | undefined ) => {
    setNewQuoteInfo({
      ...newQuoteInfo,
      quoteName
    });
  }

  return <>
    <BMButton 
      {...props}
      onClick={handleOpen}
    >{props.children}</BMButton>
    <ModalWizard
      style={{minWidth: "40rem"}}
      open={isOpen}
      onCancel={handleCancel}
      afterOpenChange={() => {
        setNewQuoteInfo(undefined);
        quoteAsync.setInit();
      }}
      steps={[ 
        {
          key: 1,
          title: "Cab Style",
          body: (nav) => <div key="ModelTypeSelector">
              <div style={{marginTop: "1.5rem"}} >
                <ModelTypeSelector value={{ cabStyle: newQuoteInfo?.cabStyle, fuelType: newQuoteInfo?.fuelType }} 
                  onChange={(v) => {
                    setNewQuoteInfo({
                      ...newQuoteInfo,
                      fuelType: v?.fuelType,
                      cabStyle: v?.cabStyle,
                      modelInfo: undefined,
                    });
                    nav?.nextStep();
                  }}  />
              </div>
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={nav?.nextStep} disabled={!newQuoteInfo?.cabStyle}>Next</Button>
            <Button key="cancel" onClick={handleCancel}>Cancel</Button>
          </div>
        },
        {
          key: 11111,
          title: "Truck Model",
          body: (_nav) => <FilteredSelectModelTable key="FilteredSelectModelTable" 
            cabStyle={newQuoteInfo?.cabStyle}
            fuelType={newQuoteInfo?.fuelType}
            adminView={adminView} 
            value={newQuoteInfo?.modelInfo}
            onChange={handleSelectedModel} />,
            footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="next" type="primary" disabled={!newQuoteInfo?.modelInfo} onClick={nav?.nextStep} >Next</Button>
              <Button key="back" onClick={nav?.prevStep}>Back</Button>
            </div>
        },
        {
          key: 111,
          title: "Quote Name",
          body: (_nav) => <div key="quoteName">
            <div style={{marginTop: "1rem", paddingLeft: "1rem"}} >
              <QuoteNameStep  
                cabStyle={newQuoteInfo?.cabStyle}
                fuelType={newQuoteInfo?.fuelType}
                modelInfo={newQuoteInfo?.modelInfo}
                value={newQuoteInfo?.quoteName}
                onChange={handleChangeQuoteName}
              />
            </div>
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" disabled={!newQuoteInfo?.quoteName} onClick={handleDone} loading={quoteAsync.isLoading()}>Done</Button>
            <Button key="back" onClick={nav?.prevStep} disabled={quoteAsync.isLoading()}  >Back</Button>
          </div>
        },
      ]} 
    />
  </>
}

interface ModelTypeSelectorValue {
  cabStyle: CabStyle | undefined 
  fuelType: FuelType | undefined
}
const ModelTypeSelector = (props:{
  id?:string
  value?: ModelTypeSelectorValue
  onChange?: (v:ModelTypeSelectorValue | undefined) => void
}) => {


  const [activeFuelType, setActiveFuelType] = useState<FuelType |undefined>();
  const handleSelectModelType = ( mt:CabStyle, ft:FuelType ) => {
    setActiveFuelType(ft);
    props.onChange?.( {
      cabStyle: mt,
      fuelType: ft
    });
  }

  return <>
    <style>
      {`
        .fuelTypeTab.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn
        {
          color:white;
        }
        .fuelTypeTab [data-node-key="BEV"]:hover,  
        .fuelTypeTab [data-node-key="BEV"].ant-tabs-tab-active
        {
          color:white !important;
          background: #40b8e4 !important;
        }
        .fuelTypeTab [data-node-key="DIESEL"]:hover, 
        .fuelTypeTab [data-node-key="DIESEL"].ant-tabs-tab-active
        {
          color:white !important;
          background: #ed1c24 !important;
        }
        .fuelTypeTab [data-node-key="CNG"]:hover, 
        .fuelTypeTab [data-node-key="CNG"].ant-tabs-tab-active
        {
          color:white !important;
          background: #23C01B !important;
        }
        `}
    </style>
    <Tabs tabPosition="left" className="fuelTypeTab" activeKey={(activeFuelType || props.value?.fuelType)?.name.toUpperCase()}
      onChange={(key) => setActiveFuelType( FuelTypes[key] )}
      items={Object.values( FuelTypes ).map( ft => ({ 
        key: ft.name.toUpperCase(), 
        label:ft.description,
        children: <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly", alignItems: "bottom",}}>
          {CabFuelImageLst?.filter( cfi => cfi.fuelType === ft )
            .map( cfi => {
              const ct = cfi.cabStyle;
              const selectedStyle = (ct.name === props.value?.cabStyle?.name && ft.name === props.value?.fuelType?.name) ? {
                borderColor: FuelTypeColor[ft.valueText.toUpperCase()],
                borderStyle: "solid",
                borderRadius: "10px",
                borderWidth: "4px",
                padding: "4px"
              } : undefined;
              return <div
                key={ [ct.name, ft.name].join("-")}  
                style={{width: "120px", marginRight: "2rem", textAlign:"center"}}
              >
              <Button 
                type="text" 
                className="ghostBmButton" 
                style={{height:"140px"}}
                onClick={() => handleSelectModelType( ct, ft )}>
                <Image src={cfi.imageUri} preview={false} width={120} style={selectedStyle} />
                <div >{ct.description}</div>
              </Button>
              </div>})}
        </div>
      }))} />
    </>
}


const FilteredSelectModelTable = (props:{
  id?:string
  value?: ModelInfo
  cabStyle?: CabStyle
  fuelType?: FuelType
  adminView: boolean | undefined
  onChange?: (v: ModelInfo | undefined) => void
}) => {

  const [form] = useForm();
  const [filter, setFilter] = useState<ModelFilter | undefined>();

  const updateFilter = (values:any) => {
        setFilter({
          ...values,
          cabStyles: props.cabStyle ? [props.cabStyle?.name] : undefined,
          fuelTypes: props.fuelType ? [props.fuelType?.name] : undefined,
        });
  }

  useEffect(() => {
    updateFilter(filter);
  }, [props.cabStyle, props.fuelType]);

  return <>
    <Form 
      form={form}
      layout="vertical"
      onValuesChange={(_changed, values) => {
        updateFilter(values);
      }}
      labelAlign="right"
    >
      <Row justify="space-between">

        <Col span={8}>

          <Form.Item name="search" label="Search" >
            <Input className="modelFilter" placeholder="Search Models" allowClear />
          </Form.Item>

          <Form.Item
            name="incentivePrograms"
            label="Incentive Program"
          >
            <IncentiveProgramSelector placeholder="Filter By Incentive" />
          </Form.Item>

          <Form.Item
            name="inactive"
            valuePropName="checked"
            hidden={!props.adminView}
          >
            <Checkbox>Show Inactive</Checkbox>
          </Form.Item>
        </Col>

        <Col>
          <Divider type="vertical" style={{height: "100%", borderWidth: "2px"}} />
        </Col>

        <Col span={15}>
            <SelectModelTable filter={filter} value={props.value} onChange={props.onChange} />
        </Col>

      </Row>
    </Form>
  </>;

}


const SelectModelTable = (props:{
  id?:string
  value?: ModelInfo
  filter?: ModelFilter
  onChange?: (v: ModelInfo | undefined) => void
}) => {

  const selectedModelId = props.value?.id; 

  const [pagination, setPagination] = useState<TablePaginationConfig>({ pageSize: DEFAULT_PAGE_SIZE, defaultCurrent: 1 });
  const [modelLst, modelLstAsync] = useAsyncState<ModelInfo[]>();
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  useEffect(() => {
    setPagination({ pageSize: DEFAULT_PAGE_SIZE, defaultCurrent: 1 })
  }, [props.filter?.cabStyles, props.filter?.fuelTypes]);

  useEffect(() => {

    //reduce unnecessary loads by requiring fuel types
    if ( props.filter?.cabStyles && props.filter.fuelTypes ) {

      const sort = { field: 'name', direction: 'asc' };
      loadModels(modelLstAsync, pagination, props.filter, sort);
    }
  }, [pagination.pageSize, pagination.current, props.filter]);

  const loadModels = useCallback(debounce( async (modelLstAsync:AsyncState<ModelInfo[]>, pagination: TablePaginationConfig, filter: ModelFilter | undefined, sorter:ModelInfoSort) => {
    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || "name",
      direction: ( sorter.order === 'descend' ? 'desc' : 'asc') as SortDirection,
    }));

    modelLstAsync.setLoading();

    try {

      const resp = await configurator.api.listModels( {
        ...filter,
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        sort,
      });
      modelLstAsync.setDone(resp.data.content);
      setPagination({ ...pagination, total: resp.data.totalElements });
      return resp.data.content;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load models. " + errorMsg });
      modelLstAsync.setFail(e.message);
    };

    return;
  }, 800), []);


  const columns:ColumnType<ModelInfo>[] = [{
    dataIndex: "name",
    title: "Name"
  }];

  const handleSelectRow = (record:ModelInfo) => {

    //toggle previously selected
    if ( selectedModelId === record.id ) {
      props.onChange?.(undefined);
      return;
    }

    props.onChange?.(record);
  }

  return <>
    <style>
      {`
        .modelLst .ant-table-content { padding: 5px; } /* don't clip corners */
          .modelLst .ant-table-cell { border: none !important; } /* remove table cell borders */
          /* add error border to table */
          .ant-form-item-has-error .modelLst .ant-table-content {
          border: solid 1px #ff4d4f;
          border-radius: 15px;
        }
        `}
    </style>

    <Table 
      className="modelLst"
      showHeader={false}
      columns={columns}
      rowKey="id"
      dataSource={modelLst}
      loading={modelLstAsync.isLoading()}
      pagination={pagination}
      onChange={setPagination}
      onRow={(record, _rowIndex) => {
        return {
          onClick: () => handleSelectRow(record)
        };
      }}
      rowSelection={{
        type: "radio",
        onSelect: handleSelectRow,
        selectedRowKeys: [...(selectedModelId ? [selectedModelId] : [])],
      }}
    />

  </>
}

const QuoteNameStep = (props:{
  cabStyle: CabStyle | undefined
  fuelType: FuelType | undefined
  modelInfo: ModelInfo | undefined
  value: string | undefined
  onChange?: (v:string | undefined) => void
}) => {
 
  const [form] = useForm();

  const cabImg = CabFuelImageLst.find( ci => ci.cabStyle === props.cabStyle && ci.fuelType === props.fuelType );

  return <>
    <Row justify={"start"} gutter={20} >
      <Col span={6}>
        <Image src={cabImg?.imageUri} preview={false} width={120} />
      </Col>
      <Col  span={18}>
        <Descriptions
          column={1}
          items={[
            { key: "cabStyle", label: "Cab Style", children: props.cabStyle?.name },
            { key: "fuelType", label: "Fuel Type", children: props.fuelType?.name },
            { key: "modelInfo", label: "Model", children: props.modelInfo?.name },
          ]}
          labelStyle={{fontWeight: "bold", color: "black"}}
        />
      </Col>
    </Row>
    <Form
      style={{marginTop: "2rem", paddingLeft: ".5rem", paddingRight: ".5rem"}}
      form={form}
      onValuesChange={(v) => props.onChange?.(v.quoteName)}
      initialValues={{
        quoteName: props.value,
      }}
    >
      <Form.Item label="Quote Name"
        name="quoteName"
        rules={[
          {
            required: true,
            message: "Quote Name is required",
          },
        ]}
      >
        <Input placeholder="Your quote name" />
      </Form.Item>
      
    </Form>
  </>;

}


export default NewQuoteWizard;
