import styles from './QuoteQuickView.module.css'
import { useIntl } from "react-intl";
import {Checkbox, Col, Descriptions, Dropdown, Image, Input, notification, Row, Space, Spin, Table, Tabs} from "antd";
import {ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { AssemblyInfo, IncentiveProgram, PricingBreakdown, Quote, CabFuelImageLst, Category } from "../../api/models";
import {ConfiguratorContext} from "../../context";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import Utils from "../../util/util";
import PricingView from "./PricingView";
import dayjs from 'dayjs'
import Title from "antd/lib/typography/Title";
import { DescriptionsItemType } from 'antd/es/descriptions';
import React from 'react';
import { useModelSelection } from '../../hook/useModelInfo';
import { useCategoryContext } from '../../contexts/CategoryContext';
import { MoreOutlined } from "@ant-design/icons";
import BMButton from '../BMButton';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { Link, useLocation } from "react-router-dom";
import Configurator from '../../pages/configurator';

type SectionAssemblyMap = Record<string, Array<AssemblyInfo>>;

export interface QuoteQuickViewContextType {
  quoteAsync?:AsyncState<Quote>
  pricingAsync?:AsyncState<PricingBreakdown>
}
const QuoteQuickViewContext = React.createContext<QuoteQuickViewContextType>({
});

export const useQuoteQuickViewContext = () : QuoteQuickViewContextType => {
    return useContext(QuoteQuickViewContext);
}

// Data provider component
export const QuoteQuickViewContextProvider = (props:{ value:QuoteQuickViewContextType, children: ReactElement | ReactElement[] }) => {

  const quoteContext = useMemo(() => props.value, [
      props.value.quoteAsync,
      props.value.pricingAsync,
    ]);

  return <QuoteQuickViewContext.Provider value={quoteContext}>{props.children}</QuoteQuickViewContext.Provider>;
};
  
export const QuoteHeader = (props:{
  quote?:Quote
}) => {

  const configurator = useContext(ConfiguratorContext);

  const { quoteAsync, pricingAsync } = useQuoteQuickViewContext();
  const quote = props.quote || quoteAsync?.val;

  const location = useLocation();
  const isInventoryView = location.pathname.includes( 'inventory' );

  const name = quote?.stock ? quote?.truckDescription
    : isInventoryView ? quote?.truckDescription
    : !!quote?.name ? quote.name
    : quote?.truckDescription

  const imageBorderStyle = {
  };
  return <>
    <Space>
      <div style={{...imageBorderStyle, marginBottom: "1rem", marginRight: "1rem" }} >
        <TruckImage />
      </div>
      <div>
        <Row>
          <Col><Title level={4} style={{marginBottom: ".3rem"}}>{name}</Title></Col>
        </Row>
        <Row justify="space-between" style={{width: "100%"}}>
          <Col><Title level={5} style={{marginBottom: ".3rem"}}>
            {configurator.isInternalSales() 
              ? <span>(<Link to={"/configurator/" + encodeURI(quote?.quoteId || "")} target="_blank">{quote?.quoteId}</Link>)</span>
              : <span>({quote?.quoteId})</span>}
          </Title>
          </Col>
          <Col><Title level={5} style={{marginBottom: ".3rem"}}>{Utils.formatMoney(pricingAsync?.val?.totalPrice)}</Title></Col>
        </Row>
        {!quote?.stock &&
          <Row>
            <div className='ellipsis' style={{maxWidth: "100%"}}><span className={styles.label} >Status:</span>{quote?.status}</div>
          </Row> }
      </div>
    </Space>

  </>

}

const TruckImage = () => {

  const { quoteAsync } = useQuoteQuickViewContext();
  const quote = quoteAsync?.val;

  const [modelInfo] = useModelSelection();
  const [imageUri, setImageUri] = useState<string>();

  Promise.all([
    modelInfo.fetchCabStyle( quote?.selections ),
    modelInfo.fetchFuelType( quote?.selections )
  ]).then(([cabStyle, fuelType]) => {
      const truck = CabFuelImageLst?.filter( cfi => cfi.fuelType === fuelType && cfi.cabStyle === cabStyle ).find(v=>v);
      setImageUri(truck?.imageUri);
    });

  return <Image src={imageUri} preview={false} width={120}  />
}

export const ConfigurationTabContents = ( props: {
  hidePricing?:boolean
}) => {

  const { quoteAsync } = useQuoteQuickViewContext();
  const quote = quoteAsync?.val;

  const configurator = useContext(ConfiguratorContext);
  const isAdminOrEngineering = ( configurator.isAdmin() || configurator.isEngineering() );

  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string | undefined | null>("filter", StringParam);
  const [showHiddenParam, setShowHiddenParam] = useQueryParam<boolean | undefined | null>("showHidden", BooleanParam);
  const [showConfiguratorOnlyParam, setShowConfiguratorOnlyParam] = useQueryParam<boolean | undefined | null>("showConfiguratorOnly", BooleanParam);

  const [selectionInfo, selectionInfoAsync] = useAsyncState<AssemblyInfo[]>();
  const [filter, setFilter] = useState<string | undefined>(searchFilterParam || undefined);
  const [isShowHiddenCategories, setShowHiddenCategories] = useState<boolean>(!!showHiddenParam);
  const [isShowConfiguratorOnly, setShowConfiguratorOnly] = useState<boolean>(showConfiguratorOnlyParam ?? true);

  const intl = useIntl();

  const {categoriesAsync, loadCategories } = useCategoryContext();
  useEffect( () => {
    if ( categoriesAsync?.isInitial() ) {
      loadCategories?.();
    }
  }, [categoriesAsync]);
  const categoryMap:Record<number,Category> | undefined = useMemo(() => 
    categoriesAsync?.val?.reduce( (acc, v ) => {
      acc[v.id] = v
      return acc;
    }, {} ), [categoriesAsync] );

  //load data one showing modal and data is init/fail
  useEffect(() => {

    const refetch = selectionInfoAsync.isInitial()
    if ( refetch ) {
      loadSelectedOptions(quote?.quoteId);
    }
  }, [quoteAsync?.val?.quoteId]);

  const loadSelectedOptions = async (quoteId:string | undefined) : Promise<AssemblyInfo[] | undefined> => {
    if ( !quoteId ) return;

    selectionInfoAsync.setLoading()

    try {
      const resp = await configurator.api.fetchSelectionsByQuoteId( quoteId, { hideNoOption: true } )
      selectionInfoAsync.setDone(resp.data);
      return resp.data;
    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get selection info. " + errorMsg });
      selectionInfoAsync.setFail( e.message );
    }
    return;
  }

  const handleChangeFilter = (filter:string) => {
    setSearchFilterParam(filter);
    setFilter(filter);
  }

  const handleShowConfiguratorOnly = (enable:boolean) => {
    setShowConfiguratorOnlyParam(enable);
    setShowConfiguratorOnly(enable);
  }

  const handleShowHidden = (enable:boolean) => {
    setShowHiddenParam(enable);
    setShowHiddenCategories(enable);
  }

  const isViewableSection = (s:AssemblyInfo) : boolean => {
    const category = categoryMap?.[s.categoryId];
    if ( !category ) return false;

    if ( isAdminOrEngineering ) return true;
    if( category.hidden ) return false;
    return true;
  }

  const searchFilter = Utils.splitByWhitespacePreservingQuotes(filter);
  const isSelectionHidden = (asm:AssemblyInfo) : boolean => {
    const category = categoryMap?.[asm.categoryId];
    if ( !category ) return false;
    if ( !isShowHiddenCategories && category.hidden ) return false;
    if ( !isShowConfiguratorOnly && asm.bom.startsWith('9999-CUSTOM') ) return false;

      const asmSearch =  [
        category.name,
        asm.bom,
        asm.label,
        asm.bomDescription,
      ]
      .filter( v => v )
      .join( " ");

    return Utils.searchValue( searchFilter, asmSearch );
  }

  const sections:SectionAssemblyMap | undefined = selectionInfo
  ?.filter( isViewableSection )
  ?.filter( isSelectionHidden )
  .reduce( (acc, asm ) => {

    const category = categoryMap?.[asm.categoryId];
    if ( !category ) return acc;

    const section = category.configuratorSection;
    acc[ section ] = (acc[ section ] || [] ).concat( [ asm ] );
    return acc;
  }, {} );

  const sectionTitles = categoriesAsync?.val
  ?.map( c => c.configuratorSection )
  //filter out sections with no options
  ?.filter( s => sections?.[ s ] )
  .reduce( (acc, s) => {
    if( !acc.includes(s) ) {
      acc.push(s);
    }
    return acc;
  },new Array<string>());
  Object.values( sections || {} ).forEach(s => s.sort( (a,b) => a.categoryName.localeCompare(b.categoryName)) );

  const optionActionItems = new Array<MenuItemType>();

  if ( isAdminOrEngineering ) {
    optionActionItems.push(
      {
        key: "showHiddenCategories",
        label:
        <div >
          <label >
            <Checkbox
              onChange={(e) => handleShowHidden(e.target.checked)}
              checked={isShowHiddenCategories}
              style={{padding:0}}
            />
            <span style={{marginLeft: "0.5rem"}}>
              Show Hidden Categories
            </span>
          </label>
        </div>
      });

    optionActionItems.push( {
      key: "showConfiguratorOnly",
      label:
      <div >
        <label >
          <Checkbox
            onChange={(e) => handleShowConfiguratorOnly(e.target.checked)}
            checked={isShowConfiguratorOnly}
            style={{padding:0}}
          />
          <span style={{marginLeft: "0.5rem"}}>
            Show Configurator-Only BOM
          </span>
        </label>
      </div>
    });
  }


  return <>
    <Spin spinning={selectionInfoAsync.isLoading()}>
      <Space direction='vertical'>

      <div style={{display: "flex", gap: ".5rem" }} data-html2canvas-ignore={true} >
        <Input onChange={(e) => handleChangeFilter(e.target.value)}
          placeholder="Filter assemblies" allowClear
        />

          {!!optionActionItems.length &&
            <Dropdown trigger={["click"]}
              menu={{items:optionActionItems}}
            >
              {/* this div is to avoid a warning with strict mode */}
              <div>
                <BMButton 
                  icon={<MoreOutlined/>} 
                  data-testid="quote-options-btn"
                >Options</BMButton>
              </div>
            </Dropdown>
          }

      </div>

      <div style={{display: "flex", justifyContent:"space-between", flexWrap: "wrap" }}>
      
        {sectionTitles?.map( (title, ndx) => {
          const assemblies = sections?.[ title ]
          if ( !assemblies?.length ) return <></>

          return <React.Fragment key={[title, ndx].join("-")}>
              <Table style={{minWidth: "20rem", width: "48%", marginBottom: "1rem"}}
                rowKey="id"
                size="small"
                columns={[
                  {
                    title,
                    render: (asm) => <div>
                      <div><span style={{fontWeight: 600}}>{Utils.stripSortingPrefix(asm.categoryName)}:</span> {asm.bom}</div>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>{asm.label || asm.bomDescription}</div>

                        {(true || !props.hidePricing) &&
                          <div style={{textAlign: "right"}}>
                            {(asm.upgradeDealerPrice || 0) !== 0 ? Utils.formatMoneyWithSign( asm.upgradeDealerPrice ) : "Standard"}
                          </div>
                        }
                      </div>
                    </div>,
                  }
                ]}
                pagination={false}
                dataSource={assemblies}
              />
          </React.Fragment>
      })}
      </div>

      </Space>
     
    </Spin>
  </>

}

export const PricingTabContents = () => {
  const { pricingAsync } = useQuoteQuickViewContext();

  const context = useContext(ConfiguratorContext);
  const adminView = context.isAdmin() || context.isEngineering();

  const pricing = pricingAsync?.val;

  return <>
    <Spin spinning={pricingAsync?.isLoading()}>
      <PricingView 
        adminView={adminView}
        disabled={true}
        pricingDetails={pricing}
      />
    </Spin>
  </>

}

export const DetailsTabContents = () => {

  const configurator = useContext(ConfiguratorContext);
  const [incentiveProgramLst, incentiveProgramLstAsync] = useAsyncState<IncentiveProgram[]>();
  const intl = useIntl();

  const { quoteAsync } = useQuoteQuickViewContext();
  const quote = quoteAsync?.val;

  //load data one showing modal and data is init/fail
  useEffect(() => {

    if ( quoteAsync?.isDone() ) {
      loadIncentivePrograms( quoteAsync?.val?.model.id );
    }
  }, [quoteAsync?.val?.quoteId]);

  const loadIncentivePrograms = async (modelId:number | undefined) => {
    if ( !modelId ) return;

    incentiveProgramLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchIncentivePrograms( modelId );
      incentiveProgramLstAsync.setDone(resp.data);
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get incentive programs. " + errorMsg });
      incentiveProgramLstAsync.setFail(e.message);
    }
  };

  const incentivePrograms = incentiveProgramLst?.filter(i => quote?.incentivePrograms.map(q => q.id).includes(i.id))
  .map(i => i.name )
  .join(", ");

  const items:DescriptionsItemType[] = [];

  items.push( {
      label: "Model",
      children: [quote?.modelYear, quote?.model.name].filter(v=>v).join(" ")
    }
  );

  if ( quote?.partNumberString ) {
    items.push( {
      label: "Part No",
      children: quote?.partNumberString
    })
  }

  if ( quote?.serialNumberStr ) {
    items.push( {
      label: "Serial Numbers",
      children: quote?.serialNumberStr
    })
  }




  if( !!quote?.incentivePrograms.length ) {
    items.push({
      label: "Incentive Program",
      children: incentivePrograms
    })
  }

  if( !quote?.stock ) {
    items.push({
      label: "Quantity",
      children: quote?.quantity
    })
  }

  if( !quote?.stock ) {
    items.push({
      label: "Concession",
      children: Utils.formatPercent( ( quote?.percentDiscount || 0 ) / 100)
    })
  }

  if( quote?.poNumber && !quote?.stock ) {
    items.push({
      label: "PO Number",
      children: quote?.poNumber?.poNumber
    })
  }

  if( quote?.dealerCustomer && !quote.stock) {
    const dealerCustomer = quote.dealerCustomer;
    items.push({
      label: "Dealer",
      children: <div>
                  <div>
                    <div>{quote.owner?.dealerName}</div>
                    {!!dealerCustomer?.addressLine1 && <div>{dealerCustomer.addressLine1}</div>}
                    {!!dealerCustomer?.addressLine2 && <div>{dealerCustomer.addressLine2}</div>}
                    <div>{[[dealerCustomer?.city, dealerCustomer?.stateProvince].filter(v=>v).join(", "), dealerCustomer?.postalCode].filter(v=>v).join(" ")}</div>
                  </div>
                  <div>
                    {!!dealerCustomer?.contactName && <div>{[dealerCustomer.contactName, dealerCustomer.contactRole && ["(", dealerCustomer.contactRole, ")"].join(" ") ].filter(v=>v).join( " " )}</div>}
                    {!!dealerCustomer?.contactPhone && <div>{dealerCustomer.contactPhone}</div>}
                    {!!dealerCustomer?.contactEmail && <div>{dealerCustomer.contactEmail}</div>}
                  </div>
                </div>
    })
  }

  if( quote?.endCustomer && !quote.stock) {
    const customer = quote.endCustomer;
    items.push({
      label: "End Customer",
      children: <div>
              <div>
                <div>{customer?.name}</div>
                {!!customer?.addressLine1 && <div>{customer.addressLine1}</div>}
                {!!customer?.addressLine2 && <div>{customer.addressLine2}</div>}
                <div>{[[customer?.city, customer?.stateProvince].filter(v=>v).join(", "), customer?.postalCode].filter(v=>v).join(" ")}</div>
              </div>
              <div>
                {!!customer?.contactName && <div>{[customer.contactName, customer.contactRole && ["(", customer.contactRole, ")"].join(" ") ].filter(v=>v).join( " " )}</div>}
                {!!customer?.contactPhone && <div>{customer.contactPhone}</div>}
                {!!customer?.contactEmail && <div>{customer.contactEmail}</div>}
              </div>
            </div>
    })
  }

  if( quote?.shippingDestination  && !quote.stock) {
    items.push({
      label: "Destination",
      children: quote?.shippingDestination.name
    })
  }


  if( quote?.productionDate ) {
    items.push({
      label: "Production Date",
      children: dayjs(quote?.productionDate).format("MMMM Do YYYY")
    })
  }

  if( quote?.shippingDate && !quote.stock) {
    items.push({
      label: "Shipping Date",
      children: dayjs(quote?.shippingDate).format("MMMM Do YYYY")
    })
  }

  if( quote?.owner && !quote.stock) {
    items.push({
      span: 5,
      label: "Salesperson",
      children: [quote.owner.name, quote.owner.dealerName].filter(v=>v).join(" - ")
    })
  }
  if( !!quote?.salesTeam?.sales?.length) {
    items.push({
      span: 5,
      label: "BM Representative",
      children: quote?.salesTeam.sales.map(s => s.name).join(", ")
    })
  }
  if( quote?.notes?.trim().length) {
    items.push({
      label: "Notes",
      children:quote?.notes
    })
  }

  return <>
    <Spin spinning={quoteAsync?.isLoading()}>
      <div className={styles["details-tab-contents"]}>
        <Descriptions className={styles['quoteQuickView-description']}  column={3}
          items={items} 
          layout="vertical" size="small" colon={false} />
      </div>
    </Spin>
  </>

}

