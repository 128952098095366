import { Button, Card } from "antd";
import { AndExpression, RuleExpression } from "../../../api/models";
import RuleExpressionEditor from "../expression";
import { useState } from "react";
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';

export interface Props {
    expression: AndExpression;
    onUpdate: (AndExpression) => void;
}

const AndExpressionEditor = (props: Props) => {
    function onUpdate(idx: number, expr: RuleExpression) {
        const andExpr = new AndExpression();
        andExpr.expressions = [...props.expression.expressions.slice(0, idx), expr, ...props.expression.expressions.slice(idx + 1)];
        props.onUpdate(andExpr);
    }

    function onAdd() {
        const andExpr = new AndExpression();
        andExpr.expressions = [...props.expression.expressions, new RuleExpression()];
        props.onUpdate(andExpr);
    }

    function onRemove(idx: number) {
        const andExpr = new AndExpression();
        andExpr.expressions = [...props.expression.expressions.slice(0, idx), ...props.expression.expressions.slice(idx+1)];
        props.onUpdate(andExpr);
    }

    return (
        <div>
            <Button style={{margin: '5px'}} size="small" type="primary" icon={<PlusCircleOutlined />} onClick={onAdd}>Add to AND</Button>
            <Card>
                {props.expression.expressions.map((e, idx) =>
                (
                    <div key={idx}>

                        <Card>
                            <Button icon={<DeleteOutlined />} style={{marginBottom: '5px'}} onClick={() => {
                                onRemove(idx);
                            }}/>
                            <RuleExpressionEditor expression={e} onUpdate={(expr) => onUpdate(idx, expr)} />
                        </Card>
                        {idx != props.expression.expressions.length - 1 &&
                            <span><strong>AND</strong></span>}
                    </div>
                ))}
            </Card>
        </div>
    );
};

export default AndExpressionEditor;
