import { Table, Alert, Empty, Descriptions, Button, Tooltip, notification } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { Assembly, ItemWeightsWithUnits, Performance } from "../api/models";
import { AlignType } from 'rc-table/lib/interface';
import PerformanceErrorCategories from "./PerformanceErrorCategories";
import LabelTooptip from "./widgets/LabelTooptip";
import { DescriptionsItemType } from "antd/es/descriptions";
import { DownloadOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useQuoteContext } from "../contexts/QuoteContext";
import { ConfiguratorContext } from "../context";
import { useIntl } from "react-intl";

const bodyApplicationCategoryId = 131;

const ifShowUnitColumn = (_, index: number | undefined) => {
  if (index !== 3 && index !== 4) {
    return { colSpan: 0 };
  }
  return { colSpan: 1 };
};

const firstIntermediateWidth  = (row: {name: string}, index: number | undefined) => {
  if (index === 2) {
    return { colSpan: 2 };
  }
  return {colSpan: ((index === 3 || index === 4) ? 1 : 2)}
};

const lastDriveWidth  = (row: {name: string}, index: number | undefined) => {
  if (index === 2) {
    return { colSpan: 2 };
  }
  return {colSpan: ((index === 3 || index === 4) ? 1 : 2)}
};

const weightsColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    // rowScope: 'row',
    width: '15%',
  },
  {
    title: 'Front',
    dataIndex: 'front',
    colSpan: 2,
    align: "center" as AlignType,
    onCell: (_, index: number | undefined) => ({
      colSpan: (index === 3 || index === 4) ? 1 : 2
    })
  },

  {
    title: 'Unit',
    dataIndex: 'frontUnit',
    colSpan: 0,
    align: "center" as AlignType,
    onCell: ifShowUnitColumn,
  },

  {
    title: 'Pusher',
    dataIndex: 'pusher',
    colSpan: 2,
    align: "center" as AlignType,
    onCell: (_, index: number | undefined) => ({
      colSpan: (index === 3 || index === 4) ? 1 : 2
    })
  },

  {
    title: 'Unit',
    dataIndex: 'pusherUnit',
    colSpan: 0,
    align: "center" as AlignType,
    onCell: ifShowUnitColumn,
  },

  {
    title: 'First Drive',
    dataIndex: 'firstIntermediate',
    colSpan: 2,
    align: "center" as AlignType,
    onCell: firstIntermediateWidth
  },

  {
    title: 'Unit',
    dataIndex: 'firstIntermediateUnit',
    colSpan: 0,
    align: "center" as AlignType,
    onCell: ifShowUnitColumn,
  },

  {
    title: 'Last Drive',
    dataIndex: 'lastDrive',
    colSpan: 2,
    align: "center" as AlignType,
    onCell: lastDriveWidth
  },

  {
    title: 'Unit',
    dataIndex: 'lastDriveUnit',
    colSpan: 0,
    align: "center" as AlignType,
    onCell: ifShowUnitColumn,
  },

  {
    title: 'Tag',
    dataIndex: 'tag',
    colSpan: 2,
    align: "center" as AlignType,
    onCell: (_, index: number | undefined) => ({
      colSpan: (index === 3 || index === 4) ? 1 : 2
    })
  },

  {
    title: 'Unit',
    dataIndex: 'tagUnit',
    colSpan: 0,
    align: "center" as AlignType,
    onCell: ifShowUnitColumn,
  },
];

const PerformanceDetails = (props: { performance: Performance | undefined; }) => {

  const [weigthsData, setWeightsData] = useState<any>([]);
  const performance = props.performance;
  const performanceData = performance?.performanceData;
  const performanceWeight = performance?.performanceWeight;
  const performanceDimension = performance?.performanceDimension;
  const disclaimerStatement = "An Allison iSCAAN can be completed upon request to verify these values.";
  const fieldStyle = { backgroundColor: 'white', color: 'black', maxWidth: '200px' };
  const quoteContext = useQuoteContext();
  const quote = quoteContext.quoteAsync?.val;
  const [bodyApplication, setBodyApplication] = useState<Assembly>();

  const performanceRef = useRef(null);

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const bodyApplicationSelectionId = quote?.selections[bodyApplicationCategoryId]?.[0];

  useEffect(() => {
    getTableData();
  }, [performance]);

  useEffect(() => {
    if (bodyApplicationSelectionId) {
      getBodyApplication();
    }
  }, [bodyApplicationSelectionId]);

  const getBodyApplication = async () => {
    if (!bodyApplicationSelectionId) return;
    try {
      const resp = await configurator.api.getAssembly(bodyApplicationSelectionId);
      setBodyApplication(resp.data);
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      const msg = "Failed to get body application. " + errorMsg;
      notification.error( { message: msg });
    }
  }

  const addTextRowToPdf = (pdf: jsPDF, pdfWidth: number, y: number, lineHeight: number, x_margin: number, text: string, fontSize: number) => {

    pdf.setFontSize(fontSize);
    let textLines = pdf.splitTextToSize(text, pdfWidth);

    textLines.forEach(line => {
      pdf.text(line, x_margin, y);
      y += lineHeight;
    });

    y += lineHeight;
    return y;
  }

  const handleExportPdf = async () => {
    if (!performanceRef.current) return;
    const element = performanceRef.current;

    try {
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4'
      });
      const margin = 20;
      const lineHeight = 15;
      const pdfWidth = pdf.internal.pageSize.getWidth() - margin * 2;
      const pdfHeight = pdf.internal.pageSize.getHeight() - margin * 2;

      const imgWidth = pdfWidth;
      const imgHeight = (imgWidth * canvas.height) / canvas.width;

      // First row with larger font

      let y = margin + 40;

      y = addTextRowToPdf(pdf, pdfWidth, y, lineHeight, margin, `Performance`, 16);

      y = addTextRowToPdf(pdf, pdfWidth, y, lineHeight, margin, `Quote ID: ${quote?.quoteId}`, 12);

      y = addTextRowToPdf(pdf, pdfWidth, y, lineHeight, margin, `Truck Description: ${quote?.truckDescription}`, 12);

      y = addTextRowToPdf(pdf, pdfWidth, y, lineHeight, margin, `Body Application: ${bodyApplication?.bom} ${bodyApplication?.bomDescription}`, 12);

      const contentPositionY = y;

      if (imgHeight > pdfHeight - contentPositionY) {
        // Add multiple pages if it doesn't fit on one
        let heightLeft = imgHeight;
        let position = contentPositionY;

        pdf.addImage(data, 'PNG', margin, position, imgWidth, imgHeight);
        heightLeft -= pdfHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(data, 'PNG', margin, position, imgWidth, imgHeight);
          heightLeft -= pdfHeight;
        }
      } else {
        pdf.addImage(data, 'PNG', margin, contentPositionY, imgWidth, imgHeight);
      }

      pdf.save(`performance_${quote?.quoteId}.pdf`);
    }
    catch (error: any) {
      console.error('Failed to generate PDF', error);
    }
  };

  const getName = (value: string) => {
    if (value === 'tare') {
      return 'Weight (+/- 5%, lbs.)';
    }
    else if (value === 'gawr') {
      return value.toUpperCase() + ' (lbs.)';
    }
    else if (value === 'wheels' || value === 'tires') {
      return value[0].charAt(0).toUpperCase() + value.slice(1).toLowerCase() + ' (lbs.) / qty';
    }
    else {
      return value[0].charAt(0).toUpperCase() + value.slice(1).toLowerCase() + ' (lbs.)';
    }
  };

  const getRowWithUnit = (key: string, item: ItemWeightsWithUnits) => {
    let result = { name: getName(key) };
    if (performanceWeight) {
      Object.keys(item).forEach((inKey: string) => {
        result = { ...result, [inKey]: item[inKey]['weight'], [inKey + 'Unit']: item[inKey]['unit'] };
      });
    }
    return result;
  };

  const getTableData = () => {
    const data: any = [];
    if (performanceWeight) {
      Object.keys(performanceWeight).forEach(key => {
        if (typeof performanceWeight[key] !== 'string') {
          if (key === 'weightsMissing' || key === 'tareMissing' || key === 'gvwrMissing') {
          }
          else if (key !== 'tires' && key !== 'wheels') {
            data.push({ ...performanceWeight[key], name: getName(key) });
          }
          else {
            data.push(getRowWithUnit(key, performanceWeight[key]));
          }
        }
      });
    }
    setWeightsData(data);
    return data;
  };

  const getGVWRDescription = (): DescriptionsItemType[] => {

    const weightStyle = {marginBottom: "1rem", minWidth: "200px"};
    
    let items = 
    [
      {
        key: "chassisGVWR",
        label: "Chassis GVWR (lbs.)",
        span: 11,
        labelStyle: weightStyle,
        children: 
        <span>{performanceWeight?.chassisGVWR}</span>
      },
      {
        key: "chassisWeight",
        label: "Chassis Weight (lbs.)",
        span: 11,
        labelStyle: weightStyle,
        children: 
        <span>{performanceWeight?.chassisWeight}</span>
      },
      {
        key: "engineGVWR",
        label: "Engine GVWR (lbs.)",
        span: 11,
        labelStyle: weightStyle,
        children: 
        <span>{performanceWeight?.engineGVWR}</span>
      },
      {
        key: "truckGVWR",
        label: "Truck GVWR (lbs.)",
        span: 11,
        labelStyle: weightStyle,
        children: 
        <span>{performanceWeight?.truckGVWR}</span>
      },
      {
        key: "transmissionGVWR",
        label: "Transmission GVWR (lbs.)",
        span: 11,
        labelStyle: weightStyle,
        children: 
        <span>{performanceWeight?.transmissionGVWR}</span>
      },
      {
        key: "chassisGVWR",
        label: "Avail GVWR (lbs.)",
        span: 11,
        labelStyle: weightStyle,
        children: 
        <span>{performanceWeight?.availGVWR}</span>
      },
    ];
    return items;
  }

  const getPerformanceDescription = (): DescriptionsItemType[] => {

    const performanceStyle = {marginBottom: "1rem", minWidth: "200px"};

    const gradeabilityAlertContent = "The gradeability criteria of 18% is not being met - this can be solved with a higher torque engine, lower GVWR cap, or slower axle ratio. Contact the VFD team to review and determine a solution.";
    const gradeabilityInfoContent = "Gradeability is the steepest gradient a truck can climb and still maintain speed. Generally this is conveyed as a percent, where 100% is 45° grade. The passing criteria is 18% (or 8° grade).";
    const wheelslipAlertContent = "The wheelslip minimum axle ratio requirement is not being met. Either select a slower axle ratio or contact the VFD team to review and determine a solution.";
    const wheelslipInfoContent = "This is the minimum axle ratio required to ensure the wheels keep turning when the vehicle stops in mud or soft soil, thus preventing operation at full throttle converter stall.";
    const accelerationRateAlertContent = "The acceleration rate criteria of 6.0 is not being met - this is generally caused by low GVW, slow axle ratio, and a high torque engine. Contact the VFD team to review and determine a solution.";
    const accelerationRateInfoContent = "This calc is only used with the 4500 series transmission to identify shift stacking, where shift points are too close together and can lead to poor performance. The passing criteria for this is 6.";
    const startabilityAlertContent = "The Startability criteria of 25% is not being met - this can be solved with a higher torque engine, lower GVWR cap, or slower axle ratio. Contact the VFD team to review and determine a solution.";
    const startabilityInfoContent = "Startability is the steepest gradient a truck can start without stalling. Generally this is conveyed as a percent, where 100% is 45° grade. The passing criteria is 25% (or 11.25° grade).";
    const gearedSpeedAlertContent = "";
    const gearedSpeedInfoContent = "This is the theoretical speed based on drivetrain and tire values, the vehicle will still be subject to the electronic speed limit listed in the Engine Programming Parameters category."

    let items = 
    [
      {
        key: "gradeability",
        label: <LabelTooptip
          title="Gradeability"
          formItemName="gradeability"
          showAlertIcon={performanceData?.gradeabilityAlert}
          showInfoIcon={true}
          inputFieldsStyle={fieldStyle}
          alertContent={gradeabilityAlertContent}
          infoContent={gradeabilityInfoContent}                
        />,
        span: 11,
        labelStyle: performanceStyle,
        children: 
        <span>{performanceData?.gradeability}</span>
      },
      {
        key: "startability",
        label: <LabelTooptip
          title="Startability"
          formItemName="startability"
          showAlertIcon={performanceData?.startabilityAlert}
          showInfoIcon={true}
          inputFieldsStyle={fieldStyle}
          alertContent={startabilityAlertContent}
          infoContent={startabilityInfoContent}                
        />,
        span: 11,
        labelStyle: performanceStyle,
        children: 
        <span>{performanceData?.startability}</span>
      },
      {
        key: "wheelslip",
        label: <LabelTooptip
          title="Wheelslip"
          formItemName="wheelslip"
          showAlertIcon={performanceData?.wheelslipAlert}
          showInfoIcon={true}
          inputFieldsStyle={fieldStyle}
          alertContent={wheelslipAlertContent}
          infoContent={wheelslipInfoContent}                
        />,
        span: 11,
        labelStyle: performanceStyle,
        children: 
        <span>{performanceData?.wheelslip}</span>
      },
      {
        key: "gearedSpeed",
        label: <LabelTooptip
          title="Geared Speed (mph)"
          formItemName="gearedSpeed"
          showAlertIcon={performanceData?.gearedSpeedAlert}
          showInfoIcon={true}
          inputFieldsStyle={fieldStyle}
          alertContent={gearedSpeedAlertContent}
          infoContent={gearedSpeedInfoContent}                
        />,
        span: 11,
        labelStyle: performanceStyle,
        children: 
        <span>{performanceData?.gearedSpeed}</span>
      },
      {
        key: "accelerationRate",
        label: <LabelTooptip
          title="Acceleration Rate"
          formItemName="accelerationRate"
          showAlertIcon={performanceData?.accelerationRateAlert}
          showInfoIcon={true}
          inputFieldsStyle={fieldStyle}
          alertContent={accelerationRateAlertContent}
          infoContent={accelerationRateInfoContent}                
        />,
        span: 11,
        labelStyle: performanceStyle,
        children: 
        <span>{performanceData?.accelerationRate}</span>
      },
    ];
    return items;
  }

  const getDimensionDescription = (): DescriptionsItemType[] => {

    const dimensionStyle = {marginBottom: "1.5rem", marginLeft: "2rem", minWidth: "250px", maxWidth: "250px", };

    let items = 
    [
      {
        key: "wheelbase-dimension",
        label: "Wheelbase(WB) (inch)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.wheelbase}</span>
      },
      {
        key: "frameSillLength",
        label: "Frame Sill Length (ft)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.frameSillLength}</span>
      },
      {
        key: "afterFrame",
        label: "Afterframe(AF) (inch)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.afterFrame}</span>
      },
      {
        key: "cabToAxle",
        label: "Cab to Axle(CA) (inch)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.cabToAxle}</span>
      },
      {
        key: "pusherTagSpacing",
        label: "Pusher / Tag Spacing (inch)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.pusherTagSpacing}</span>
      },
      {
        key: "bumperToFrontAxle",
        label: "Bumper to Front Axle(BA) (inch)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.bumperToFrontAxle}</span>
      },
      {
        key: "overallLength",
        label: "Overall Length (inch)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.overallLength}</span>
      },
      {
        key: "cabWidth",
        label: "Cab Width (inch)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.cabWidth}</span>
      },
      {
        key: "mirrorWidt",
        label: "Mirror Width (inch)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.mirrorWidth}</span>
      },
      {
        key: "frameHeightRearLaden",
        label: "Frame Height @ Rear suspension (laden) (inch)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.frameHeightRearLaden}</span>
      },
      {
        key: "frameHeightRearUnladen",
        label: "Frame Height @ Rear suspension (unladen) (inch)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.frameHeightRearUnladen}</span>
      },
      {
        key: "frameSlope",
        label: "Frame Slope",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.frameSlope}</span>
      },
      {
        key: "centerlineToTireCenterline",
        label: "SAE - Tire Centerline To Tire Centerline (ft)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.centerlineToTireCenterline}</span>
      },
      {
        key: "curbToCurb",
        label: "Curb To Curb (ft)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.curbToCurb}</span>
      },
      {
        key: "wallToWall",
        label: "Wall To Wall (ft)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.wallToWall}</span>
      },
      {
        key: "cgPusher",
        label: "CGPUSHER (inch)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.cgPusher}</span>
      },
      {
        key: "cgTag",
        label: "CGTAG (inch)",
        span: 22,
        labelStyle: dimensionStyle,
        children: 
        <span>{performanceDimension?.cgTag}</span>
      },
    ];
    return items;
  }

  return (
    <>
    <style>
      {`
        .bordered-div {
          border: 3px solid #1677ff;
          border-radius: 10px;
          position: relative;
          padding: 25px 15px;
          margin-bottom: 20px;
          margin-top: 15px;
        }

        .bordered-div::before {
          content: attr(section-title);
          position: absolute; 
          top: -19px;
          left: 12px;
          background-color: white;
          padding: 0 5px;
          font-weight: bold;
          font-size: 20px;
          color: #1677ff;
        }

        .grid-container {
          display: flex;
          gap: 30px;
        }
        
        .left-panel, .right-panel {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
        
        .left-panel > .bordered-div,
        .right-panel > .bordered-div {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
        
        .right-panel div {
          height: 100%;
        }
      `}
    </style>

  <div>
    <PerformanceErrorCategories performance={performance} />
    <Tooltip title="Export Performance"><Button type="primary" onClick={handleExportPdf} style={{marginBottom: "1rem"}} icon={<DownloadOutlined />} shape="circle"/></Tooltip>
    <div ref={performanceRef} key="performance-export-section">
      <div className="grid-container">
        <div className="left-panel">
          <div className="bordered-div" section-title="Weight">
            <Table
              style={{marginBottom: '4rem'}}
              rowKey="name"
              columns={weightsColumns}
              dataSource={weigthsData}
              bordered
              pagination={false}
              locale={{emptyText: 
                <div style={{height: "30rem", display: "flex", justifyContent: "center"}}>
                  <Empty style={{position: "absolute", top: "30%", scale: "1.5"}} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
              }}
            >
            </Table>
            <Descriptions
              layout="horizontal"
              column={22}
              items={getGVWRDescription()}
              labelStyle={{color: "black"}}
              style={{marginLeft: "1rem"}}
            />
          </div>

          <div className="bordered-div" section-title="Performance">
            <Descriptions
              layout="horizontal"
              column={22}
              items={getPerformanceDescription()}
              labelStyle={{color: "black"}}
              style={{marginLeft: "1rem"}}
            />
          </div>
        </div>

        <div className="right-panel">

          <div className="bordered-div" section-title="Dimensions">
            <Descriptions
              layout="horizontal"
              column={22}
              items={getDimensionDescription()}
              labelStyle={{color: "black"}}
              style={{marginLeft: "1rem", marginTop: "1rem"}}
            />
          </div>
        </div>
      </div>
      {performance?.modelType === 'ICE' && <Alert type="warning" message={disclaimerStatement} />}
    </div>
  </div>
  </>);
};

export default PerformanceDetails;
