import { useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../context";
import { Select } from "antd";
import { useIntl } from "react-intl";

export interface Props {
  onSelectQuoteStatus?: (status: string) => void;
  //selectedStatus?: string;
  value?: string;
  onChange?: (statuses: string) => void;
  style?: any;
  inludingCancelledOrder?: boolean;
}

const QuoteStatusSelector = (props: Props) => {
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [quoteStatuses, setQuoteStatuses] = useState<string[] | undefined>(
    props.value ? [props.value] : undefined
  );

  useEffect(() => {
    load();
  }, [props.inludingCancelledOrder]);

  async function load() {
    const resp = await configurator.api.listStatuses(props.inludingCancelledOrder || false);
    setQuoteStatuses(resp?.data.sort((a,b) => {
        return intl.formatMessage({id: 'status.' + a, defaultMessage: a})
            .localeCompare(intl.formatMessage({id: 'status.' + b, defaultMessage: b}));
    }));
  }

  return (
    <Select
      onChange={props.onChange}
      defaultValue={props.value}
      allowClear
      showSearch={true}
      style={props.style}
      optionFilterProp="label"
      mode="multiple"
      options={ quoteStatuses?.map(status => ({
        value: status, 
        label: intl.formatMessage({id: 'status.' + status, defaultMessage: status})
      }))}
    />
  );
};

export default QuoteStatusSelector;
