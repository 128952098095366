import Title from "antd/lib/typography/Title";
import { AssemblyBase, QuoteReview } from "../api/models";
import Utils from "../util/util";

const QuoteReviewDetail = (props:{ 
  review:QuoteReview | undefined 
  ignoreDashDrawing?: boolean
}) => {

  const { review } = props;


  const perferrs = Utils.getPerformanceErrors(review?.performance);
  const getAssemblyLabel = (asm:AssemblyBase) =>  <span style={{fontSize: ".8rem"}}><span style={{fontWeight: 600}}>{asm.bom}</span> - <span>{asm.bomDescription}</span></span>

  return <>
    {!!review?.customOptions.length && <>
      <Title level={5}>The following custom options need review:</Title>
      <ul>
        {review.customOptions
          .sort( (a,b) => a.content?.localeCompare(b.content || "") || 0 )
          .map( co => `${co.content} - ${co.approved === undefined ? "Pending" : co.approved ? "Approved" : "Rejected"}`)
          .map( (v,ndx) => <li style={{marginBottom: ".4rem"}} key={`co-${ndx}`}>{v}</li>)}
      </ul>
    </>}

    {!!review?.obsoleteAssemblyLst.length && <>
      <Title level={5}>The following selections are obsolete:</Title>
      <ul>
        {review.obsoleteAssemblyLst
          .sort( (a,b) => a.bom.localeCompare(b.bom) )
          .map( getAssemblyLabel )
          .map( (v,ndx) => <li style={{marginBottom: ".4rem"}}  key={`asm-${ndx}`}>{v}</li>)}
      </ul>
    </>}

    {!!review?.invalidAssemblyLst.length && <>
      <Title level={5}>The following selections are incompatible:</Title>
      <ul>
        {review.invalidAssemblyLst
          .sort( (a,b) => a.bom.localeCompare(b.bom) )
          .map( getAssemblyLabel )
          .map( (v,ndx) => <li style={{marginBottom: ".4rem"}}  key={`asm-${ndx}`}>{v}</li>)}
      </ul>
    </>}

    {!!review?.missingCategoryLst.length && <>
      <Title level={5}>The following categories are missing selections:</Title>
      <ul>
        {review?.missingCategoryLst
          .map( cat => `${cat?.configuratorSection}: ${Utils.stripSortingPrefix(cat?.name)}` )
          .sort( (a,b) => a.localeCompare(b) )
          .map( (v,ndx) => <li style={{marginBottom: ".4rem"}}  key={`missingcat-${ndx}`}>{v}</li>)}
      </ul>
    </>}

    {!!perferrs?.length && <>
      <Title level={5}>There are performance errors:</Title>
      <ul>
        {perferrs?.map( (v,ndx) => <li style={{marginBottom: ".4rem"}}  key={`perferr-${ndx}`}>{v}</li> )}
      </ul>
    </>}

    {(!props.ignoreDashDrawing && !review?.dashImageUrl?.length) && <>
      <Title level={5}>The dash drawing is missing.</Title>
    </>}
  </>
}

export default QuoteReviewDetail;

