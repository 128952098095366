import { Checkbox, Col, Divider, Row, Space, Spin, Table } from "antd";
import { ChangeOrderDiff, DealerAdjustment, NonDiscountOption, NewOrOld, RevisionType, MajorTableExcludedDiff, ApprovalDiff, QuoteDiffItem, AssemblyInfo, Truck } from "../../api/models";
import { useState } from "react";
import _ from "lodash";
import {ColumnType} from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import Utils from "../../util/util";
import { useIntl } from "react-intl";


interface TableRow {
  changeItem: string;
  before: string;
  after: string;
  beforePrice?: number | undefined;
  afterPrice?: number | undefined;
  priceChange?: string;
  key: string;
}

interface TableRowForOption {
  removed: AssemblyInfo[] | undefined;
  added: AssemblyInfo[] | undefined;
  categoryName: string;
}

const PropertiesDiff = (props:{
  diff: ApprovalDiff; 
  hidePriceChange?: boolean;
  columnTitles?: string[]
  type?: string
}) => {


  const nonDiscountOptionsEmpty = !( ( props.diff.nonDiscountOptionsDiff?.newNonDiscountOptions || [] ).concat(
    props.diff.nonDiscountOptionsDiff?.oldNonDiscountOptions || [] ).length );

  const dealerAdjustmentsEmpty = !( ( props.diff.dealerAdjustmentsDiff?.newDealerAdjustments || [] ).concat(
    props.diff.dealerAdjustmentsDiff?.oldDealerAdjustments || [] ).length );

  const majorTableNotIncludedDiff = Object.values(MajorTableExcludedDiff).map(key => String(key));
  const includesKeys = Object.keys(props.diff).some(key => !majorTableNotIncludedDiff.includes(key) ) 
  const showTable = includesKeys || !nonDiscountOptionsEmpty || !dealerAdjustmentsEmpty;
  const intl = useIntl();
  const columnTitles = (props.columnTitles?.length || 0 ) > 1 
    ? props.columnTitles 
    : [ "Before", "After" ];

  if (!showTable ) return <></>;

  const columns:ColumnType<TableRow>[] = [
    {
      title: "Change Item",
      dataIndex: 'changeItem',
      key: "changeItem",
      width: '20%',
    },
    {
      title: columnTitles?.[0],
      dataIndex: 'before',
      key: "before",
      width: '30%',
      render: (_, row, _i) => 
        getArrayRender(row, NewOrOld.OLD, props.diff, props.hidePriceChange)
    },
    {
      title: columnTitles?.[1],
      dataIndex: 'after',
      key: "after",
      width: '30%',
      render: (_, row, _i) => 
        getArrayRender(row, NewOrOld.NEW, props.diff, props.hidePriceChange)
    },
  ];

  if (!(props.hidePriceChange ?? false)) {
    columns.push(
      {
        title: "Price Change",
        dataIndex: 'priceChange',
        key: "priceChange",
        width: '20%',
        render: (priceChange, _row, _i) => props.hidePriceChange ? "" : priceChange,
      },
    );
  }

  const getItemName = (str: string) : string => {
    const ret = str.replace(/([A-Z])/g, " $1").replace('Diff', '').trim();
    return ret.charAt(0).toUpperCase() + ret.slice(1);
  }

  const getFormatedDate = (date: string) => {
    return date === "NA" ? date : intl.formatDate(date);
  }

  const getDataSource = () : TableRow[] => {

    let dataSource: TableRow[] = [];
    Object.values(ChangeOrderDiff)
    .filter( p => p !== 'assembliesDiff' )
    .filter( p => p !== 'truckDiff' )
    .filter( p => p in props.diff )
    .forEach( p => {
      let row: TableRow = {changeItem: getItemName(p), before: 'NA', after: 'NA', key: p};
      if (p !== 'nonDiscountOptionsDiff' && p !== 'dealerAdjustmentsDiff') {
        const diffVal = props.diff[ p ] as QuoteDiffItem;

        const priceChangeDiff = Number(diffVal.afterPrice) - Number(diffVal.beforePrice);
        const priceChange = Utils.formatMoneyWithSign(priceChangeDiff);

        row = {...row, 
          // Do not show the hh:mm:ss in production/shipping date
          before : (p === 'productionDateDiff' || p === 'shippingDateDiff') ? getFormatedDate(diffVal.before.split(' ')[0]) : diffVal.before,
          after : (p === 'productionDateDiff' || p === 'shippingDateDiff') ? getFormatedDate(diffVal.after.split(' ')[0]) : diffVal.after,
          beforePrice: diffVal.beforePrice,
          afterPrice: diffVal.afterPrice,
          priceChange,
        };
      }
      else {
        const priceChangeDiff = Number(props.diff[ p ]?.newPrice) - Number(props.diff[ p ]?.oldPrice);
        const priceChange = Utils.formatMoneyWithSign(priceChangeDiff);
        row = {
          ...row,
          priceChange,
        };
      }
      if ( !(p === 'nonDiscountOptionsDiff' && nonDiscountOptionsEmpty) && !(p === 'dealerAdjustmentsDiff' && dealerAdjustmentsEmpty) ) {
        dataSource.push(row);
      }  
    });

    // Doesn't need to show these items when it's SPLIT_ORDER
    if (RevisionType.SPLIT_ORDER === props.diff?.revisionType) {
      dataSource = dataSource.filter(row => {return row.changeItem !== 'Non Discount Options' && row.changeItem !== 'Dealer Adjustments'})
    }

    return dataSource;
  }

  const quoteChangeDataSource = getDataSource();

  return <div style={{marginBottom: "1rem"}}>
    <Title level={5}>Quote Change</Title>
    <Table
      size="small"
      columns={columns}
      dataSource={quoteChangeDataSource}
      pagination={false}
    />
  </div>
}

const OptionsDiff = (props: {
  addedAssemblies?: AssemblyInfo[]
  removedAssemblies?: AssemblyInfo[]
  hidePriceChange?: boolean
  columnTitles?: string[]
}) => {
  const { addedAssemblies, removedAssemblies } = props;

  const [showCustomBom, setShowCustomBom] = useState<boolean>(true);

  const columnTitles = (props.columnTitles?.length || 0 ) > 1 
    ? props.columnTitles 
    : [ "Removed", "Added" ];

  const isCustomBom = (a:AssemblyInfo | undefined) => a?.bom.startsWith("9999");
  const filterCustomBom = (a:AssemblyInfo) => !showCustomBom || !isCustomBom(a);

  const allAssemblies = ( addedAssemblies || [] ).concat( removedAssemblies || []);
  const hasCustomBom = allAssemblies.some( isCustomBom );

  const addedBySection = _.groupBy(addedAssemblies?.filter( filterCustomBom ), 'configuratorSection');
  const removedBySection = _.groupBy(removedAssemblies?.filter( filterCustomBom ), 'configuratorSection');
  //order sections by catgoryName....
  const sectionNames = Object.keys( _.groupBy( allAssemblies.filter( filterCustomBom ).sort( (a,b) => a.categoryName.toUpperCase().localeCompare( b.categoryName.toUpperCase() ) ), 'configuratorSection' ) );

  if ( allAssemblies.length === 0 ) return <></>;


  const columnsForOptions:ColumnType<TableRowForOption>[] = [
    {
      title: columnTitles?.[0],
      dataIndex: 'removed',
      key: "removed",
      width: 500,
      onCell: (_record) => ({ 
        style: { verticalAlign: "top" }
      }),
      render: (d) => <ListOptions assemblies={d} added={false} filterCustomBom={filterCustomBom} hidePriceChange={props.hidePriceChange} />

    },
    {
      title: columnTitles?.[1],
      dataIndex: 'added',
      key: "added",
      width: 500,
      onCell: (_record) => ({ 
        style: { verticalAlign: "top" }
      }),
      render: (d) => <ListOptions assemblies={d} added={true} filterCustomBom={filterCustomBom} hidePriceChange={props.hidePriceChange} />
    },
  ];

  if (!props.hidePriceChange) {
    columnsForOptions.push( {
        title: "Price Change",
        key: "priceChange",
        width: '20%',
        render: (d) => {
          const removedPrice = d?.removed?.[0]?.optionDealerPrice || 0;
          const addedPrice = d?.added?.[0]?.optionDealerPrice || 0;

          const priceChangeDiff = addedPrice - removedPrice;
          const priceChange = Utils.formatMoneyWithSign(priceChangeDiff);

          return props.hidePriceChange ? "" : priceChange
        }
      });
  }

  const getData = (section:string) => {
    const addedByCategory =  _.groupBy( addedBySection[ section ], 'categoryName' );
    const removedByCategory = _.groupBy( removedBySection[ section ], 'categoryName' );
    const categorySet = Array.from( new Set( Object.keys( addedByCategory ).concat( Object.keys( removedByCategory ) ) ) )
      .sort( (a,b) => a.toUpperCase().localeCompare( b.toUpperCase() ) );

    return categorySet.map( c => ({
      categoryName: c,
      added: addedByCategory[c],
      removed: removedByCategory[c]
    }));
  };


  return <>
    <Title level={5}>Assemblies Change</Title>
    {hasCustomBom && <>
      <Checkbox onClick={() => setShowCustomBom((prevState) => { return !prevState; })}>Show Configurator-only BOM</Checkbox>
      <Divider style={{margin: "1rem 0 1rem 0"}}  />
    </>
    }
    {sectionNames.map( section => <div style={{marginBottom: "1.5rem"}} key={"section-" + section + "-category"}>
      <div style={{fontWeight: "500"}} key={"section-" + section + "-name"}>{section}</div>
      <Table
        size="small"
        columns={columnsForOptions}
        dataSource={getData(section)}
        pagination={false}
        rowKey={"categoryName"}
      />
    </div>)}
  </>;

}


const TruckDiff = (props: {
  truckDiff?: {oldTruck: Truck[], newTruck: Truck[]}
  columnTitles?: string[]
}) => {

  const showTruckDiff = !!props.truckDiff?.oldTruck.length || !!props.truckDiff?.newTruck.length;

  let TableRow = {changeItem: "Truck Notes", before: 'NA', after: 'NA', key: "truck-note"};
  let dataSource: TableRow[] = [TableRow];

  const columnTitles = (props.columnTitles?.length || 0 ) > 1 
    ? props.columnTitles 
    : [ "before", "after" ];

  let columns:ColumnType<TableRow>[] = [
    {
      title: "Change Item",
      dataIndex: 'changeItem',
      key: "changeItem",
      width: '20%',
    },
    {
      title: columnTitles?.[0],
      dataIndex: 'before',
      key: "before",
      width: '30%',
      render: () => 
        props.truckDiff?.oldTruck.map(t => <div key={`before-note-${t.truckSerialNumberStr}`} style={{marginBottom: "5px"}}>
          <div key={`before-serial-${t.truckSerialNumberStr}`}>{"Serial: " + t.truckSerialNumberStr}</div>
          <div key={`before-note-${t.truckSerialNumberStr}`}>{"Notes: " + t.notes}</div>
        </div>)
    },
    {
      title: columnTitles?.[1],
      dataIndex: 'after',
      key: "after",
      width: '30%',
      render: () => 
        props.truckDiff?.newTruck.map(t => <div key={`after-note-${t.truckSerialNumberStr}`} style={{marginBottom: "5px"}}>
          <div key={`after-serial-${t.truckSerialNumberStr}`}>{"Serial: " + t.truckSerialNumberStr}</div>
          <div key={`after-note-${t.truckSerialNumberStr}`}>{"Notes: " + t.notes}</div>
        </div>)
    },
  ];

  return <>
  {showTruckDiff && <div style={{marginBottom: "1rem"}}>
    <Title level={5}>Truck Change</Title>
    <Table
      size="small"
      columns={columns}
      dataSource={dataSource}
      pagination={false}
    />
  </div>}
  </>
}

const ApprovalDiffTable = (props: {
  diff: ApprovalDiff | undefined
  hidePriceChange?: boolean;
  loading?:boolean
  columnTitles?: string[]
}) => {

  if ( !props.diff ) return <></>;

  
  return <>
    <Spin spinning={!!props.loading}>
      <Space direction="vertical" size={"large"} style={{width: "100%"}}>
        <PropertiesDiff diff={props.diff} hidePriceChange={props.hidePriceChange} columnTitles={props.columnTitles} />
        <OptionsDiff hidePriceChange={props.hidePriceChange} {...props.diff.assembliesDiff} columnTitles={props.columnTitles} />
        <TruckDiff truckDiff={props.diff.truckDiff} columnTitles={props.columnTitles}  />
      </Space>
    </Spin>
  </>
}

export default ApprovalDiffTable;


const getArrayRender = (row: TableRow, sectionName: string, diff: ApprovalDiff, hidePriceChange: boolean | undefined ) => {

  const getItemPriceChange = (price: number | undefined) => {
    if (price == undefined || price === 0) return "";
    if (hidePriceChange) return "";

    const priceStr = Utils.formatMoney(price);
    return  `  ${priceStr}`
  }

  const normalizeName = (str: string) : string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const getItemContent = (sectionName:string, row:TableRow) => {
    return sectionName === NewOrOld.OLD ? 
    (row.before ?? "") + getItemPriceChange(row.beforePrice) 
    : 
    (row.after ?? "") + getItemPriceChange(row.afterPrice)
  }

  const str = row.key.replace('Diff', '');
  const section =  sectionName + str.charAt(0).toUpperCase() + str.slice(1);
  if (row.changeItem === 'Non Discount Options' && diff[row.key][section].length > 0) {
    return <>
      { diff[row.key][section].map((item: NonDiscountOption, i: number) =>
      <div key={row.key + '_section_' + item.id}>
        {
          Object.keys(diff[row.key][section][i])
          .filter(key => key.toUpperCase() !== 'ID')
          .map( key => {
            if (key.toUpperCase() !== "VALUE") {
              return <div key={row.key + `_${key}_` + item[key]} style={{maxWidth: "20rem"}}>{normalizeName(key)}: {item[key] || ''}</div>
            }
            else {
              return <div key={row.key + `_${key}_` + item[key]}>{normalizeName(key)}: {Utils.formatMoney(item[key]) || ''}</div>
            }
          })
        }
        <br/>
      </div>)
      }
    </>
  }
  else if (row.changeItem === 'Dealer Adjustments' && diff[row.key][section].length > 0) {
    return <>
      { diff[row.key][section].map((item: DealerAdjustment, i: number) =>
      <div key={row.key + '_section_' + item.id}>
        {
          Object.keys(diff[row.key][section][i])
          .filter(key => key.toUpperCase() !== 'ID')
          .map( key => {
            if (key.toUpperCase() !== "VALUE") {
              return <div key={row.key + `_${key}_` + item[key]} style={{maxWidth: "20rem"}}>{normalizeName(key)}: {item[key] || ''}</div>
            }
            else {
              return <div key={row.key + `_${key}_` + item[key]}>{normalizeName(key)}: {Utils.formatMoney(item[key]) || ''}</div>
            }
          })
        }
        <br/>
      </div>)
      }
    </>
  }
  else {
    return <>{getItemContent(sectionName, row)}</>
  }
}

const ListOptions = (props:{
  assemblies: AssemblyInfo[] | undefined
  added: boolean
  filterCustomBom: (a: AssemblyInfo) => boolean
  hidePriceChange?: boolean
  type?: string
}) => {

  const lst = props.assemblies?.filter(props.filterCustomBom);

  const getDealerPriceChange = (value: number | undefined) => {
    return Utils.formatMoney(value);
  }
  const getPriceWarning = (asm: AssemblyInfo): string => {
    if (!asm.bom.startsWith("9999") && !asm.optionDealerPrice) {
      return "Non-custom selection with zero price"
    }
    return "";
  }

  const asmLbl = (a:AssemblyInfo) => (!!a.label?.length ? a.label : a.bomDescription);

  return <ul style={{marginBottom: "0.1rem"}}>
    {lst?.map(a => <li key={'option-' + a.bom}>
      <div style={{fontWeight: "600"}} key={`option - ${a.bom} - ${a.categoryName}`}>
        {Utils.stripSortingPrefix(a.categoryName)}: {a.bom}
      </div>
      <div style={{wordBreak: "break-word"}} key={`option - ${a.bom} - ${asmLbl(a)}`}>{asmLbl(a)}</div>
      {!(props.hidePriceChange ?? false) && 
        <Row key={`option-${a.bom}-msrp-info`} gutter={12}>
          <Col key={`option-${a.bom}-msrp`}>{getDealerPriceChange(a.optionDealerPrice)}</Col>
          <Col key={`option-${a.bom}-msrp-alert`}><span style={{color: "red"}}>{getPriceWarning(a)}</span></Col>
        </Row>
      }
      </li>)}
  </ul>;
}
