import { NotExpression, RuleExpression } from "../../../api/models";
import RuleExpressionEditor from "../expression";

export interface Props {
    expression: NotExpression;
    onUpdate: (NotExpression) => void;
}

const NotExpressionEditor = (props: Props) => {
    return (
        <div>
            <strong>NOT</strong><br/> <RuleExpressionEditor expression={props.expression.expression} onUpdate={(expr) => {
                var newExpr = new NotExpression();
                newExpr.expression = expr;
                props.onUpdate(newExpr);
            }} />
        </div>
    );
};

export default NotExpressionEditor;
