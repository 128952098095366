import { useContext, } from "react";
import { AndExpression, Category, CategoryHasSelectionExpression, EqualsExpression, GlobalExpression, GtExpression, GteExpression, LhsRhsExpression, LtExpression, LteExpression, NotEqualsExpression, NotExpression, OrExpression, RuleExpression, SelectionHasMetadataOptionExpression, ExistingSelectionMatchesExpression } from "../../api/models";
import AndExpressionEditor from "./Expressions/and_expression";
import EqualsExpressionEditor from "./Expressions/equals_expression";
import LhsRhsExpressionEditor from "./Expressions/lhs_rhs_expression";
import NotExpressionEditor from "./Expressions/not_expression";
import OrExpressionEditor from "./Expressions/or_expression";
import CategorySelector2 from "../category_selector2";
import MetadataSelectOptionEditor, { MetadataSelectOptionInfo } from "./Values/metadata_select_option";
import { Card, Select } from "antd";
import GlobalExpressionSelector from "./Expressions/global_expression_selector";
import RuleValueEditor from "./Values/rule_value";
import RulesContext from "./context";
import ExistingSelectionMatchesEditor from "./Expressions/existing_selection_matches_expression";

export interface Props {
    expression: RuleExpression | undefined
    onUpdate?: (arg0: RuleExpression) => void;
    disableExpressionTypeChange?: boolean;
    disabledExpressionTypes?: string[];
    readOnly?: boolean;
}

const RuleExpressionEditor = (props: Props) => {
    const rulesConext = useContext(RulesContext);
    const expression = props.expression;
    const onUpdateFn = props.onUpdate || ((_e: RuleExpression) => { });

    function onUpdate(expression: RuleExpression) {
        onUpdateFn(expression);
    }

    function getExpressionReadOnly() {
        if (expression instanceof AndExpression) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {expression.expressions.map((expr, idx) => (
                        <div key={idx} style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                            <Card>
                                <RuleExpressionEditor key={idx} expression={expr} readOnly={props.readOnly} />
                            </Card>
                            {(idx < expression.expressions.length - 1) && <strong>AND</strong>}
                        </div>))}
                </div>
            );
        }
        else if (expression instanceof OrExpression) {
            return expression.expressions.map((expr, idx) => (
                <div key={idx} style={{ display: 'inline' }}>
                    <Card>
                        <RuleExpressionEditor key={idx} expression={expr} readOnly={props.readOnly} />
                    </Card>
                    {(idx < expression.expressions.length - 1) && <div><strong>OR</strong></div>}
                </div>
            ));
        }
        else if (expression instanceof LhsRhsExpression) {
            const expr = expressionTypes.find(et => et[0] == expression.type);
            return (<>
                <RuleValueEditor value={expression.lhs} readOnly={props.readOnly} />
                &nbsp;<em>{expr ? expr[1] : expression.type}</em>&nbsp;to&nbsp;
                <RuleValueEditor value={expression.rhs} readOnly={props.readOnly} />
            </>);
        }
        else if (props.expression instanceof SelectionHasMetadataOptionExpression) {
            const categoryId = props.expression.categoryId;
            const category = rulesConext.categories.find(cat => cat.categoryId == categoryId)?.name || categoryId;
            return (
                <>
                    <strong>{category}.{props.expression.field}</strong> is <strong>{props.expression.value}</strong>
                </>
            );
        }
        if (expression instanceof ExistingSelectionMatchesExpression) {
            return (<>
                <RuleValueEditor value={expression.lhs} readOnly={true} />
                &nbsp; is equal to&nbsp;
                <RuleValueEditor value={expression.rhs} readOnly={props.readOnly} />
            </>);
        }
        else if (props.expression instanceof CategoryHasSelectionExpression) {
            const categoryId = props.expression.category;
            const category = rulesConext.categories.find(cat => cat.categoryId == categoryId)?.name || categoryId;
            return (
                <>Category <strong>{category} has a selection</strong></>
            );
        }
        else if (expression instanceof EqualsExpression) {
            return (<>
                <RuleValueEditor value={expression.lhs} readOnly={props.readOnly} />
                &nbsp; is equal to&nbsp;
                <RuleValueEditor value={expression.rhs} readOnly={props.readOnly} />
            </>);
        }
        else if (expression instanceof NotExpression) {
            return (<><strong>NOT</strong> <RuleExpressionEditor expression={expression.expression} readOnly={props.readOnly} /></>);
        }
        else if (props.expression instanceof GlobalExpression) {
            const exprName = props.expression.name;
            const global = rulesConext.globalExpressions.find(ge => ge.id == exprName)?.definition.name || exprName;
            return (<>
                Global Expression <strong>{global}</strong>
            </>);
        }
        else if (!props.expression?.type || props.expression.type == '') {
            return (<>No expression type defined.</>);
        }
        else {
            return (<>Expression {props.expression.type} not supported.</>);
        }
    }

    function getExpressionConfig() {
        if (expression instanceof AndExpression) {
            return (<>
                <AndExpressionEditor expression={expression} onUpdate={onUpdate} />
            </>);
        }
        else if (props.expression instanceof OrExpression) {
            return (<>
                <OrExpressionEditor expression={props.expression} onUpdate={onUpdate} />
            </>);
        }
        else if (props.expression instanceof LhsRhsExpression) {
            return (<>
                <LhsRhsExpressionEditor expression={props.expression} onUpdate={onUpdate} />
            </>);
        }
        else if (props.expression instanceof SelectionHasMetadataOptionExpression) {
            return (<div>
                <MetadataSelectOptionEditor value={props.expression} onUpdate={(val: MetadataSelectOptionInfo) => {
                    const newValue = { ...props.expression, field: val.field, categoryId: val.categoryId, value: val.value } as RuleExpression;
                    Object.setPrototypeOf(newValue, SelectionHasMetadataOptionExpression.prototype);
                    onUpdateFn(newValue);
                }} /></div>);
        }
        if (expression instanceof ExistingSelectionMatchesExpression) {
            return (<>
                <ExistingSelectionMatchesEditor expression={expression} onUpdate={onUpdate} />
            </>);
        }
        else if (props.expression instanceof CategoryHasSelectionExpression) {
            return (<div style={{ display: 'flex', flex: '1 1 0', flexDirection: 'row', width: '100%', alignItems: 'baseline' }}>
                <CategorySelector2 style={{ width: '400px' }} selectedCategory={props.expression.category}
                    onSelectCategory={(cat: Category) => {
                        const newValue = { ...props.expression, category: cat.categoryId } as RuleExpression;
                        Object.setPrototypeOf(newValue, CategoryHasSelectionExpression.prototype);
                        onUpdateFn(newValue);
                    }} />
            </div>);
        }
        else if (expression instanceof EqualsExpression) {
            return (<>
                <EqualsExpressionEditor expression={expression} onUpdate={onUpdate} />
            </>);
        }
        else if (props.expression instanceof NotExpression) {
            return (<>
                <NotExpressionEditor expression={props.expression} onUpdate={onUpdate} />
            </>);
        }
        else if (props.expression instanceof GlobalExpression) {
            return (<>
                <GlobalExpressionSelector name={props.expression.name} onUpdate={onUpdate} />
            </>);
        }
        else if (!props.expression?.type || props.expression.type == '') {
            return (<><br />Choose an expression type.</>);
        }
        else {
            return (<>Expression {props.expression.type} not supported.</>);
        }
    }

    function onChangeType(t: string) {
        if (t == props.expression?.type) return;

        if (t == "and") {
            onUpdateFn(new AndExpression());
        }
        else if (t == "or") {
            onUpdateFn(new OrExpression());
        }
        else if (t == "category-has-selection") {
            onUpdateFn(new CategoryHasSelectionExpression());
        }
        else if (t == "selection-has-metadata-option") {
            onUpdateFn(new SelectionHasMetadataOptionExpression());
        }
        else if (t == "existing-selection-matches") {
            onUpdateFn(new ExistingSelectionMatchesExpression());
        }
        else if (t == "equals") {
            onUpdateFn(new EqualsExpression());
        }
        else if (t == "not-equals") {
            onUpdateFn(new NotEqualsExpression());
        }
        else if (t == "not") {
            onUpdateFn(new NotExpression());
        }
        else if (t == "lt") {
            onUpdateFn(new LtExpression());
        }
        else if (t == "gt") {
            onUpdateFn(new GtExpression());
        }
        else if (t == "gte") {
            onUpdateFn(new GteExpression());
        }
        else if (t == "lte") {
            onUpdateFn(new LteExpression());
        }
        else if (t == "global") {
            onUpdateFn(new GlobalExpression());
        }
    }

    const expressionTypes = [
        ['and', 'AND'],
        ['or', 'OR'],
        ['category-has-selection', 'Category Has Selection'],
        ['selection-has-metadata-option', 'Selection Matches Metadata'],
        ['existing-selection-matches', 'Existing Selection Matches'],
        ['equals', 'Equals'],
        ['not-equals', 'Not Equals'],
        ['not', 'NOT'],
        ['lt', 'Less Than'],
        ['lte', 'Less Than or Equal'],
        ['gt', 'Greater Than'],
        ['gte', 'Greater Than or Equal'],
        ['global', 'Global Expression'],
    ];

    return (
        <>
            {!props.readOnly && <Select value={props.expression?.type} disabled={props.disableExpressionTypeChange} onChange={onChangeType} style={{ width: '400px' }}>
                {expressionTypes.filter(t => !props.disabledExpressionTypes || props.disabledExpressionTypes.indexOf(t[0]) == -1).map((type, idx) => (
                    <Select.Option key={idx} value={type[0]}>{type[1]}</Select.Option>
                ))}
            </Select>}
            {props.readOnly ?
                getExpressionReadOnly()
                : getExpressionConfig()}
        </>
    );
};

export default RuleExpressionEditor;
