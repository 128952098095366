import { StatisticCard } from '@ant-design/pro-components';
import { Col, Modal, Progress, Row, Spin, Tooltip, notification } from 'antd';
import { InfoCircleTwoTone } from "@ant-design/icons";
import { useContext, useState } from 'react';
import { AsyncState } from '../../hook/useAsyncState';
import { BaseQuote, QuoteFilter, QuoteStatus } from '../../api/models';
import { ConfiguratorContext } from '../../context';
import { useIntl } from 'react-intl';

const { Statistic, Divider } = StatisticCard;

const GeneralOrdersInfo = (props: {
  quoteListAsync: AsyncState<BaseQuote[]>
  totalQuantity: number
  filter: QuoteFilter
}) => {

  const [open, setOpen] = useState(false);
  const {quoteListAsync, totalQuantity, filter} = props;
  const quotes = quoteListAsync.val;
  const [orderQuantity, setOrderQuantity] = useState<number>();
  const [shippedQuantity, setShippedQuantity] = useState<number>();
  const [approvedQuoteQuantity, setApprovedQuoteQuantity] = useState<number>();
  const [draftOnlyQuantity, setDraftOnlyQuantity] = useState<number>();
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const loading = orderQuantity == undefined || shippedQuantity == undefined || approvedQuoteQuantity == undefined;

  const loadOrderQuantity = async () => {
    try {
      const resp = await configurator.api.listQuotes({
          ...filter,
          ordersOnly: true,
          page: 0,
          size: 20,
        },
      )
      setOrderQuantity(resp.data.totalElements || 0)
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load order quantity. " + errorMsg });
    }
  }

  const loadShippedQuantity = async () => {
    try {
      const resp = await configurator.api.listQuotes({
          ...filter,
          quoteStatus: [QuoteStatus.SHIPPED],
          page: 0,
          size: 20,
        },
      )
      setShippedQuantity(resp.data.totalElements || 0)
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load shipped quantity. " + errorMsg });
    }
  }

  const loadApprovedQuoteQuantity = async () => {
    try {
      const resp = await configurator.api.listQuotes({
          ...filter,
          quoteStatus: ["Quote (Approved)"],
          page: 0,
          size: 20,
        },
      )
      setApprovedQuoteQuantity(resp.data.totalElements || 0)
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load apporved quote quantity. " + errorMsg });
    }
  }

  const loadDraftOnlyQuantity = async () => {
    try {
      const resp = await configurator.api.listQuotes({
          ...filter,
          hasDraftRevision: true,
          page: 0,
          size: 20,
        },
      )
      setDraftOnlyQuantity(resp.data.totalElements || 0)
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load draft quantity. " + errorMsg });
    }
  }

  const getTwoDigitDecimalNumber = (quantity: number | undefined) => {
    return Math.floor((quantity || 0) * 10000.0 / totalQuantity) / 100.0
  }

  const onCancel = () => {
    setUndefined();
    setOpen(false);
  }

  const setUndefined = () => {
    setOrderQuantity(undefined);
    setApprovedQuoteQuantity(undefined);
    setShippedQuantity(undefined);
    setDraftOnlyQuantity(undefined);
  }


  return (<>
      <Tooltip title="Click to show general information"><InfoCircleTwoTone style={{marginLeft: "10px", marginTop: "10px", fontSize: "20px"}} onClick={() => {setOpen(true)}}/></Tooltip>
      <Modal
        open={open}
        onCancel={onCancel}
        width={"950px"}
        cancelButtonProps={{style: {display: "none"}}}
        okButtonProps={{style: {display: "none"}}}
        centered
        afterOpenChange={() => {
          loadOrderQuantity();
          loadShippedQuantity();
          loadApprovedQuoteQuantity();
          loadDraftOnlyQuantity();
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin spinning={loading} />
        </div>
        {!loading && <StatisticCard.Group direction={'row'}>
          <StatisticCard
            statistic={{
              title: 'Total Quote / Order',
              value: props.totalQuantity,
            }}
            colSpan={6}
          />
          <Divider type={'vertical'} />
          <Row>
            <Col>
            <StatisticCard
              statistic={{
                title: 'Order',
                value: orderQuantity,
                // description: <Statistic value={Math.floor(orderQuantity * 10000.0 / totalQuantity) / 100.0} style={{fontSize: "12px"}}/>,
              }}
              chart={
                <Progress type="circle" percent={getTwoDigitDecimalNumber(orderQuantity)} status="active"/>
              }
              chartPlacement="left"
            />
            <StatisticCard
              statistic={{
                title: 'Shipped Order',
                value: shippedQuantity,
              }}
              chart={
                <Progress type="circle" percent={getTwoDigitDecimalNumber(shippedQuantity)} strokeColor={"darkorange"} status="active"/>
              }
              chartPlacement="left"
            />
            </Col>
            <Col>
            <StatisticCard
              statistic={{
                title: 'Approved Quote',
                value: approvedQuoteQuantity,
              }}
              chart={
                <Progress type="circle" percent={getTwoDigitDecimalNumber(approvedQuoteQuantity)} strokeColor={"green"} status="active"/>
              }
              chartPlacement="left"
            />
            <StatisticCard
              statistic={{
                title: 'Quote with pending request',
                value: draftOnlyQuantity,
              }}
              chart={
                <Progress type="circle" percent={getTwoDigitDecimalNumber(draftOnlyQuantity)} strokeColor={"purple"} status="active"/>
              }
              chartPlacement="left"
            />
            </Col>
          </Row>
        </StatisticCard.Group>}
      </Modal>
  </>);
}

export default GeneralOrdersInfo;
