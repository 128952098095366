import Title from "antd/lib/typography/Title";
import { useContext, useEffect, useState } from "react";
import { FilterAssembliesIfNoneMatchAction, GlobalExpressionDefinition, GlobalExpressionDetails, RuleAction, RuleSet, RuleSetDefinition, PAGINATION_MAX_PAGE_SIZE, Permission } from "../../api/models";
import { Button, Input, Spin, Table, Tabs, notification, Space, TableColumnType } from "antd";
import { useParams } from "react-router-dom";
import { ConfiguratorContext } from "../../context";
import { plainToClass } from "class-transformer";
import RulesContext, { RulesContextInterface } from "../../components/Rules/context";
import {
    CheckCircleTwoTone,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import EditRuleModal from "../../components/Rules/edit_rule_modal";
import EditGlobalExpressionModal from "../../components/Rules/edit_global_expression_modal";
import Paragraph from "antd/lib/typography/Paragraph";


const ViewRuleSet = () => {
    const { id: ruleSetId } = useParams<{ id: string }>();
    const [ruleSet, setRuleSet] = useState<RuleSet>();
    const [quickFilter, setQuickFilter] = useState<string>();

    const configurator = useContext(ConfiguratorContext);
    const [ruleSetDefinition, setRuleSetDefinition] = useState<RuleSetDefinition>();

    const [globalExpressions, setGlobalExpressions] = useState<GlobalExpressionDetails[]>([]);

    const [editRule, setEditRule] = useState<RuleAction>();
    const [editGlobalExpression, setEditGlobalExpression] = useState<GlobalExpressionDetails>();

    const [rulesContext, setRulesContext] = useState<RulesContextInterface>({
        categories: [],
        models: [],
        ruleSet: new RuleSetDefinition(),
        globalExpressions: [],
    });

    useEffect(() => {
        loadRuleSet();
    }, [ruleSetId]);

    async function loadRuleSet() {
        try {
            setRuleSet(undefined);

            const categories = ((await configurator.api.getCategories()).data);
            const modelResp = await configurator.api.listModels( {
              size: PAGINATION_MAX_PAGE_SIZE,
              sort: { field: 'name', direction: 'asc' },
            });
            const models = modelResp.data.content;

            const resp = await configurator.api.getRuleSet(parseInt(ruleSetId));
            if (!resp.ruleSet) return;

            setRuleSet(resp);

            const rules = plainToClass(RuleSetDefinition, JSON.parse(resp.ruleSet));
            setRuleSetDefinition(rules);

            let globalExpressions: GlobalExpressionDetails[] = [];
            if (rules.globalExpressions) {
                globalExpressions = Object.entries(rules.globalExpressions).map(expr => {
                    /*const stub = plainToClass(RuleExpressionStub, {
                        expression: expr[1]
                    });*/
                    const definition = plainToClass(GlobalExpressionDefinition, expr[1]);
                    const details: GlobalExpressionDetails = {
                        id: expr[0],
                        definition,
                    };
                    return details;
                });
            }
            setGlobalExpressions(globalExpressions);

            setRulesContext({
                ...rulesContext,
                ruleSet: rules,
                models: models,
                globalExpressions,
                categories,
            });

        }
        catch (e:any) {
            notification.error({
                message: "Failed to load rule set."
            });
        }
    }

    function addGlobalExpression() {
        if (!ruleSetDefinition) return;
        var definition = new GlobalExpressionDefinition();
        setEditGlobalExpression({ definition, id: '' });
    }

    function addRule() {
        if (!ruleSetDefinition) return;
        var ruleAction = new FilterAssembliesIfNoneMatchAction();
        ruleAction.ruleId = -1;
        setEditRule(ruleAction);
    }

    function onEditRuleFinished(_rule: RuleAction | undefined) {
        setEditRule(undefined);
        loadRuleSet();
    }

    function onEditGlobalExpressionFinished(_definition: GlobalExpressionDefinition | undefined) {
        setEditGlobalExpression(undefined);
        loadRuleSet();
    }

    const filterCategories = rulesContext.categories.map(cat => ({
            text: cat.name,
            value: cat.categoryId,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));

    async function onClickDeleteRule(rule: RuleAction) {
        if (!ruleSet) return;

        if (window.confirm('Are you sure you want to delete the rule: ' + rule.name + ' (' + rule.ruleId + ')?')) {
            try {
                await configurator.api.deleteRule(ruleSet.id, { ruleId: rule.ruleId });
                loadRuleSet();
                notification.success({ message: 'Rule deleted' });
            }
            catch (e) {
                notification.error({ message: 'Rule could not be deleted at this time.' });
            }
        }
    }

    const columns:TableColumnType<RuleAction>[] = [
        {
            title: 'Actions',
            width: 100,
            render: (rule) => 
                <>
                  <Space>
                    {configurator.hasPermission(Permission.RULES_WRITE)  &&
                      <Button icon={<DeleteOutlined />} size="small" danger onClick={() => onClickDeleteRule(rule)}></Button>}
                    <Button icon={<EditOutlined />} size="small" onClick={() => setEditRule(rule)}></Button>&nbsp;
                  </Space>
                </>
                ,
        },
        {
            title: 'Rule ID',
            dataIndex: 'ruleId',
            width: 100,
        },
        {
            title: 'Enabled',
            width: 100,
            render: (rule) => !rule.disabled && <CheckCircleTwoTone twoToneColor="#52c41a" />
        },
        {
            title: 'Name',
            render: (rule) => rule.name 
        },
        {
            title: 'Type',
            render: (rule: RuleAction) => rule.actionName()
        },
        {
            title: 'Condition Category',
            filterSearch: true,
            filters: filterCategories,
            onFilter: (value: any, record: RuleAction) => record.conditionCategories().includes(value),
            render: (rule: RuleAction) => <Paragraph style={{maxWidth:"18rem"}} ellipsis={{expandable: true, tooltip:true}}>
              {rule.conditionCategories()
                .map(catId => rulesContext.categories.find(cat => cat.categoryId == catId)?.name || catId)
                .join(',')}</Paragraph>
        },
        {
            title: 'Filter Category',
            filterSearch: true,
            filters: filterCategories,
            onFilter: (value: any, record: RuleAction) => record.filterCategories().includes(value),
            render: (rule: RuleAction) => <Paragraph style={{maxWidth:"18rem"}} ellipsis={{expandable: true, tooltip:true}}>
              {rule.filterCategories()
                .map(catId => rulesContext.categories.find(cat => cat.categoryId == catId)?.name || catId)
                .join(',')}
            </Paragraph>
        }
    ];

    const globalExpressionColumns:TableColumnType<GlobalExpressionDetails>[] = [
        {
            title: 'Actions',
            width: 50,
            render: (globalExpr) => <Button size="small" icon={<EditOutlined />} onClick={() => setEditGlobalExpression(globalExpr)} />
        },
        {
            title: 'Name',
            render: (details) => details.definition.name
            
        }
    ];

    const filteredRules = ruleSetDefinition?.rules.filter(rule => !quickFilter?.length || ( (rule.ruleId.toString() == quickFilter) || ((rule.name || '').toLowerCase().includes(quickFilter.toLowerCase())) ) )

    const filteredExpressions = globalExpressions?.filter(expr => !quickFilter?.length || (expr.definition.name.toLowerCase().includes(quickFilter.toLowerCase())) )

    const items = [
        {
            key: 'rules',
            label: 'Rules',
            children: <Space direction="vertical" style={{width: "100%"}}>
              {configurator.hasPermission(Permission.RULES_WRITE) && <Button type="primary" onClick={addRule}>Add Rule</Button> }
              <Table loading={!ruleSet} dataSource={filteredRules} rowKey="ruleId" columns={columns} />
              <EditRuleModal 
                open={!!editRule} 
                rule={editRule} 
                onCancel={() => setEditRule(undefined)} 
                onFinished={onEditRuleFinished} 
                ruleSet={ruleSet} /> 
            </Space>
          },
        {
            key: 'globalExpressions',
            label: 'Global Expressions',
            children: <Space direction="vertical" style={{width: "100%"}}>
                    {configurator.hasPermission(Permission.RULES_WRITE) && <Button type="primary" onClick={addGlobalExpression}>Add Global Expression</Button>}
                    <Table loading={!ruleSet} dataSource={filteredExpressions} rowKey="id" columns={globalExpressionColumns} />
                    <EditGlobalExpressionModal 
                      id={editGlobalExpression?.id} 
                      open={!!(editGlobalExpression && ruleSet)} 
                      definition={editGlobalExpression?.definition} 
                      ruleSet={ruleSet}
                      onCancel={() => setEditGlobalExpression(undefined)} 
                      onFinished={onEditGlobalExpressionFinished} 
                    />
                </Space>
        }
    ];

    return (
        <div className="site-layout-background">
            <Title level={2}>Rule Set</Title>
            <RulesContext.Provider value={rulesContext}>
            <Spin spinning={!ruleSet}>
              <Space direction="vertical" style={{width:"100%"}}>
                <div> <strong>Name: </strong> <span>{ruleSet?.name}</span> </div>
                <Input value={quickFilter} onChange={(e) => setQuickFilter(e.target.value)} allowClear placeholder="Filter by Rule ID or Name" />
                <Tabs items={items} defaultActiveKey="rules" />
              </Space>
            </Spin>
            </RulesContext.Provider>

        </div >
    )
};

export default ViewRuleSet;
