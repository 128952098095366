import {notification} from "antd";
import {useContext} from "react";
import {useIntl} from "react-intl";
import { Quote} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import { useQuoteContext } from "../../contexts/QuoteContext";
import BMButton, {BMButtonProps} from "../BMButton";

const CreatePriceProtectedSalesChangeOrderButton = (props:Omit<BMButtonProps, 'onChange'> & {
  onChange?: (q:Quote) => void
}) => {

  const { onChange:a, ...btnProps } = props;

  const {quoteAsync} = useQuoteContext();
  const quote = quoteAsync?.val;
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const handleSaveSalesChangeOrder = async () => {
    const quote = await createPriceProtectedSalesChangeOrder();
    if ( quote ) {
      props.onChange?.(quote);
    }
  }

  const createPriceProtectedSalesChangeOrder = async () : Promise<Quote | undefined> => {
    if ( !quote ) return;

    quoteAsync.setLoading()
    try {
      const resp = await configurator.api.createChangeOrder(quote.id, true);
      quoteAsync.setDone(resp.data);
      notification.success({message:"Change Order Created!"});
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to create change order. " + errorMsg;
      notification.error( { message: msg });
      quoteAsync.setFail(msg);
    }

    return;
  }

  return <>
    <BMButton
      type="primary"
      onClick={handleSaveSalesChangeOrder}
      {...btnProps}
    />
  </>
}

export default CreatePriceProtectedSalesChangeOrderButton;
