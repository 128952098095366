import Title from "antd/lib/typography/Title";
import {Result, notification, Button } from "antd";
import {ValidateFields} from "rc-field-form/lib/interface";
import {useContext, useState} from "react";
import {useIntl} from "react-intl";
import {Customer, Quote, QuoteReview, ShippingDestination} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import {useAsyncState} from "../../hook/useAsyncState";
import Utils from "../../util/util";
import BMButton, {BMButtonProps} from "../BMButton";
import QuoteReviewDetail from "../QuoteReviewDetail";
import { useQuoteContext } from "../../contexts/QuoteContext";
import ModalWizard from "../ModalWizard";
import { SelectShippingDestination } from "../ShippingDestinationModal";
import { SelectEndCustomer } from "../customer_entry";
import { WizardInstance } from "../Wizard";

const SubmitQuoteButton = (props:Omit<BMButtonProps, 'onChange'> & {
  onChange?: (q:Quote) => void
  onValidate?: () => Promise<ValidateFields | undefined>
  isReservation?:boolean
}) => {

  const { onChange:a, onValidate:b, isReservation:c, ...btnProps } = props;

  const {quoteAsync, adminView, setQuoteFormValues } = useQuoteContext();
  const quote = quoteAsync?.val;
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [review, reviewAsync] = useAsyncState<QuoteReview>();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [selectedShippingDestination, setSelectedShippingDestination] = useState<ShippingDestination>();
  const [selectedEndCustomer, setSelectedEndCustomer] = useState<Customer>();
  const [initialValue, setInitialValue] = useState<Quote>();

  const handleOpen = () => {
    setInitialValue( quote && {...quote});
    setSelectedShippingDestination(quote?.shippingDestination);
    setSelectedEndCustomer(quote?.endCustomer);
  };

  const submit = async () : Promise<Quote|undefined> => {
    if (!quote) return;

    quoteAsync.setLoading();
    try {
      const options = {
        reservation: props.isReservation
      };
      const resp = await configurator.api.submitQuoteApproval(quote.id, options );
      quoteAsync.setDone(resp.data);

      notification["success"]({
        message: "Submitted",
        description: "Your quote has been submitted for approval",
      });

      return resp.data;
    } catch (e: any) {

      const errorMsg = intl.formatMessage({ id: e.message });
      const msg = "Failed to submit quote. " + errorMsg
      notification.error( { message: msg, duration: 0 });
    }

    return;
  };

  const loadReview = async (quoteRevisionId:number | undefined) : Promise<QuoteReview | undefined> => {
    if ( !quoteRevisionId ) return;

    reviewAsync.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteReview(quoteRevisionId, { dealerView:!adminView });
      reviewAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
      reviewAsync.setFail(e.message);
    }
    return;
  }

  const handleSubmitBtn = async (confirm?:boolean) => {

    const isValid = await props.onValidate?.();

    const review = ( reviewAsync.isInitial() ) ? await loadReview(quote?.displayRevisionId) : reviewAsync.val;
    const needsReview = !isValid || props.isReservation || Utils.reviewHasErrors(review) ;
    const showDialog =  !confirm && needsReview;
    if ( showDialog ) {
      setIsOpen( true );
    }
    else {
      handleSubmit();
    }
  }

  const handleSelectCustomer = (nav:WizardInstance) => {
    if ( nav.getLastStep()?.key !== nav.getCurrentStep()?.key ) {
      nav.nextStep();
    }
    else {
      handleReviewFinished();
    }
  }

  const handleSelectDestination = (nav:WizardInstance) => {
    if ( nav.getLastStep()?.key !== nav.getCurrentStep()?.key ) {
      nav.nextStep();
    }
    else {
      handleReviewFinished();
    }
  }

  const handleReviewFinished = async () => {
    if ( !quote ) return;
    if ( !selectedShippingDestination ) return;
    if ( !selectedEndCustomer ) return;

    if ( ( quote?.endCustomer != selectedEndCustomer ) ||
      (quote?.shippingDestination != selectedShippingDestination )
    ) {
      await setQuoteFormValues?.({
        ...quote,
        endCustomer:quote.endCustomer || selectedEndCustomer,
        shippingDestination: quote.shippingDestination || selectedShippingDestination
      });
    }

    handleSubmitBtn(true);
  }

  const handleSubmit = async () => {
    setDisableSubmit(true);

    const quote = await submit();
    if ( quote ) {
      props.onChange?.(quote);

      setIsOpen(false);
    }
  }

  const handleCancel = () => {
    setIsOpen(false);
    setDisableSubmit(false);
  }

  const isWorking = quoteAsync?.isLoading() || reviewAsync.isLoading();

  const btnTitle = props.isReservation ? "Submit as Reservation" : "Submit Quote";

  return <>
    <BMButton
      type="primary"
      onClick={() => handleSubmitBtn()}
      {...btnProps}
      loading={isWorking}
    > {btnTitle}</BMButton>
    <ModalWizard
      centered
      open={isOpen}
      onCancel={handleCancel}
      width={'50rem'}
      showSteps={false}
      afterOpenChange={(open) => {
        if(open) {
          handleOpen();
        }
      }}
      steps={[
        {
          key:"selectShippingDestination",
          title: "Select Shipping Destination",
          hidden: !!initialValue?.shippingDestination,
          body:(_nav) => <div>
            <div>A shipping destination is required for quote approval.</div>
            <SelectShippingDestination value={selectedShippingDestination} onChange={setSelectedShippingDestination} />
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" disabled={!selectedShippingDestination} loading={isWorking} onClick={() => handleSelectDestination(nav)} >Next</Button>
            <Button key="cancel" type="primary" onClick={handleCancel} >Cancel</Button>
          </div>,
        },
        {
          key:"selectCustomer",
          title: "Select End Customer",
          hidden: !!initialValue?.endCustomer,
          body:(_nav) => <div>
            <div>A customer is required for quote approval.</div>
            <SelectEndCustomer value={selectedEndCustomer} onChange={setSelectedEndCustomer} />
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" disabled={!selectedEndCustomer} loading={isWorking} onClick={() => handleSelectCustomer(nav)} >Next</Button>
            <Button key="cancel" type="primary" onClick={handleCancel} >Cancel</Button>
          </div>,
        },
        {
          key:"reviewQuote",
          hidden: !Utils.reviewHasErrors(review),
          body:(_nav) => <Result status="warning" 
            title={"The following issues with the quote have been identified."}
            subTitle={<Title level={5}>Continue to submit for approval?</Title> }
          >
            <QuoteReviewDetail 
              review={review} 
              ignoreDashDrawing={true} 
            />
          </Result>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            {props.isReservation
              ? <Button key="next" type="primary" onClick={nav.nextStep} >Next</Button>
              : <Button key="next" type="primary" onClick={handleReviewFinished} loading={isWorking} disabled={disableSubmit} >Continue</Button>}
            <Button key="back" onClick={handleCancel} >Cancel</Button>
          </div>,
        },
        {
          key:"setReservation",
          hidden: !props.isReservation,
          body:(_nav) => <Result status="warning" 
            title={"This is a reservation."}
            subTitle={<Title level={5}>Continue to submit for approval?</Title> }
          >
            <ul>
              <li>This is a reservation for future production capacity.</li>
              <li>It requires approval before it will take effect.</li>
              <li>It must be converted into a quote to become an order.</li>
            </ul>
          </Result>,
          footer: (_nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={handleReviewFinished} disabled={disableSubmit}>Continue</Button>
            <Button key="back" onClick={handleCancel} >Cancel</Button>
          </div>,
        }
      ]}
    />
  </>
}

export default SubmitQuoteButton;
