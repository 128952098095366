import { useContext, useEffect, useRef } from "react";
import { ConfiguratorContext } from "../context";
import { notification, Select, SelectProps } from "antd";
import { BaseModel } from "../api/models";
import { useAsyncState } from "../hook/useAsyncState";
import Utils from "../util/util";
import { CancelTokenSource } from "axios";
import { useIntl } from "react-intl";

const ModelMultipleSelector = (props: SelectProps) => {

  const [models, modelsAsync] = useAsyncState<BaseModel[]>();
  const configurator = useContext(ConfiguratorContext);
  const cancelLoadModelsTokenSourceRef = useRef<CancelTokenSource>();
  const intl = useIntl();

  const loadModels = async() => {
    modelsAsync.setLoading();
    try {
      const resp = await Utils.executeWithCancelToken(cancelLoadModelsTokenSourceRef, (token) =>
        configurator.api.listModels( {inactive: false }, token )
      );
      modelsAsync.setDone(resp?.data?.content);
    }
    catch(e: any){
      const msg = e.response?.data?.message || e.message ;
      const errorMsg = intl.formatMessage({ id: msg });
      console.log(errorMsg);
      notification.error({message: "Failed to load models in filter."})
    }

  }

  useEffect(() => {
    loadModels();
  }, [])

  return <Select
    {...props}
      loading={modelsAsync.isLoading()}
      mode="multiple"
      showSearch
      allowClear
      optionFilterProp="label"
      style={props.style}
      options={models?.map(m => ({value: m.id, label: m.name}))}
    />
};

export default ModelMultipleSelector;
