import { Row, Modal, Checkbox, Transfer, Col, notification, ButtonProps, Input, Button, Card } from "antd";
import { useState, useContext } from "react";
import { TruckRecord, Quote } from "../../api/models"
import { ConfiguratorContext } from "../../context";
import type { TransferDirection } from 'antd/es/transfer';
import BMButton from "../BMButton";
import modal from "antd/lib/modal";
import { ExclamationCircleOutlined, SyncOutlined } from "@ant-design/icons";
import dayjs from 'dayjs'

interface SerialNumberDisplay {
  origin: any[] | null,
  new: any[] | null,
}

const SplitOrder = (props:{
  quote: Quote | undefined | null,
  disabled: boolean,
  onDisabledClick: ()=>void,
} & ButtonProps) => {

  const {quote, ...btnProps} = props;

  const trucksToBeSplit:TruckRecord[] = quote?.trucks
    ?.map(truck => ({key: truck.truckSerialNumberStr, truckSerialNumberStr: truck.truckSerialNumberStr}))
    .sort((a, b) => (a.truckSerialNumberStr.localeCompare(b.truckSerialNumberStr))) || [] ;

  const configurator = useContext(ConfiguratorContext);

  const [transferTargetKeys, setTransferTargetKeys] = useState<string[]>([]);
  const [transferSelectedKeys, setTransferSelectedKeys] = useState<string[]>([]);
  const [confirmSplit, setConfirmSplit] = useState<boolean>(false);
  const [DisplaySerialNumbers, setDisplaySerialNumbers] = useState<SerialNumberDisplay>({origin: null, new: null});
  const [showSplitModal, setShowSplitModal] = useState<boolean>(false);
  const [isSaving, setSaving] = useState(false);
  const [updatedQuoteName, setUpdatedQuoteName] = useState<string | undefined>();
  const [newQuoteNames, setNewQuoteNames] = useState<string[]>([]);
  const [selectedSplitOrder, setSelectedSplitOrder] = useState<number | undefined>();
  const [allSplitOrders, setAllSplitOrders] = useState<string[][] | undefined>();
  const [remainingTrucks, setRemainingTrucks] = useState<TruckRecord[]>(trucksToBeSplit);

  const splitQuantity = (allSplitOrders ?? []).flat().length;
  const splitOrderQuantity = (allSplitOrders ?? []).length;
  const assignValidTruck = allSplitOrders ? allSplitOrders.every(o => o.length > 0): false;
  const assignNamesValid = newQuoteNames.every(name => (name ?? "") !== "") && ((updatedQuoteName ?? "") !== "");

  const onTransferItemsChange = (nextTargetKeys: string[], _direction: TransferDirection, _moveKeys: string[]) => {

    setTransferTargetKeys(nextTargetKeys);

    // Logic to update the assigned trucks and remaining trucks
    if (allSplitOrders && selectedSplitOrder != undefined) {

      const newAllSplitOrders = allSplitOrders.map((o, i) => {
        return i === selectedSplitOrder ? [...nextTargetKeys] : o;
      });
      setAllSplitOrders(newAllSplitOrders);
      getDisplaySerialNumber(newAllSplitOrders);

      const assignedTrucksSerialInOtherSplitOrders = newAllSplitOrders?.filter((_a, idx) => idx !== selectedSplitOrder).flat();
      const newRemainingTrucks = trucksToBeSplit.filter(truck => !assignedTrucksSerialInOtherSplitOrders?.includes(truck.truckSerialNumberStr)); // must keep the selected order trucks in the dataSource
      setRemainingTrucks(newRemainingTrucks);
    }
  };

  const onTransferSelectChange = (sourceSelectedKeys: any[], targetSelectedKeys: any[]) => {
    setTransferSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onTransferScroll = (_direction: TransferDirection, _e: React.SyntheticEvent<HTMLUListElement>) => {

  };

  const addSplitOrder = () => {
    // select first split order by default after adding split order
    if (!allSplitOrders?.length) {
      setSelectedSplitOrder(0);
    }
    setAllSplitOrders(prevOrders => prevOrders ? [...prevOrders, []] : [[]]); // update split order
    let displaySerialNew = DisplaySerialNumbers.new ? [...DisplaySerialNumbers.new.concat([[]])] : null;
    setDisplaySerialNumbers({origin: DisplaySerialNumbers.origin, new: displaySerialNew});  // update serial
    setNewQuoteNames(newQuoteNames.concat([""])); // update name
  }

  // handle the selection after split order removal and clear the target box
  const setSelectedOrderAfterRemoval = () => {
    const newSelectedOrder = splitOrderQuantity > 1 ? (selectedSplitOrder === splitOrderQuantity - 1 ? 0 : selectedSplitOrder) : undefined;
    setSelectedSplitOrder(newSelectedOrder);
    if (newSelectedOrder != undefined && allSplitOrders) {
      setTransferTargetKeys([...allSplitOrders[newSelectedOrder]]);
    }
    else if ( newSelectedOrder == undefined ) {
      setTransferTargetKeys([]);
    }
  }

  const reduceSplitOrder = () => {

    const releasedTruckSerial = allSplitOrders ? allSplitOrders[allSplitOrders.length - 1] : [];
    const remainingTrucksSerial = remainingTrucks.map(truck => truck.truckSerialNumberStr);
    const releaseTrucks = trucksToBeSplit.filter(truck => releasedTruckSerial.includes(truck.truckSerialNumberStr))
                                          .filter(truck => !remainingTrucksSerial.includes(truck.truckSerialNumberStr));
    const newRemainingTrucks = [...remainingTrucks, ...releaseTrucks];

    setRemainingTrucks(newRemainingTrucks);
    setSelectedOrderAfterRemoval();

    getDisplaySerialNumberAfterRemoval(newRemainingTrucks.map(t => t.truckSerialNumberStr)); // Update Serial
    removeLastItemInArrayState(setAllSplitOrders); // Update split order
    removeLastItemInArrayState(setNewQuoteNames); // Update Name

  }

  const removeLastItemInArrayState = (setMethod: (val: any) => void) => {
    setMethod(prevNames => {
      if (prevNames && prevNames.length > 0) {
        return prevNames.slice(0, -1);
      }
      return prevNames;
    });
  }

  const onSelectSplitOrder = (currentSelected: number) => {
    setSelectedSplitOrder(currentSelected);

    // --------- logic to achieve the current remaining truck
    const assignedTrucksSnInOtherSplitOrders = allSplitOrders?.filter((_, idx) => idx !== currentSelected).flat();
    const newRemainingTrucks = trucksToBeSplit.filter(truck => !assignedTrucksSnInOtherSplitOrders?.includes(truck.truckSerialNumberStr));
    setRemainingTrucks(newRemainingTrucks);
    // ---------

    if (selectedSplitOrder != undefined && selectedSplitOrder !== currentSelected && allSplitOrders) {
      setTransferTargetKeys(allSplitOrders[currentSelected]);
    }
  }

  const assignNewQuoteName = (newName: string, idx: number) => {
    const updatingQuoteName = newQuoteNames.map((name, i) => {
      return i === idx ? newName : name;
    });
    setNewQuoteNames(updatingQuoteName);
  }
  
  const onConfirmSplit = async () => {
    if ( !quote ) return;

    setSaving(true);
    try {
      const resp = await configurator.api.splitQuote({
        quoteId: quote?.quoteId,
        trucksToBeSplit: (allSplitOrders || []).map(order => order.map(sn => Number(sn))),
        updatedQuoteName: updatedQuoteName || quote.name,
        newQuoteNames: newQuoteNames,
      })

      window.location.href = "/configurator/" + encodeURI(resp?.data.quoteId);
    } catch (e) {
      console.log(e);
      notification.error({message: "Failed to split quote"});
    } finally {
      setSaving(false);
    }
  }

  const onCancelSplit = () => {
    if (isSaving) {
      return
    }
    setShowSplitModal(false);
    onReset();
  }

  const onReset = () => {
    setDisplaySerialNumbers({origin: null, new: null});
    setTransferTargetKeys([]);
    setTransferSelectedKeys([]);
    setAllSplitOrders(undefined);
    setSelectedSplitOrder(undefined);
    setRemainingTrucks(trucksToBeSplit);
    setUpdatedQuoteName(undefined);
    setNewQuoteNames([]);
    setConfirmSplit(false);
  }

  const getDisplaySerialNumberAfterRemoval = (newRemainingTrucks: string[]) => {
    let afterChange = allSplitOrders ? [...allSplitOrders] : [];
    afterChange = afterChange.slice(0, -1); // Remove the last split quote

    setDisplaySerialNumbers({origin: getStrList(newRemainingTrucks.sort()), new: afterChange.map(q => getStrList(q.sort()))});
  }

  const getDisplaySerialNumber = (newAllSplitOrders: string[][]) => {

    let originalQuote: string[] = []
    let newQuote: string[][] = []

    const assignedTrucks = newAllSplitOrders.flat(); // All assigned truckes

    originalQuote = [...trucksToBeSplit.map(truck => truck.truckSerialNumberStr).filter(sn => !assignedTrucks.includes(sn))];
    newQuote = [...newAllSplitOrders];

    setDisplaySerialNumbers({origin: getStrList(originalQuote.sort()), new: newQuote.map(q => getStrList(q.sort()))});
  }

  const getStrList = (numberList: string[]) => {
    let pointer = 0;
    const ret: string[] = [];
    for (let i = 1; i <= numberList.length; i++) {
      if (i === numberList.length || Number(numberList[i]) - Number(numberList[i - 1]) != 1) {
        if ( numberList[i - 1] === numberList[pointer]) {
          ret.push(String(numberList[pointer]) + ' (1)');
        }
        else {
          ret.push(numberList[pointer] + '-' + numberList[i - 1] + ` (${i - pointer})`);
        }
        pointer = i;
      }
    }
    return ret;
  }

  const PRODUCTION_GRACE_PERIOD_DAYS = 3;
  const handleClickSplitBtn = () => {

    const isWarning = dayjs(quote?.productionDate).subtract(PRODUCTION_GRACE_PERIOD_DAYS, 'days').isBefore(dayjs());
    if ( quote?.productionDate && isWarning ) {
      modal.confirm({
        title: 'Warning',
        icon: <ExclamationCircleOutlined />,
        content: 'Please verify these serial numbers have not been manufactured before splitting.',
        onOk() {
          setShowSplitModal(true);
        },
      });
    }
    else {
      setShowSplitModal(true);
    }

  }
  //todo - in the future pop-up a dialog and let them enter a quantity.
  //then copy and modify the quote for them
  const isNoSerialNumbers = ( ( quote?.trucks?.length || 0 ) < 2 );
  const handleDisabledClick = () => {

    if ( isNoSerialNumbers ) {
      notification.warning({message: "No serial numbers have been assigned.  Please copy and modify the new quote." });
      return;
    }

    if ( props.disabled ) {
      props.onDisabledClick();
      return;
    }

  }

  const getFooter = () => {

    const getDisabledConfirmMsg = () => {
      return  splitQuantity >= trucksToBeSplit.length ? `Only ${trucksToBeSplit.length} trucks can be split` 
        : splitQuantity == 0 ? "Please select truck to split."
        : !assignValidTruck ? "Some split order doesn't have assigned trucks."
        : !assignNamesValid ? "Some split order doesn't have assgined names"
        : !confirmSplit ? "Please check 'Confirm splitting'"
        : isSaving ? "It's in saving process"
        : undefined;
    }

    const notifyDisabled = (msg:string | undefined) => {

      if ( !!msg ) {
        notification.warning({message: msg, style: {maxWidth: "350px"} });
      }
    }

    return [
        <Row justify={"space-between"} key="footer">
          <Col>
            <Checkbox key="confirm-checkbox" onChange={() => {setConfirmSplit((previousState)=>{return !previousState})}} checked={confirmSplit} style={{fontWeight: "bold"}}>
              Confirm splitting the quote.
            </Checkbox>
          </Col>
          <Col>
            {isSaving && <SyncOutlined spin color="#1890ff" style={{marginRight: "10px", fontSize: "20px", color: "#1890ff"}}/>}
            <Button key="cancel"
              onClick={() => onCancelSplit()} >
              Cancel
            </Button>
            <BMButton
              data-testid="confirm-split-button"
              key="confirm"
              disabled={!!getDisabledConfirmMsg()}
              onDisabledClick={() => notifyDisabled(getDisabledConfirmMsg())}
              onClick={() => onConfirmSplit()}
              type="primary"
            >
              Confirm
            </BMButton>
          </Col>
        </Row>,
      ];
  }

  return <>
    <style>
      {`
       .split-transfer .ant-transfer-list {
          border: 2px solid #1677ff !important;
          margin-bottom: 1rem;
        }

        .split-transfer .ant-transfer-list-header-title {
          color: #1677ff;
        }

        .split-modal .ant-modal-title {
          font-size: 24px;
        }

        .split-modal .ant-modal {
          transition: transform 0.1s ease;
          transform-origin: center center !important;
        }

        .split-option-button:hover {
          color: #1890ff !important;
          background-color: transparent !important;
        }

        .split-review {
          border: 2px solid #1890ff;
          border-radius: 10px;
          position: relative;
          padding: 25px 15px;
          margin-bottom: 20px;
          margin-top: 15px;
        }

        .split-review::before {
          content: attr(section-title);
          position: absolute; 
          top: -19px;
          left: 12px;
          background-color: white;
          padding: 0 5px;
          font-weight: bold;
          font-size: 18px;
          color: #1890ff;
        }

        .split-quantity-input .ant-input-number-handler-up, .split-quantity-input .ant-input-number-handler-down {
          background-color: #1890ff !important;
        }

        .split-quantity-input .ant-input-number-handler-up-inner, .split-quantity-input .ant-input-number-handler-down-inner {
          color: white !important;
          font-size: 9px !important;
        }

      `}
    </style>
    <BMButton {...btnProps}
      disabled={props.disabled || isNoSerialNumbers}
      onDisabledClick={handleDisabledClick}
      onClick={handleClickSplitBtn}
    >
      Split
    </BMButton>

    <Modal title="Split" 
      open={showSplitModal} 
      maskClosable={true} 
      onCancel={onCancelSplit}
      confirmLoading={isSaving}
      closable={!isSaving}
      bodyStyle={{height: 730, overflowY: "auto", overflowX: "hidden", paddingLeft: "10px"}}
      width={910}
      rootClassName="split-modal"
      style={{top: 5}}
      footer={getFooter()}
    >

      <p><strong>{`There are ${trucksToBeSplit.length} trucks in this quote/order. `}</strong></p>

      <>
        <Row justify="space-between" align="middle" style={{marginTop: "5px", marginBottom: "10px"}}>
          <>
            <Col>
              <Button style={{marginTop: "5px", marginRight: "0.5rem"}} onClick={() => addSplitOrder()} type="primary" size='small'>Add Split</Button>
              <Button style={{marginTop: "5px", marginRight: "2rem"}} onClick={() => reduceSplitOrder()} type="primary" size='small' disabled={splitOrderQuantity === 0}>Remove Split</Button>
            </Col>

            <Col>
              {allSplitOrders?.map((_, i) => 
                <Button 
                  type="primary"
                  shape="circle"
                  size='small'
                  style={{marginRight: "5px", backgroundColor: i === selectedSplitOrder ? "#1677ff" : "lightblue"}}
                  onClick={() => onSelectSplitOrder(i)}
                  key={`split-order-selection-${i}`}
                >
                    {i + 1}
                </Button>
              )}
            </Col>
          </>
        </Row>

        <Transfer
          dataSource={remainingTrucks}
          titles={['Original Quote', `New Quote ${(selectedSplitOrder ?? 0) + 1}`]}
          targetKeys={transferTargetKeys}
          selectedKeys={transferSelectedKeys}
          onChange={onTransferItemsChange}
          onSelectChange={onTransferSelectChange}
          onScroll={onTransferScroll}
          render={item => item.truckSerialNumberStr}
          pagination={{pageSize: 5}}
          locale={{itemUnit: 'Truck', itemsUnit: 'Trucks'}}
          disabled={splitOrderQuantity === 0 || selectedSplitOrder == undefined}
          listStyle={{
            width: 400,
            height: 250,
          }}
          rootClassName="split-transfer"
        />

      </>

      {quote?.trucks != null && (transferTargetKeys.length === quote.trucks.length || splitQuantity >= trucksToBeSplit.length ) && 
        <p style={{color: 'red'}}>There must be at least one truck in the quote. </p>
      }

      <Row>
        <div key="quote-name" style={{marginBottom: "1rem", fontWeight: "bold"}}>{`Current Quote Name: ${quote?.name}`}</div>
      </Row>

      <div key="split-name-review">
        <Row align={"top"} gutter={30} style={{marginBottom: "2rem"}}>
          <Col span={12}>
            <span style={{color: "red", marginRight: "5px"}}>*</span>
            <span style={{color: "#1677ff"}}>Original Quote Name: </span>
            <Input value={updatedQuoteName} onChange={(e) => setUpdatedQuoteName(e.target.value)} style={{maxWidth: "400px"}}/>
          </Col>
          <Col span={12}>
            {allSplitOrders?.map( (_, i) =>
            <div key={`order-name-${i}`}>
              <Row gutter={12}>
                <span style={{color: "red", marginRight: "5px"}}>*</span>
                <span style={{color: "#1677ff"}}>{`New Quote ${i + 1} Name: `}</span>
                <Input value={newQuoteNames[i]} onChange={(e) => assignNewQuoteName(e.target.value, i)}  style={{maxWidth: "400px"}}/>
              </Row>
            </div>)
            }
          </Col>
        </Row>

        {DisplaySerialNumbers.origin != null && DisplaySerialNumbers.new != null && splitQuantity > 0
            && (splitQuantity < trucksToBeSplit.length) &&
        <div className="split-review" section-title="Split Review" key="split-review">
          <Row>
            <Col span={8}>
              <Card
                title={updatedQuoteName ? (updatedQuoteName + ":") : quote?.name}
                headStyle={{padding: '3px 6px', minHeight: '20px', fontSize: "14px", backgroundColor: "#1890ff", color: "white"}}
                bodyStyle={{padding: '3px 6px', borderColor: "#1890ff", border: "1px solid #1890ff", minHeight: '30px'}}
              >
                {DisplaySerialNumbers.origin.map( (str, i) => (<div key={i}>{str}</div>) )}
              </Card>
            </Col>
            <Col span={8} offset={4}>
              {DisplaySerialNumbers?.new?.map( (o, i) =>
                <div key={`order-sn-${i}`} style={{marginLeft: "15px", marginBottom: "10px"}}>
                  <Card
                    title={`${newQuoteNames[i] ? newQuoteNames[i] : "Split Quote " + (i + 1)}:`}
                    headStyle={{padding: '3px 6px', minHeight: '20px', fontSize: "14px", backgroundColor: "#1890ff", color: "white"}}
                    bodyStyle={{padding: '3px 6px', borderColor: "#1890ff", border: "1px solid #1890ff", minHeight: '30px'}}
                  >
                    {o.map( (str: string, i: number) => (<div key={i}>{str}</div>) )}
                  </Card>
                </div>
              )}
            </Col>
          </Row>

          {(!assignValidTruck || !assignNamesValid) &&
            <p style={{color: 'red'}}>Please assign truck and names to split quote(s).</p>
          }
        </div>}
      </div>
    </Modal>
  </>;
}

export default SplitOrder;
