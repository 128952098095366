import styles from "./VersionChecker.module.css";
import { useEffect } from 'react';
import axios from 'axios';
import { Modal, Result } from 'antd';

// Periodic check every 15 minutes
const CHECK_VERSION_INTERVAL = 15 * 60 * 1000; //in ms

export const VersionChecker = () => {

  useEffect(() => {

    let currentVersion:string;
    const checkVersion = async () => {
      try {
        const response = await axios.get('/manifest.json', {
          // query URL without using browser cache
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          },
        });

        const latestVersion = response.data.version;
        console.log( "latestVersion", latestVersion, "currentVersion", currentVersion );
        if (!currentVersion?.length) {
          currentVersion = latestVersion;
        }
        else if (latestVersion !== currentVersion) {

          Modal.confirm({
            className: styles["versionCheckerModal"],
            icon:<></>,
            content: <Result 
              status={"info"}
              title={"New version available!"}
              subTitle={"Refresh for the best experience."}
            />,
            okText: "Refresh",
            onOk() {
              window.location.reload();
            },
          });
          return false;
        }
      } catch (error) {
        console.error('Failed to check for updates:', error);
      }
    };

    // Initial check
    checkVersion();

    const intervalId = setInterval(checkVersion, CHECK_VERSION_INTERVAL);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return <></>
};

export default VersionChecker;

