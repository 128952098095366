import React, {ReactElement, useContext, useMemo} from "react";
import { AsyncState } from "../hook/useAsyncState";
import { Quote } from "../api/models";

export interface QuoteContextType {
  quoteAsync?:AsyncState<Quote>
  userActivity?:()=>void
  adminView?:boolean
  loadQuote?:(q?:Quote)=>Promise<Quote | undefined>
  loadQuoteOnly?:()=>Promise<Quote | undefined>
  setQuoteFormValues?:(q:Quote | undefined, disableSave?: boolean)=>Promise<void>
  isLocked?: boolean
}
const QuoteContext = React.createContext<QuoteContextType>({
});

export const useQuoteContext = () : QuoteContextType => {
    return useContext(QuoteContext);
}

// Data provider component
const QuoteContextProvider = (props:{ value:QuoteContextType, children: ReactElement | ReactElement[] }) => {

  const quoteContext = useMemo(() => props.value, [
      props.value.quoteAsync,
      props.value.adminView,
    ]);

  return <QuoteContext.Provider value={quoteContext}>{props.children}</QuoteContext.Provider>;
};
  
export default QuoteContextProvider;

