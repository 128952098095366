import {
  notification,
  Button,
  Col,
  Row,
  Table,
} from "antd";
import { useContext, useEffect, useState, useMemo } from "react";
import { ConfiguratorContext, S3UrlMapContext } from "../context";
import { QuoteComment, CommentTopic } from "../api/models"
import _ from "lodash";
import { useIntl } from "react-intl";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import {  PlusOutlined, } from "@ant-design/icons";
import dayjs from "dayjs";
import {QuoteCommentRequestOptions} from "../api";
import QuoteCommentCard, {EditQuoteCommentCard} from "./QuoteCommentCard";

const NOT_FOUND = -1;

type S3Map = Record<string, string>
const CommentActivityList = (props: {
  topic: CommentTopic
  quoteId:string | undefined
} ) => {

  const { quoteId } = props;

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [editCommentId, setEditCommentId] = useState<number | undefined>();
  const [showAddComment, setShowAddComment] = useState<boolean>(false);
  const commentOptions = {
    showHidden: configurator.isAdmin(),
    topic: [ props.topic ],
  };
  const [_s3UrlMap, s3UrlMapAsync] = useAsyncState<S3Map>();
  const [quoteCommentLst, quoteCommentLstAsync] = useAsyncState<QuoteComment[]>();

  const s3UrlContext = useMemo(() => ({
    s3UrlMapAsync 
  }), [s3UrlMapAsync ]);

  const handleShowAddComment = () => {
    setEditCommentId(undefined);
    setShowAddComment(true);
  }

  const handleEditComment = (commentId:number) => {
    setEditCommentId(commentId);
    setShowAddComment(false);
  }

  useEffect(() => {
    if ( quoteId ) {
      loadS3Url(s3UrlMapAsync, commentOptions);
      loadQuoteComments(quoteCommentLstAsync, commentOptions);
    }
  }, [props.quoteId, commentOptions.showHidden, props.topic] );

  const loadS3Url = async (s3UrlMapAsync:AsyncState<S3Map>, options?:QuoteCommentRequestOptions): Promise<S3Map | undefined> => {
    if ( !quoteId ) return;

    s3UrlMapAsync.isLoading();
    try {
      const resp = await configurator.api.fetchQuoteCommentDocumentUrls( quoteId, options );
      s3UrlMapAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch document urls. " + errorMsg });
      s3UrlMapAsync.setFail(e.message);
    }
    return;
  }

  const loadQuoteComments = async (quoteCommentLstAsync:AsyncState<QuoteComment[]>, options?:QuoteCommentRequestOptions) : Promise<QuoteComment[] | undefined> => {
    if ( !quoteId ) return;

    quoteCommentLstAsync.isLoading();
    try {
      const resp = await configurator.api.fetchQuoteComments(quoteId, options);
      quoteCommentLstAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch document urls. " + errorMsg });
      quoteCommentLstAsync.setFail(e.message);
    }
    return;
  }

  const handleAddedComment = (comment:QuoteComment) => {
    const lst = [ comment ].concat( quoteCommentLst || [] );
    quoteCommentLstAsync.setDone( lst );
    setShowAddComment(false);
  }

  const handleEditedComment = (comment:QuoteComment) => {
    const ndx = quoteCommentLst?.findIndex( c => c.id === comment.id ) ?? NOT_FOUND;
    if ( ndx === NOT_FOUND ) return;

    const lst = [...(quoteCommentLst || [])];

    if ( comment.hidden ) {
      lst.splice( ndx, 1 );
    }
    else {
      lst.splice( ndx, 1, comment );
    }
    quoteCommentLstAsync.setDone( lst );
    setEditCommentId(undefined);
  }

  const datasource = quoteCommentLst?.sort((a,b) => dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1);
  const pageSize = 7;
  const pagination = ((datasource?.length || 0) > pageSize) ? { pageSize } : false; 

  return <>
    <S3UrlMapContext.Provider value={s3UrlContext}>
      <Row justify={"space-between"} style={{paddingRight: ".4rem", marginBottom: "1rem"}} align="middle">
        <Col>
        </Col>
        {!showAddComment && 
        <Col>
          <Button icon={<PlusOutlined />} shape="circle" type="primary" size="small"
            onClick={handleShowAddComment}
          />
        </Col>
        }
      </Row>

      {showAddComment &&
      <EditQuoteCommentCard 
        quoteId={quoteId}
        comment={{
          topic: props.topic,
          internal: true
        }}
        reset={showAddComment}
        onCancel={() => setShowAddComment(false)}
        onSave={handleAddedComment}
      />}

      <Table
        className="mobile-table"
        bordered={false}
        rowKey="id"
        loading={quoteCommentLstAsync.isLoading()}
        dataSource={datasource}
        pagination={pagination}
        columns={[{ render: (comment) => 
          (editCommentId === comment.id ) 
            ? <EditQuoteCommentCard comment={comment} 
              quoteId={quoteId}
              onCancel={() => setEditCommentId(undefined)}
              onSave={handleEditedComment}
            /> 
            : <QuoteCommentCard comment={comment} 
              onEdit={handleEditComment} 
            />
        }]}
      />

    </S3UrlMapContext.Provider>
  </>
}


export default CommentActivityList;
