import { EntityType, TruckDateFilterType, TruckFilter } from "../api/models";
import dayjs from "dayjs";
import { useForm } from "antd/es/form/Form";
import useDateTypeAndRangeHook from "../hook/useDateTypeAndRangeHook";
import { Button, Col, Collapse, DatePicker, Divider, Form, Input, Row, Select, Skeleton, Space, Tooltip } from "antd";
import DealerMultipleSelector from "./DealerMultipleSelector";
import SaveFilter from "./widgets/SaveFilter";
import Utils from "../util/util";
import { debounce } from "lodash";
import { AdvancedSearchConfig, QuoteSalesSelector } from "./QuoteFilterControls";

const { RangePicker } = DatePicker;

export interface Props {
    tableName: string;
    filter: TruckFilter;
    loading?: boolean;
    onFilterChange: (_values: Record<string, any>, filter: TruckFilter) => void;
}

const TruckFilterControls = (props: Props) => {
    const { filter, onFilterChange, tableName } = props;
    const loading = props.loading == undefined ? false : props.loading;

    const getDateRange = (filter: TruckFilter | undefined) => {
        if (filter?.dateFilterStart && filter?.dateFilterEnd) {
            return [dayjs(filter.dateFilterStart), dayjs(filter.dateFilterEnd)];
        }
        return undefined;
    }

    const filterWithDateRange = { ...filter, dateRange: getDateRange(filter) }

    const [form] = useForm();

    const showClearFilter = !!(Object.values(form.getFieldsValue()).filter(v => v).flat().length);

    const clearFilter = () => {
        let setObject = { ...form.getFieldsValue() };
        form.resetFields();

        Object.keys(setObject).forEach(key => {
            setObject[key] = undefined;
        });

        form.setFieldsValue(setObject);
        props.onFilterChange({}, setObject);
        // TODO
        //props.resetQueryParam?.();
    }

    const { validateDateFilterType, validateRangePicker, filterChange } = useDateTypeAndRangeHook(form, "dateFilterType", "dateRange", onFilterChange);

    return <>
        <style>
            {`
                .clear-filter-button {
                margin-top: 3px;
                border: 1px solid red !important;
                color: red !important;
                }

                .clear-filter-button:hover {
                margin-top: 3px;
                border: 1px solid red !important;
                color: white !important;
                background-color: red !important;
                }

                .custom-form-item .ant-form-item {
                margin-bottom: 15px !important;
                }
            `}
        </style>
        <Form
            form={form}
            onValuesChange={debounce(filterChange, 750)}
            initialValues={filterWithDateRange}
            layout="vertical"
            disabled={loading}
            className="custom-form-item"
        >
            <Space direction="vertical" style={{ width: '100%' }} size="middle">
                <div>
                  <Row gutter={[32, 0]}>
                    <Col xs={11} xxl={9}>
                      <Form.Item name="search">
                        <Input
                          allowClear
                          value={filter?.search}
                          placeholder="Search by serial number, quote name, customer, and more."
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={11} xxl={9}>
                      <Form.Item name="dealerLst" >
                        <DealerMultipleSelector style={{ width: "100%" }} placeholder="Filter by Dealer" />
                      </Form.Item>
                    </Col>
                    <div>
                      <SaveFilter tableName={tableName} size="small" style={{ marginTop: "3px", marginRight: "10px" }} />
                    </div>
                    <div>
                      {showClearFilter && <Tooltip title="Reset Filter"><Button size="small" onClick={clearFilter} rootClassName="clear-filter-button">Reset</Button></Tooltip>}
                    </div>

                  </Row>
                  <Row gutter={[32, 0]}>

                    <Col xs={8} xxl={5}>
                      <Form.Item
                        name="dateFilterType"
                        label="Date Filter"
                        rules={[{ validator: validateDateFilterType }]}
                      >
                        <Select
                          options={Object.values(TruckDateFilterType).map(e => ({ label: Utils.snakeCaseToFirstLetterCapitalized(e), value: e }))}
                          allowClear
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={14} xxl={10} >
                      <Form.Item
                        name="dateRange"
                        label="Date Filter Range"
                        rules={[{ validator: validateRangePicker }]}
                      >
                        <RangePicker
                          name="range"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <Collapse
                    style={{ width: '100%', marginTop: "-2rem" }}
                    {...AdvancedSearchConfig}
                    items={
                        [{
                            key: "advanced-search",
                            label: <span style={{color: "#1677FF"}}>Advanced Search</span>,
                            forceRender: true,
                            children:
                                <>
                                    <Row gutter={[32, 8]}>

                                        <Col xs={22} xxl={7}>
                                            <Form.Item
                                                name="entityTypeList"
                                                label="Customer Entity Type"
                                            >
                                                <Select
                                                    options={Object.values(EntityType).map(val => {
                                                        return {
                                                            label: Utils.snakeCaseToFirstLetterCapitalized(val),
                                                            value: String(val),
                                                        }
                                                    })}
                                                    allowClear
                                                    optionFilterProp="label"
                                                    mode={"multiple"}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={22} xxl={7}>
                                          <Form.Item
                                            name="salespersons"
                                            label="Salesperson"
                                          >
                                            <QuoteSalesSelector  style={{ width: '100%' }} />
                                          </Form.Item>
                                        </Col>

                                    </Row>
                                    <Divider/>
                                </>
                        }]
                    }
                />
            </Space>
        </Form>
    </>
};
export default TruckFilterControls;
