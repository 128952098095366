import Table, {ColumnType} from "antd/lib/table";
import TextArea from "antd/lib/input/TextArea";
import {ApprovalRequest, ChangeOrderStep, CustomOptionProcessingStatus, CustomOptionStep, CustomOptionType, OrderSubmissionStep, Quote, QuoteApprovalStep} from "../../api/models";
import {Button, Card, Form, InputNumber, Modal, Tooltip} from "antd";
import ToggleApproved from "../ToggleApproved";
import Utils from "../../util/util";
import { 
  EditOutlined,  
} from "@ant-design/icons";
import {useForm} from "antd/lib/form/Form";
import {useContext, useEffect, useState} from "react";
import { ApprovalContext } from "../../context";


const MobileCustomOptionApproval = (props:{ 
  allCustomOptions:CustomOptionType[]
  canEditCustomOption:boolean
  saveCustomOption:(opt:CustomOptionType)=>void
}) => {
  const { allCustomOptions,canEditCustomOption, saveCustomOption } = props;


  const isNullOrUndefined = ( o:any ) : boolean => {
    return o === null || o === undefined;
  }

  const canActionCustomOption = allCustomOptions.filter(co => co.processingStatus !== CustomOptionProcessingStatus.PROCESS_COMPLETED);

  const actionedCnt = canActionCustomOption.filter( c => !isNullOrUndefined( c.approved ) ).length;
 
  const columns:ColumnType<CustomOptionType>[] = [
    {
      render: (_val, rec, _ndx) => {
        return <CustomOptionCard 
          rec={rec} 
          saveCustomOption={saveCustomOption} 
          canEditCustomOption={canEditCustomOption}
        />
      },
    },
  ];

  return <>
    <div style={{fontWeight: "600", display: "flex", justifyContent: "space-between"}}>
      <div>Custom Options</div>
      <div>Actioned: {actionedCnt}/{canActionCustomOption.length}</div>
    </div>
    <Table
      className="custom-option-mobile-table mobile-table"
      dataSource={allCustomOptions}
      columns={columns}
      pagination={{pageSize: 2}}
      rowKey="id"
    />
  </>
}

const CustomOptionCard = (props:{ 
  rec: CustomOptionType
  saveCustomOption:(c:CustomOptionType)=>void
  canEditCustomOption: boolean
}) => {
  const { rec, saveCustomOption, canEditCustomOption } = props;

  const toggleApproveCustomOption = (opt:CustomOptionType, approved:boolean | null) => {
    opt.approved = approved;
    saveCustomOption( opt );
  }

  const approvalContext = useContext(ApprovalContext);

  const quote = approvalContext.quoteInApproval;

  const isCustomOptionEngineeringStep = quote?.status === CustomOptionStep.ENGINEERING_QUOTE_REVIEW;
  const isQuoteApprovalEngineeringStep = quote?.status === QuoteApprovalStep.ENGINEERING_QUOTE_APPROVAL_REVIEW || quote?.status === QuoteApprovalStep.OLD_ENGINEERING_QUOTE_APPROVAL_REVIEW;
  const isChangeOrderEngineeringStep = quote?.status === ChangeOrderStep.ENGINEERING_CHANGE_ORDER_REVIEW || quote?.status === ChangeOrderStep.OLD_ENGINEERING_CHANGE_ORDER_REVIEW;

  const isEngineeringStep = isQuoteApprovalEngineeringStep || isChangeOrderEngineeringStep || isCustomOptionEngineeringStep;

  const canActionOperation = rec.processingStatus !== CustomOptionProcessingStatus.PROCESS_COMPLETED && isEngineeringStep;

  return <>
    <Card 
      title={<>
        <div style={{display: "flex", justifyContent: "space-between", gap: ".8rem"}} >
          <div style={{width: "13rem", whiteSpace: "normal"}}>{rec.content}</div>
          {canActionOperation && <div style={{marginTop: ".2rem"}}>
            <ToggleApproved value={rec.approved} onChange={(v)=>toggleApproveCustomOption( rec, v )} />
          </div>}
        </div>
      </>} 
      size="small" >
      <div style={{display: "flex", justifyContent: "space-between"}} >
        <div style={{marginTop: ".5rem"}}> 
          <span style={{fontWeight: "500"}}>Price: </span>{Utils.formatMoney( rec.price)} 
          {rec.note &&
            <span> - <span style={{fontStyle: "italic"}}>{rec.note}</span></span>
          }
        </div>
        <div style={{marginLeft: ".3rem"}}>
        {canEditCustomOption && <EditCustomOption 
          customOption={rec}
          save={(co) => { saveCustomOption(co) } } 
          /> }
        </div>
      </div>
    </Card>
  </>

}

const EditCustomOption = (props: {
  customOption:CustomOptionType | undefined
  save:(co:CustomOptionType)=>void
}) => {
  const { customOption, save } = props;
  const [form] = useForm();


  const [open, setOpen] = useState<boolean>(false);

  const handleSaveEditCustomOption = () => {
    const values = form.getFieldsValue(true);
    const updated =  {...customOption, ...values };
    save( updated );

    setOpen( false );
  }
  const handleCancel = () => {
    setOpen( false );
  }

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(customOption);
  }, [customOption])

  return <>
    <Tooltip title="Edit" >
      <Button shape="circle" type="default" onClick={()=>setOpen(!open)} ><EditOutlined /></Button>
    </Tooltip>
    <Modal
      open={open}
      onOk={handleSaveEditCustomOption}
      onCancel={handleCancel}
      okText="Done"
      title={"Edit " + customOption?.content}
    >
      <Form form={form}>
        <Form.Item name="price" label="Price"
          rules={[ {
            message: "Price must be greater than or equal to zero.",
            validator: async (rule: any, value: string, cb: any) => {
              try {
                const n = parseFloat(value);
                if (n >= 0) return true;
              } catch (e) {}
              throw new Error("validation failed");
            }
          } ]}
        >
          <InputNumber
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value!.replace(/\$\s?|(,*)/g, '')}
            placeholder="Type dollar amount." style={{width: "calc(100% - 120px)"}}
            controls={false}
          />
        </Form.Item>
        <Form.Item name="note" label="Note" >
          <TextArea />
        </Form.Item>
        <Form.Item name="approved" label="Action" >
          <ToggleApproved/>
        </Form.Item>
      </Form>
    </Modal>
  </>
}


export default MobileCustomOptionApproval;
