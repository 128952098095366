import {Button, Collapse, Descriptions, DescriptionsProps, Modal, notification, Row, Spin, Table, Timeline} from "antd";
import dayjs from 'dayjs'
import {useCallback, useContext, useEffect, useState} from "react";
import {ApprovalChange, CustomOptionType, Quote, RevisionType, RevisionStatus, CustomOptionApprovalType, BaseCategory, ParentQuoteHistoryDto} from "../../api/models";
import { ConfiguratorContext } from "../../context";
import ApprovalDiffTable from "../Table/ApprovalDiffTable";
import { Utils } from "../../util/util";
import Title from "antd/lib/typography/Title";
import { Link } from "react-router-dom";
import { HISTORY_PANEL_KEY } from "../../pages/configurator";
import WorkflowProgress from "../WorkflowProgress";
import { CopyOutlined, PartitionOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";

interface Properties { 
  quote: Quote | undefined
  categories: BaseCategory[] | undefined;
  tabKey: string;
}
const QuoteHistoryTab = (props: Properties) => {
  const { quote, categories, tabKey } = props;
  const configurator = useContext(ConfiguratorContext);
  const [approvalChangeHistory, setApprovalChangeHistory] = useState<any[]>();
  const updatedCategories= categories;
  const intl = useIntl();
  const isAdminOrEngineeringOrSalesDesk = configurator.isAdmin() || configurator.isEngineering() || configurator.isSalesDesk();

  useEffect(() => {
    if (tabKey === HISTORY_PANEL_KEY) {
      getHistory();
    }
  }, [tabKey]);

  const translateEnumString = useCallback((str: string) => {
    if (str) {
      let strArr = str.split('_');
      if (strArr.length === 0) {
        return '';
      }
      else {
        strArr.forEach((e, i) => { strArr[i] = e[0] + e.toLowerCase().slice(1) })
        return strArr.join(' ');
      }
    }
    return '';
  }, []);

  const getHistory = async () => {
    try {
      if (quote) {
        const resp = await configurator.api.getApprovalHistory(quote.id);
        setApprovalChangeHistory(resp.data.changeDetail);
      }
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error({ message: "Failed to get history." + errorMsg });
    }
  }

  const getEventTitle = (change: ApprovalChange, idx: number) => {
    let approvalType = translateEnumString(change.approvalType);
    if (change.approvalType === RevisionType.SPLIT_ORDER) {
      approvalType = "Split";
    }
    else if (change.approvalType === RevisionType.ORDER) {
      approvalType = "Order Submission"
    }

    return (
    <div style={{marginBottom: "-2rem", marginTop: "-.7rem"}} key={`${idx}-panel`}>
      <span>{`CHANGE TYPE: ${approvalType} - ${getRevisionStr(change)}`}</span>
      <Descriptions 
        layout="horizontal"
        items={getDescriptionItem(change)}
        style={{marginBottom: "1rem"}}
        column={5}
      />
    </div>
    );
  }

  const getRevisionStr = (change: ApprovalChange): string => {
    if (change.revisionOrderBeforeChange === change.revisionOrderAfterChange) {
      return !!change.revisionOrderAfterChange ? `Change on revision: ${change.revisionOrderBeforeChange}` : "Shipment";
    }
    else {
      return `Change from Rev ${change.revisionOrderBeforeChange} to Rev ${change.revisionOrderAfterChange}`;
    }
  };

  const getRequestedAtStr = (change: ApprovalChange): string => {
    return dayjs(
      change.requestedAt ? change.requestedAt : ''
    ).format("M/DD/YY, h:mm a");
  };

  const getPriceDiffStr = (change: ApprovalChange): string => {

    if (change.approvalType === RevisionType.ENGINEERING_CHANGE || 
        change.approvalType === CustomOptionApprovalType.APPLY_CUSTOM_OPTION ||
        change.approvalType === CustomOptionApprovalType.DISMISS_CUSTOM_OPTION || 
        change.priceProtected) return "Price Protected";

    else if (change.approvalStatus === RevisionStatus.REJECTED) {
      return "NA";
    }

    const priceDiff = change?.diff?.priceDiff;
    if (!priceDiff?.before || !priceDiff?.after) return "NA";
    const oldValue = priceDiff?.before.replace(/,/g, '');
    const newValue = priceDiff?.after.replace(/,/g, '');
    return Utils.formatMoneyWithSign(Number(newValue) - Number(oldValue));
  };

  const getEngineeringStatus = (change: ApprovalChange) => {
    switch (change.approvalStatus) {
      case RevisionStatus.APPROVED: {
        return <p style={{color: 'green', fontWeight: "bold"}}>{Utils.snakeCaseToFirstLetterCapitalized(change.approvalStatus)}</p>
      }
      case RevisionStatus.REJECTED: {
        return <p style={{color: 'red', fontWeight: "bold"}}>{Utils.snakeCaseToFirstLetterCapitalized(change.approvalStatus)}</p> 
      }
      case RevisionStatus.PENDING_APPROVAL:
      case RevisionStatus.DRAFT: {
        return <p style={{color: 'blue', fontWeight: "bold"}}>{Utils.snakeCaseToFirstLetterCapitalized(change.approvalStatus)}</p> 
      }
      default: {
        return <p></p>
      }
    }
  }

  const getDescriptionItem = (change: ApprovalChange): DescriptionsProps['items'] => {

    let items = [
      {
        key: "requestedBy",
        label: "Requested By",
        children: change.requestedBy,
      },
      {
        key: "requestedAt",
        label: "Requested At",
        children: getRequestedAtStr(change),
      },
      {
        key: "epicorRev",
        label: "Epicor Rev",
        children: change.afterChangeEpicorRev || "-",
      },
      {
        key: "priceChange",
        label: "Total Price Change",
        children: getPriceDiffStr(change),
      },
    ];

    return items;
  } 

  const hidePrice = (change: ApprovalChange): boolean => {
    return change?.approvalType === RevisionType.ENGINEERING_CHANGE;
  }

  const getHistoryItem = (change: ApprovalChange, idx: number) => {

    let items = [
      {
        key: "change-" + change.revisionOrderAfterChange + "-" + change.revisionOrderAfterChange,
        label: getEventTitle(change, idx),
        children: 
        <>
          {(change.approvalType === RevisionType.ENGINEERING_CHANGE || change.approvalType === RevisionType.CANCEL_ORDER) && 
            <>
              {getEngineeringStatus(change)}
              {change.changeSummary && 
                <div key="changeSummary">
                  <Title level={5}>{`${change.approvalType === RevisionType.ENGINEERING_CHANGE ? "Change Summary" : "Cancellation Message"}:` }</Title>
                  <p>{change.changeSummary}</p>
                </div>
              }
            </>
          }
          {!!change?.partners?.length && <Title level={5}>The other quote in this split change:
            {change.partners?.map(partner => <Link style={{marginLeft:"5px", marginRight: "5px"}} to={"/configurator/" + encodeURI(partner)}>{partner}</Link>)}
          </Title>}
          {change.approvalType === RevisionType.CHANGE_ORDER && 
            <>
              {change.dealerRequest &&
                <div key="dealerRequest">
                  <Title level={5}>Dealer/Sales Change Request: </Title>
                  <p>{change.dealerRequest}</p>
                </div>
              }
            </>
          }
          {change?.approvals && 
            <WorkflowProgress
              workflow={change?.approvals}
            />}
          <br/>
          {change.diff && <ApprovalDiffTable diff={change.diff} hidePriceChange={hidePrice(change)}/>}
          {change?.customOptions && change?.customOptions.length != 0 &&
            <CustomOptionTable 
              customOptions={change.customOptions}
              categories={updatedCategories}
              translateEnumString={(value: string) => translateEnumString(value)}
              approvalType={change.approvalType}
            />
          }
        </>
      },
    ];

    return (
      <Collapse style={{marginBottom: ".6rem"}} key="history" className="history-collapse"  bordered={false} items={items} />
    );
  }

  return (
    <>
      <style>
        {`
          .history-workflow ant-steps-item-content {
            text-wrap: wrap;
            max-width: 120px;
          }
          .history-collapse {
            border: 2px solid #1890ff;
            background-color: white;
          }
          .history-collapse .ant-collapse-header .ant-collapse-arrow svg {
            color: darkgrey;
            fill: #1677ff;
          }
        `}
      </style>
      {isAdminOrEngineeringOrSalesDesk && <Row><ParentQuoteHistory quote={quote} canAccess={isAdminOrEngineeringOrSalesDesk} style={{marginBottom: "1rem"}}/></Row>}
      {!approvalChangeHistory && <Spin/>}
      {approvalChangeHistory?.map((change: ApprovalChange, idx: number) => 
        <div key={"div-" + idx}>
          {getHistoryItem(change, idx)}
        </div>
      )}
    </>
  );
}

export default QuoteHistoryTab;

export const CustomOptionTable = (props: {customOptions: CustomOptionType[] | undefined, categories: BaseCategory[] | undefined, translateEnumString: (value: string) => string, approvalType: string}) => {

  const {customOptions, categories, approvalType} = props;
  const APPLY_CUSTOM_OPTION = "APPLY_CUSTOM_OPTION";
  const DISMISS_CUSTOM_OPTION = "DISMISS_CUSTOM_OPTION";

  const basicColumns = [
    {
      title: 'Custom option content',
      dataIndex: 'content',
      width: '40%',
    },
    {
      title: 'MSRP',
      dataIndex: 'price',
      width: '10%',
      render: (price: number, _record: CustomOptionType, _index: any) => {
        return Utils.formatMoney(price)
      }
    },
    {
      title: 'Note',
      dataIndex: 'note',
      width: '10%',
    },
    {
      title: 'Category',
      dataIndex: 'categoryId',
      width: '10%',
      render: (_param: any, record: CustomOptionType, _index: any) => {
        return <p>{Utils.stripSortingPrefix(categories?.find(cat => cat.id === record.category?.id)?.name || "")}</p>
      }
    },
    {
      title: 'Lead Time (wk.)',
      dataIndex: 'designAndProcurementWeeks',
      width: '13%',
      render: (_, record: CustomOptionType, _index: any) => Utils.getEngineeringLeadTime(record, categories || [])
    },
    {
      title: 'Status',
      dataIndex: 'processingStatus',
      width: '10%',
      render: (param: any, _record: CustomOptionType, _index: any) => {
        return <p>{props.translateEnumString(param || "")}</p>
      }
    },
  ]


  const columns = basicColumns.splice(0, basicColumns.length - 1).concat(
    (approvalType === APPLY_CUSTOM_OPTION || approvalType === DISMISS_CUSTOM_OPTION) ? 
    [
      {
        title: 'Applied / Dismissed',
        dataIndex: 'applyStatus',
        width: '10%',
        render: (param: any, _record: CustomOptionType, _index: any) => {
          return <p>{props.translateEnumString(param || "")}</p>
        }
      },
      {
        title: 'Applied Assembleis',
        dataIndex: 'appliedAssemblies',
        width: '10%',
        render: (param: any, _record: CustomOptionType, _index: any) => {
          return <p>{param || ""}</p>
        }
      },
    ] : []);

  return (
    <div key="customOptionTable">
      <p><strong>Custom Options</strong> {customOptions?.[0].applyStatus}</p>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={customOptions}
        bordered
        pagination={false}
      />
    </div>
  );
}

const ParentQuoteHistory = (props: {quote: Quote | undefined, canAccess: boolean,  style: any}) => {

  const {quote, ...buttonProps} = props;
  const configurator = useContext(ConfiguratorContext);
  const [open, setOpen] = useState<boolean>(false);
  const [quoteHistory, setQuoteHistory] = useState<ParentQuoteHistoryDto[]>([]);
  const intl = useIntl();
  const isAdminOrEngineeringOrSalesDesk = configurator.isAdmin() || configurator.isEngineering() || configurator.isSalesDesk();

  const getParentHistory = async () => {
    if (!quote?.id) return;
    setOpen(true);
    try {
      const resp = await configurator.api.getParentQuotes(quote.id);
      setQuoteHistory(resp.data);
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get parent history " + errorMsg });
    }
  }

  const getItemTitle = (qh: ParentQuoteHistoryDto): string => {
    return qh.quoteName + `, ${qh.quoteId} (Rev: ${qh.rev})`;
  }


  return (<>
      <Button {...buttonProps} type="primary" onClick={getParentHistory}>Get Parent History</Button>
      <Modal
        title="Quote Parent History"
        open={open}
        onCancel={() => setOpen(false)}
        footer={[]}
      >
        {!!quoteHistory.length ? 
        <Timeline
          mode="left"
          style={{marginTop: "4rem", marginLeft: "-24rem"}}
          items={
            quoteHistory.map(qh => {return {
              key: qh.id,
              color: qh.type === "copy" ? "blue" : "green",
              label: qh.type.toLocaleUpperCase(),
              children: 
              <>
                {isAdminOrEngineeringOrSalesDesk ? 
                <Link to={"/configurator/" + encodeURI(qh.quoteId)} target="_blank">{getItemTitle(qh)}</Link>
                :
                <span key={qh.id + "-child"} style={{marginBottom: "2rem"}}>{getItemTitle(qh)}</span>}
              </>,
              dot: qh.type === "copy" ? <CopyOutlined style={{fontSize: "20px"}}/> : <PartitionOutlined style={{fontSize: "20px"}}/>
            }})
          }
        />
      : <span>There's no parent history to this quote.</span>
      }
      </Modal>
  </>);
}


