import { Button, ButtonProps, Checkbox, Form, Modal, Row, notification } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { getCSVRow } from "../../helpers/csv";
import { useContext, useState } from "react";
import CategorySelector from "../category_selector";
import {useForm} from "antd/es/form/Form";
import {useIntl} from "react-intl";
import {ConfiguratorContext} from "../../context";
import {PAGINATION_MAX_PAGE_SIZE, QuoteFilter, SortDirection} from "../../api/models";
import dayjs from "dayjs";
import {BaseQuoteSort} from "../../pages/quotes";
import Utils from "../../util/util";
import SaveOption from "../widgets/SaveOption";

export interface ExportOptions {
    categoryIds?: number[];
    rowPerUnit?: boolean;
}

const ExportQuotesModal = (props: ButtonProps & {
  filter: QuoteFilter;
  sort: BaseQuoteSort;
  modulename?: string;
}) => {
  const { sort, filter, ...btnProps} = props;
  const [form] = useForm();

  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const exportQuotes = async (exportOptions: ExportOptions) => {
    try {
      const sorter = [sort].flat().map( sorter => ({
        field: sorter.columnKey?.toString() || "updatedAt",
        direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
      }));

      setIsExporting(true);
      const resp = (await configurator.api.listQuotes({
        ...filter,
        page: 0,
        size: PAGINATION_MAX_PAGE_SIZE,
        sort: sorter,
        selectionCategories: exportOptions.categoryIds?.length ? exportOptions.categoryIds : undefined,
        includeSelectionSummary: true,
        rowPerUnit: exportOptions.rowPerUnit,
      })).data;

      const categories = Object.keys(resp.content.reduce((categories, quote) => {
        //todo - fetch separately from quote
        Object.keys(quote.selectionSummary).forEach(key => {
          if (!(key in categories)) categories[key] = true;
        });
        return categories;
      }, {}));

      const truckColumns = exportOptions?.rowPerUnit ? ['Unit Number', 'VIN', 'Ship Date', 'Truck Production Date', 'Engine Serial', 'Transmission Serial'] : [];
      const quoteColumns = ['Name', 'Quote ID', 'Status', 'Part Number','Serial Number', 'Quantity', 'Production Date', 'Model', 'Last Updated', 
        'Sales Person', 'Dealer', 'Customer', 'Shipping Destination', 'Dealer Price'];
      const csv = [getCSVRow(truckColumns.concat(quoteColumns).concat(categories))]

      resp.content.forEach((quote) => {

        const quoteData = [
          quote.name,
          quote.quoteId,
          Utils.formatQuoteStatusStr(intl, quote) || "", 
          quote.partNumberString || '',
          quote.serialNumberStr || '',
          String(quote.quantity),
          quote.productionDate ? dayjs(quote.productionDate).format("MM-DD-YYYY") : '',
          quote.model.name,
          dayjs(quote.updatedAt).format("MM-DD-YYYY"),
          quote.owner?.name || '',
          quote.owner?.dealerName || '',
          quote.endCustomer?.name || '',
          quote.shippingDestination?.name || '',
          (!!quote.dealerPrice)? String(quote.dealerPrice) || '' : '',
        ]
        
        const selectionData = categories.map(category => {
          const s = quote.selectionSummary[category];
          if (s) {
            return s.map(asm => asm.bom + ' ' + asm.label).join(',');
          }
          else {
            return '';
          }
        });

        const baseRow = quoteData.concat(selectionData);

        if(exportOptions?.rowPerUnit) {
          quote.trucks?.forEach(t => {
            const truckData = [t.truckSerialNumberStr, t.vin || '', String(t.shipDate) || '', String(t.productionDate) || '', t.engineSerialNumber || '', t.transmissionSerialNumber || ''];
            csv.push(getCSVRow(truckData.concat(baseRow)));
          });
        }
        else {
          csv.push(getCSVRow(baseRow));
        }
      });
      var blob = new Blob([csv.join('\n')], { type: 'text/csv;charset=utf-8' });
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'quote-export-' + (new Date()) + '.csv';
      document.body.appendChild(a);
      a.click();

    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Export failed. " + errorMsg });
    }

    setIsExporting(false);
  };

  const onOk = async () => {

    const values = await form.validateFields() as ExportOptions;

    await exportQuotes(values);

    notification['success']({
      message: 'Export successful',
      description: 'Quote listing exported.'
    });

    setIsOpen(false);

  };

  return <>
    <Button type="primary" {...btnProps} loading={isExporting} onClick={() => setIsOpen(true)}>Export</Button>
    <Modal 
      title="Export Quotes CSV"
      open={isOpen}
      okText="Export" 
      confirmLoading={isExporting}
      onOk={onOk} 
      onCancel={() => setIsOpen(false)}
    >
      <Form
        form={form}
        initialValues={{rowPerUnit:false}}
      >
        <Row>
          <Paragraph>Choose a list of categories below to include selections from in the CSV. By default, all selections will be included.</Paragraph>
          <Form.Item
            name="categoryIds"
            label="Only Include Categories"
            style={{width: "100%"}}
            labelCol={{ span: 24 }}
          >
            <CategorySelector mode="multiple" />
          </Form.Item>
        </Row>

        <Row align="middle">
          <Form.Item
            name="rowPerUnit"
            label="Row per Unit"
            valuePropName="checked"
          >
           <Checkbox/> 
          </Form.Item>
        </Row>
      </Form>
      {props.modulename && <SaveOption 
        includeSelectionSummary={true}
        moduleName={props.modulename}
        form={form}
        size="small"
      />}
    </Modal >
  </>
};

export default ExportQuotesModal;
