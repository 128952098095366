import {Button, Tabs, Image, notification, Row, Col, Form, Divider, Input, Checkbox } from "antd";
import {useForm} from "antd/es/form/Form";
import Table, {ColumnType, TablePaginationConfig} from "antd/es/table";
import {SorterResult} from "antd/es/table/interface";
import _ from "lodash";
import {debounce} from "lodash";
import {useCallback, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {ListModelsRequest} from "../../api";
import {ModelInfo, CabStyle, FuelType, FuelTypes, SortDirection, CabFuelImageLst, FuelTypeColor, CategoryIdAssembliesIdMap} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import { useQuoteContext } from "../../contexts/QuoteContext";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import {useModelSelection} from "../../hook/useModelInfo";
import BMButton, {BMButtonProps} from "../BMButton";
import IncentiveProgramSelector from "../incentive_program_selector";
import ModalWizard from "../ModalWizard";

type ModelInfoSort = SorterResult<ModelInfo> | SorterResult<ModelInfo>[]
type ModelFilter = Omit<ListModelsRequest, 'page' | 'size' | 'sort'> ;

const DEFAULT_PAGE_SIZE = 4;


export type SelectedModelInfo =  {
  modelInfo:ModelInfo
  selections:CategoryIdAssembliesIdMap
  modelYear:number | undefined
}
const ModelSelectionWizard = (props: Omit<BMButtonProps, "id" | "value" | "onChange"> & {
  id?:string
  value?: SelectedModelInfo
  onChange?: (v: SelectedModelInfo | undefined) => void
  open?:boolean
}) => {


  const { id:a, value:b, onChange:c, open:d, ...btnProps } = props;

  const [toggleOpen, setToggleOpen] = useState<boolean>();
  const {adminView} = useQuoteContext();

  const [selectedModelType, setSelectedModelType] = useState<ModelTypeSelectorValue | undefined>();
  const [selectedModel, setSelectedModel] = useState<ModelInfo | undefined>();
  const [modelSelection] = useModelSelection();
  const [_modelOptions, modelOptionsAsync] = useAsyncState<CategoryIdAssembliesIdMap>();
  const intl = useIntl();


  const isOpen = toggleOpen ?? props.open;

  const handleOpen = () => {
    setToggleOpen(true)
  }
  const handleCancel = () => {
    setToggleOpen(false)
  }

  const handleDone = async () => {
    if ( !selectedModel ) return;

    modelOptionsAsync.setLoading();

    try {
      const options = await modelSelection.fetch( selectedModel.id, selectedModelType?.fuelType, selectedModelType?.cabStyle );
      if ( !options ) {
        const errorMsg = "No model options found.";
        notification.error( { message: errorMsg });
        modelOptionsAsync.setFail(errorMsg);
        return;
      }

      modelOptionsAsync.setDone(options);

      props.onChange?.({
        modelInfo: selectedModel,
        modelYear: props.value?.modelYear,
        selections:options 
      });

      setToggleOpen(false)
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load model options. " + errorMsg });
      modelOptionsAsync.setFail(e.message);
    };
  }

  const handleSelectedModel = (model:ModelInfo | undefined) => {
    if ( model?.id === selectedModel?.id ) return;

    setSelectedModel(model);
  }

  const btnLbl =  ( props.value?.modelInfo.name ) 
    ? [props.value?.modelYear, props.value?.modelInfo.name ].join(" " )
    : "Add Truck Model";

  //hide border when disabled
  const btnStyle =  ( props.disabled )
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

  return <>
    <BMButton type="text"
      className="ghostBmButton"
      {...btnProps}
      onClick={handleOpen}
      style={{padding: "0"}}
    ><span style={btnStyle}>{btnLbl}</span></BMButton>
    <ModalWizard
      showSteps={false}
      style={{minWidth: "40rem"}}
      open={isOpen}
      onCancel={handleCancel}
      afterOpenChange={() => {

        modelOptionsAsync.setInit();

        Promise.all([
          modelSelection.fetchCabStyle( props.value?.selections ),
          modelSelection.fetchFuelType( props.value?.selections )])
          .then( ([ cabStyle, fuelType ]) => {
            const modelSelectionType = {
              cabStyle,
              fuelType
            };

            setSelectedModelType(modelSelectionType );
          });
      }}
      steps={[ 
        {
          key:1,
          body: (nav) => <div key="ModelTypeSelector">
              <div style={{marginTop: "1rem"}} >
                <ModelTypeSelector value={selectedModelType} 
                  onChange={(v) => {
                    setSelectedModelType({
                      fuelType: v?.fuelType,
                      cabStyle: v?.cabStyle,
                    });
                    setSelectedModel(undefined);
                    if ( v?.fuelType && v?.cabStyle ) {
                      nav?.nextStep();
                    }
                  }}  />
              </div>
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={nav?.nextStep}>Next</Button>
            <Button key="cancel" onClick={handleCancel}>Cancel</Button>
          </div>
        },
        {
          key:2,
          body: (_nav) => <FilteredSelectModelTable key="FilteredSelectModelTable" 
            cabStyle={selectedModelType?.cabStyle}
            fuelType={selectedModelType?.fuelType}
            adminView={adminView} 
            value={selectedModel}
            onChange={handleSelectedModel} />,
            footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="next" type="primary" disabled={!selectedModel} onClick={handleDone} loading={modelOptionsAsync.isLoading()} >Done</Button>
              <Button key="back" onClick={nav?.prevStep} disabled={modelOptionsAsync.isLoading()} >Back</Button>
            </div>
        }

      ]} 
    />
  </>
}

interface ModelTypeSelectorValue {
  cabStyle: CabStyle | undefined 
  fuelType: FuelType | undefined
}
const ModelTypeSelector = (props:{
  id?:string
  value?: ModelTypeSelectorValue
  onChange?: (v:ModelTypeSelectorValue | undefined) => void
}) => {

  const [activeFuelType, setActiveFuelType] = useState<FuelType |undefined>();
  const handleSelectModelType = ( mt:CabStyle | undefined, ft:FuelType | undefined) => {
    setActiveFuelType(ft);
    props.onChange?.( {
      cabStyle: mt,
      fuelType: ft
    });
  }

  return <>
    <style>
      {`
        .fuelTypeTab.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn
        {
          color:white;
        }
        .fuelTypeTab [data-node-key="BEV"]:hover,  
        .fuelTypeTab [data-node-key="BEV"].ant-tabs-tab-active
        {
          color:white !important;
          background: #40b8e4 !important;
        }
        .fuelTypeTab [data-node-key="DIESEL"]:hover, 
        .fuelTypeTab [data-node-key="DIESEL"].ant-tabs-tab-active
        {
          color:white !important;
          background: #ed1c24 !important;
        }
        .fuelTypeTab [data-node-key="CNG"]:hover, 
        .fuelTypeTab [data-node-key="CNG"].ant-tabs-tab-active
        {
          color:white !important;
          background: #23C01B !important;
        }
        `}
    </style>
    <Tabs tabPosition="left" className="fuelTypeTab" activeKey={(activeFuelType || props.value?.fuelType)?.name.toUpperCase()}
      onChange={(key) => setActiveFuelType( FuelTypes[key] )}
      items={Object.values( FuelTypes ).map( ft => ({ 
        key: ft.name.toUpperCase(), 
        label:ft.description,
        children: <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly", alignItems: "bottom",}}>
          {CabFuelImageLst?.filter( cfi => cfi.fuelType === ft )
            .map( cfi => {
              const ct = cfi.cabStyle;
              const selectedStyle = (ct.name === props.value?.cabStyle?.name && ft.name === props.value?.fuelType?.name) ? {
                borderColor: FuelTypeColor[ft.valueText.toUpperCase()],
                borderStyle: "solid",
                borderRadius: "10px",
                borderWidth: "4px",
                padding: "4px"
              } : undefined;
              return <div
                key={ [ct.name, ft.name].join("-")}  
                style={{width: "120px", marginRight: "2rem", textAlign:"center"}}
              >
              <Button 
                type="text" 
                className="ghostBmButton" 
                style={{height:"140px"}}
                onClick={() => handleSelectModelType( ct, ft )}>
                <Image src={cfi.imageUri} preview={false} width={120} style={selectedStyle} />
                <div >{ct.description}</div>
              </Button>
              </div>})}
        </div>
      }))} />
    </>
}


const FilteredSelectModelTable = (props:{
  id?:string
  value?: ModelInfo
  cabStyle?: CabStyle
  fuelType?: FuelType
  adminView: boolean | undefined
  onChange?: (v: ModelInfo | undefined) => void
}) => {

  const [form] = useForm();
  const [filter, setFilter] = useState<ModelFilter | undefined>();

  const updateFilter = (values:any) => {
        setFilter({
          ...values,
          cabStyles: props.cabStyle ? [props.cabStyle?.name] : undefined,
          fuelTypes: props.fuelType ? [props.fuelType?.name] : undefined,
        });
  }

  useEffect(() => {
    updateFilter(filter);
  }, [props.cabStyle, props.fuelType]);

  return <>
    <Form 
      form={form}
      layout="vertical"
      onValuesChange={(_changed, values) => {
        updateFilter(values);
      }}
      labelAlign="right"
    >
      <Row justify="space-between">

        <Col span={8}>

          <Form.Item name="search" label="Search" >
            <Input className="modelFilter" placeholder="Search Models" allowClear />
          </Form.Item>

          <Form.Item
            name="incentivePrograms"
            label="Incentive Program"
          >
            <IncentiveProgramSelector placeholder="Filter By Incentive" />
          </Form.Item>

          <Form.Item
            name="inactive"
            valuePropName="checked"
            hidden={!props.adminView}
          >
            <Checkbox>Show Inactive</Checkbox>
          </Form.Item>
        </Col>

        <Col>
          <Divider type="vertical" style={{height: "100%", borderWidth: "2px"}} />
        </Col>

        <Col span={15}>
            <SelectModelTable filter={filter} value={props.value} onChange={props.onChange} />
        </Col>

      </Row>
    </Form>
  </>;

}


const SelectModelTable = (props:{
  id?:string
  value?: ModelInfo
  filter?: ModelFilter
  onChange?: (v: ModelInfo | undefined) => void
}) => {

  const selectedModelId = props.value?.id; 

  const [pagination, setPagination] = useState<TablePaginationConfig>({ pageSize: DEFAULT_PAGE_SIZE, defaultCurrent: 1 });
  const [modelLst, modelLstAsync] = useAsyncState<ModelInfo[]>();
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  useEffect(() => {
    setPagination({ pageSize: DEFAULT_PAGE_SIZE, defaultCurrent: 1 })
  }, [props.filter?.cabStyles, props.filter?.fuelTypes]);

  useEffect(() => {

    //reduce unnecessary loads by requiring fuel types
    if ( props.filter?.cabStyles && props.filter.fuelTypes ) {

      const sort = { field: 'name', direction: 'asc' };
      loadModels(modelLstAsync, pagination, props.filter, sort);
    }
  }, [pagination.pageSize, pagination.current, props.filter]);

  const loadModels = useCallback(debounce( async (modelLstAsync:AsyncState<ModelInfo[]>, pagination: TablePaginationConfig, filter: ModelFilter | undefined, sorter:ModelInfoSort) => {
    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || "name",
      direction: ( sorter.order === 'descend' ? 'desc' : 'asc') as SortDirection,
    }));

    modelLstAsync.setLoading();

    try {

      const resp = await configurator.api.listModels( {
        ...filter,
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        sort,
      });
      modelLstAsync.setDone(resp.data.content);
      setPagination({ ...pagination, total: resp.data.totalElements });
      return resp.data.content;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load models. " + errorMsg });
      modelLstAsync.setFail(e.message);
    };

    return;
  }, 800), []);


  const columns:ColumnType<ModelInfo>[] = [{
    dataIndex: "name",
    title: "Name"
  }];

  const handleSelectRow = (record:ModelInfo) => {

    //toggle previously selected
    if ( selectedModelId === record.id ) {
      props.onChange?.(undefined);
      return;
    }

    props.onChange?.(record);
  }

  return <>
    <style>
      {`
        .modelLst .ant-table-content { padding: 5px; } /* don't clip corners */
          .modelLst .ant-table-cell { border: none !important; } /* remove table cell borders */
          /* add error border to table */
          .ant-form-item-has-error .modelLst .ant-table-content {
          border: solid 1px #ff4d4f;
          border-radius: 15px;
        }
        `}
    </style>

    <Table 
      className="modelLst"
      showHeader={false}
      columns={columns}
      rowKey="id"
      dataSource={modelLst}
      loading={modelLstAsync.isLoading()}
      pagination={pagination}
      onChange={setPagination}
      onRow={(record, _rowIndex) => {
        return {
          onClick: () => handleSelectRow(record)
        };
      }}
      rowSelection={{
        type: "radio",
        onSelect: handleSelectRow,
        selectedRowKeys: [...(selectedModelId ? [selectedModelId] : [])],
      }}
    />

  </>
}


export default ModelSelectionWizard;
