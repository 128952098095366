import {Button, Form, Input, InputNumber, Modal, notification, Row, Space, Spin, Upload} from "antd";
import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {PoNumber} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import {useForm} from 'antd/es/form/Form';
import type { UploadFile, UploadProps } from 'antd/es/upload';
import { PlusOutlined, ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import BMButton, {BMButtonProps} from "../BMButton";
import {useAsyncState} from "../../hook/useAsyncState";
import { useQuoteContext } from "../../contexts/QuoteContext";


interface PoDocumentAsset {
  id?  : number
  name  : string
  type : string
  url?  : string | undefined
  status: string
  assetUri: string
}
type PoNumberForm = Omit<PoNumber, "documents"> & { 
  documents?: PoDocumentAsset[] 
}

type PoNumberEditorProps =  Omit<BMButtonProps, "id" | "value" | "onChange"> & {
  id?:string
  value?: PoNumber
  onChange?: (v: PoNumber | undefined) => void
  totalPrice: number | undefined
};

const PoNumberEditor = (props: PoNumberEditorProps) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>();
  const { quoteAsync } = useQuoteContext();
  const quote = quoteAsync?.val;
  const [s3UrlMap, s3UrlMapAsync] = useAsyncState<Record<string, string>>();

  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
  }, [ s3UrlMapAsync?.val ] );

  const loadS3Url = async () => {
    if ( !quote ) return;
    const poNumberId = props.value?.id;
    if ( !poNumberId ) return;


    s3UrlMapAsync.isLoading();
    try {
      const resp = await configurator.api.fetchQuotePoDocumentUrls( poNumberId );
      s3UrlMapAsync.setDone(resp.data);
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch document urls. " + errorMsg });
      s3UrlMapAsync.setFail(e.message);
    }
  }

  //convert poNumber to form format
  const poNumberForm:PoNumberForm = {
    ...props.value,
    documents: props.value?.documents?.map( d => ({
      id: d.id,
      assetUri: d.assetUri,
      name: d.filename,
      type: d.contentType,
      status: 'done',
      downloadUrl: s3UrlMap?.[ d.assetUri ],
      url: s3UrlMap?.[ d.assetUri ],
    }))
  }

  const uploadDocumentUrl = quote?.quoteId && configurator.api.getUploadPoDocumentUrl(quote?.quoteId);

  const handleUploadChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    return newFileList;
  }

  const handleRefreshAmount = () => {
    form.setFieldValue("amount", props.totalPrice );
  }

  const handleDownloadDocument = (file:UploadFile) => {
    const document = file.response || file;
    if ( document.url ) {
      configurator.api.downloadFile( document.url, {
        mimeType: document.type,
        filename: document.name
      })
    }
  }

  const getUpdatedPoNumber = ():PoNumber => {

    var allValues = form.getFieldsValue(true);
    return { ...allValues, 
      amount: allValues.amount || undefined,  //fix issue with null amount
      documents: allValues.documents?.map( (d:Record<string, any>) => d.response ? d.response : d ) 
      .map( (d:PoDocumentAsset) => ({
        id: d.id,
        assetUri: d.assetUri,
        filename: d.name,
        contentType: d.type,
      }) ) 
    }
  }


  const handleOpen = async () => {

    //reset ui
    setErrorMessage(undefined);

    setIsOpen(true)

    await loadS3Url();
  }

  const handleSave = async () => {
    if ( !quote) return;

    const updatedPoNumber = getUpdatedPoNumber();
    try {
      quoteAsync.setLoading();
      const resp = await configurator.api.savePoNumber(quote.displayRevisionId, updatedPoNumber);
      props.onChange?.(resp.data.poNumber);
      quoteAsync.setDone(resp.data);
      setIsOpen(false);
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update Po Number. " + errorMsg });
      quoteAsync.setFail(e.message);
    }
  }

  const handleDelete = async () => {
    if ( !quote) return;
    if ( !props.value?.id) return;

    try {
      quoteAsync.setLoading();
      const resp = await configurator.api.deletePoNumber(props.value?.id);
      quoteAsync.setDone(resp.data);
      props.onChange?.(undefined);
      setIsOpen(false);
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update Po Number. " + errorMsg });
      quoteAsync.setFail(e.message);
    }
  }


  const handleCancel = () => {
    setIsOpen(false);
  }

  const errMsg = errorMessage && `An error occured: ${intl.formatMessage({ id: errorMessage })}`;

  const btnLbl =  ( poNumberForm?.poNumber ) ? poNumberForm.poNumber
    : ( poNumberForm?.documents?.length || poNumberForm.amount ) ? "Edit PO Number"
    : props.disabled ? "None"
    : "Add PO Number";


  //hide border when disabled
  const btnStyle =  ( props.disabled )
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

  //remove other props for button props
  const { id, value, onChange, totalPrice, ...btnProps } = props;

  return <>
    <BMButton type="text"
      className="ghostBmButton"
      {...btnProps}
      data-testid="poNumber-button"
      onClick={handleOpen}
      style={{padding: "0"}}
    ><span style={btnStyle}>{btnLbl}</span></BMButton>

    <Modal
      forceRender
      title={poNumberForm.poNumber ? "Edit PO Number" : "Add PO Number"}
      open={isOpen}
      onCancel={handleCancel}
      footer={ <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button key="delete" danger onClick={handleDelete} disabled={quoteAsync?.isLoading() || !props.value?.id}>Remove</Button>

        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Button key="cancel" onClick={handleCancel} disabled={quoteAsync?.isLoading()}>Cancel</Button>
          <Button key="done" type="primary" onClick={handleSave} disabled={quoteAsync?.isLoading()} >Save</Button>
        </div>
        </div>
      }
    >
      <Spin spinning={quoteAsync?.isLoading() || s3UrlMapAsync.isLoading() }>

        <Form form={form} 
          initialValues={poNumberForm}
          labelAlign="right"
        >

          <Form.Item
            name="id"
            hidden
          >
            <Input type="hidden" />
          </Form.Item>


          <Space direction="vertical">

            <Row>
              <Form.Item
                label="PO Number"
                name="poNumber"
                labelCol={{span: 10}}
                style={{width: "13rem"}}
              >
                <Input />
              </Form.Item>
            </Row>

            <Row>
              <Form.Item
                label="Amount"
                name="amount"
                labelCol={{span: 10}}
                style={{width: "13rem"}}
              >
                <InputNumber
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                  placeholder="Type dollar amount."
                  controls={false}
                />
              </Form.Item>
              <Button type="text" style={{marginLeft: "-2rem"}} icon={<ReloadOutlined />} title="Refresh with latest price." 
                data-testid="poNumber-refresh-amount-btn"
                onClick={handleRefreshAmount}></Button>
            </Row>

            <Row>
              <Title level={5}>Documents</Title>

              <Form.Item
                name="documents"
                getValueFromEvent={handleUploadChange}
                valuePropName="fileList"
                noStyle
              >
                <Upload
                  action={uploadDocumentUrl}
                  name="document"
                  listType="picture-card"
                  withCredentials={true}
                  showUploadList={ {
                    showDownloadIcon: true,
                    downloadIcon: <DownloadOutlined />,
                    showPreviewIcon: false,
                    showRemoveIcon: true,
                  }}
                  onDownload={handleDownloadDocument}
                  isImageUrl={(f) => !!f.type?.startsWith("image")  }
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>
              </Form.Item>

            </Row>

            <Row>
              <div style={{color: "red", maxHeight: "5.5rem", overflow:"auto", whiteSpace:"pre-line" }}>{errMsg}</div>
            </Row>

          </Space>

        </Form>
      </Spin>
    </Modal>
  </>;

}

export default PoNumberEditor;
