import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Form,
  FormListFieldData,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Result,
  Row,
  Select,
  Spin,
  Tabs,
  Upload,
} from "antd";
import Title from "antd/lib/typography/Title";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { PlusCircleOutlined, DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import { ConfiguratorContext } from "../context";
import {
  UploadOutlined,
  DownloadOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Operation, Category, NON_DISCOUNTED_TYPE, Permission, DEFAULT_CATEGORY_ID, User, CategoryMetadata, EngineeringTeamInfo } from "../api/models";
import {useForm} from "antd/lib/form/Form";
import {useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import {StringParam, useQueryParam} from "use-query-params";
import DealerMultipleSelector from "../components/DealerMultipleSelector";
import {closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import BMReadOnly from "../components/BMReadOnly";
import SelectEngineeringTeamsButtonModal from "../components/Quote/SelectEngineeringTeamsButtonModal";
import UserSingleSelector from "../components/widgets/UserSingleSelector";
import { UpdateCategoryRequest } from "../api";

enum UPLOADING_STATUS {
  INIT = "init",
  ERROR = "error",
  DONE = "done",
  UPLOADING = "uploading"
}
enum FIELD_TYPES  {
  TEXT = "text", 
  BOOL = "bool", 
  NUMERIC = "numeric", 
  DECIMAL = "decimal", 
  SELECT = "select"
};

interface AssemblyImportFailure {
  id: number
  message: string
}

interface CategoryFormValues {
  metadata: CategoryMetadata[];
  allowMultiple: boolean;
  name: string
  dependencyRules?: string; //CategoryRuleConfig[]
  configuratorSection: string
  quoteSection?: string;
  nonDiscounted: boolean;
  markup?: number;
  nonDiscountedType?: NON_DISCOUNTED_TYPE;
  operationId: string | undefined;
  excludeERPExport: boolean;
  hidden?: boolean;
  authorizedCustomers?: string[];
  stock: boolean
  leadTimeDays: number
  applyDesignProcurementTime?: boolean;
  designTimeWeeks: number
  procurementTimeWeeks: number
  engineeringTeam?:EngineeringTeamInfo | undefined
  primaryEngineer?:User | undefined
  bomLocation?:boolean | undefined
}


const CategoryView = () => {
  const params = useParams<Record<string,string>>();
  const categoryId = Number(params.id);
  const configurator = useContext(ConfiguratorContext);
  const [category, categoryAsync] = useAsyncState<Category>();
  const [_categoryLst, categoryLstAsync] = useAsyncState<Category[]>();
  const [operationLst, operationLstAsync] = useAsyncState<Operation[]>([]);
  const [exportProgress, setExportProgress] = useState<number|undefined>();
  const [uploadFile, setUploadFile] = useState<{status:string, response?:AssemblyImportFailure[]}>({status: UPLOADING_STATUS.INIT});
  const [tabKeyParam, setTabKey] = useQueryParam<string | undefined | null >("tab", StringParam);

  const history = useHistory();
  const intl = useIntl();

  const [form] = useForm();

  //todo - remove this when dependencies or shouldUpdate works
  const metadata = Form.useWatch("metadata", form);
  const hidden = Form.useWatch("hidden", form);
  const nonDiscounted = Form.useWatch("nonDiscounted", form);
  const applyDesignAndProcurementTime = Form.useWatch("applyDesignProcurementTime", form);

  useEffect(() => {
    loadOperations();
  }, []);

  useEffect(() => {
    loadCategory(Number(categoryId));
  }, [categoryId]);

  useEffect(() => {
      form.resetFields();
  }, [category]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const handleDragEnd = (fields:FormListFieldData[], move: (from: number, to: number) => void ) => {

    return (event:DragEndEvent) => {
      const {active, over} = event;

      //do nothing if over is null/undefined
      if ( !over ) return;

      //if same spot do nothing
      if (active.id === over.id) return;

      const oldIndex = fields.findIndex(f => f.key == active.id);
      const newIndex = fields.findIndex(f => f.key == over.id);
      move( oldIndex, newIndex );
    }
  }


  //todo - this is dependent on the database instance, remove DEFAULT_CATEGORY_ID
  //don't allow modifications to Default category
  const canWrite = configurator.hasPermission(Permission.ENGINEERING_WRITE) && categoryId !== DEFAULT_CATEGORY_ID;

  const loadCategory = async (id:number) : Promise<Category | undefined> => {

    try {
      categoryAsync.setLoading();
      const resp = await configurator.api.getCategory(id);

      const category = resp.data;
      categoryAsync.setDone(category);
      return category;

    } catch (e:any) {
      categoryAsync.setFail( e.message );
    }

    return;
  };

  const loadOperations = async () => {
    try {
      operationLstAsync.setLoading();
      const resp = await configurator.api.fetchAssemblyOperations();
      operationLstAsync.setDone(resp.data);
    } catch (e:any) {
      operationLstAsync.setFail( e.message );
    }
  };

  const onSaveForm = async (values:CategoryFormValues) => {
    if ( !category ) return;

    const { primaryEngineer, engineeringTeam, ...v } = values;
    const dto:UpdateCategoryRequest = {
      ...v,
      primaryEngineerId: primaryEngineer?.id,
      engineeringTeamId: engineeringTeam?.id,
    }

    //update sort order on save
    dto.metadata.forEach( (md, ndx) => {
      md.sortOrder = ndx;
    });

    dto.isCab = !!values.bomLocation;

    try {
      categoryAsync.setLoading();
      const resp = await configurator.api.updateCategory(category.id, dto );
      categoryAsync.setDone(resp.data);
      notification.success({message:"Saved successfully."});
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message })
      notification.error({message:"Failed to save at this time. " + errorMsg});
      categoryAsync.setFail( e.message );
    }
  };


  const onUploadChange = ({file}) => {
    setUploadFile( file );
    return file.response;
  };
  const isUploading = UPLOADING_STATUS.UPLOADING == uploadFile.status;

  const handleExportCsv = () => {
    if ( !category ) return;

    const url = configurator.api.getAssemblyCategoryCSVExportUrl(category.id)
    configurator.api.downloadCsv( url, undefined, setExportProgress )
    .catch((e) => {
      console.log( e );
      const errorMsg = intl.formatMessage({ id: e.message })
      notification.error( {message: "Export failed. " + errorMsg } );
    })
    .finally( () => {
      setExportProgress( undefined );
    });
  }

  const handleUpload = () : string  => {
    if (!category) return "";

    return configurator.api.getAssemblyCategoryCSVImportUrl(category.id);
  }

  if ( isNaN( categoryId )  ) {
    return <div className="site-layout-background">
      <Result
        status="error"
        title={"The category requested does not exist." }
        extra={ <Button onClick={history.goBack} type="primary" key="console"> Back </Button>
        }
      />
    </div>
  }

  const loadFailedMsg = ( categoryAsync.isFail() && !category ) ? categoryAsync.err
    : categoryLstAsync.isFail() ? categoryLstAsync.err
    : operationLstAsync.isFail() ? operationLstAsync.err 
    : undefined;
  if (loadFailedMsg ) {

    return <div className="site-layout-background">
      <Result
        status="error"
        title={"The category failed to load." }
        subTitle={ intl.formatMessage({ id: loadFailedMsg }) }
        extra={ <Button onClick={history.goBack} type="primary" key="console"> Back </Button> }
      />
    </div>
  }

  var dropdownItems = [
    {
      key: 1,
      label:<Button icon={<DownloadOutlined />}
        type="text"
                  onClick={handleExportCsv} 
                  loading={exportProgress != undefined}
      >
        Export Assemblies
      </Button> }
  ];

  if ( canWrite ) {
    dropdownItems.push({
      key: 2,
      label: <Upload
        name="file"
        action={handleUpload}
        onChange={onUploadChange}
        withCredentials={true}
        showUploadList={false}
      >
        <Button type="text" 
          icon={<UploadOutlined />}
          loading={isUploading}
          disabled={!category?.id || !canWrite}
          title={!canWrite ? "You need permission to upload." : ""}
        >
          Import Assemblies
        </Button>
      </Upload> });
  }

  const isMetadataSelect = (ndx:number) => {
    const key = ["metadata", ndx, "fieldType"];
    const fieldType = form.getFieldValue(key);
    return fieldType === FIELD_TYPES.SELECT
  }

  const isMetadataNew = (ndx:number) => {
    const key = ["metadata", ndx, "id"];
    const id = form.getFieldValue(key);
    return !!id;
  }

  const pageLoading = categoryAsync.isLoading() || categoryLstAsync.isLoading() || operationLstAsync.isLoading();
  return (
    <div className="site-layout-background">
      <div>
        <Title level={2}>View Category</Title>
        <Spin spinning={pageLoading}>
          <Row gutter={[16, 16]} style={{marginBottom: "1rem"}}>
            <Col>
              <Dropdown trigger={["click"]}
                menu={{items:dropdownItems}}
              >
                <Button type="primary" 
                  icon={<MoreOutlined />} 
                >Options</Button>
              </Dropdown>
            </Col>
            <Col>
              <Button type="primary" 
                onClick={()=>form.submit()}
                loading={categoryAsync.isLoading()}
                disabled={!canWrite}
                title={!canWrite ? "You need permission to save." : ""}
              >
                Save
              </Button>
            </Col>
          </Row>
          <Form form={form} 
            labelCol={{ flex: '13rem' }}
            labelAlign="right"
            labelWrap
            onFinish={onSaveForm} 
            disabled={!canWrite}
            initialValues={{
              ...category,
              //sort the metadata according to the sort order
              metadata: category?.metadata.sort((a,b) => (a.sortOrder || 0 ) - (b.sortOrder || 0) ),
              engineeringTeam: category?.engineeringTeam
            }}
          >
            <Tabs defaultActiveKey={tabKeyParam || undefined}
              onChange={setTabKey}>
              <Tabs.TabPane tab="Info" key="info" forceRender>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      rules={[{ required: true, message: "Name is required" }]}
                      label="Category Name"
                      name="name"
                      wrapperCol={{span: 16}}
                      labelCol={{span: 8}}
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                      rules={[{ required: true, message: "Section is required" }]}
                      label="Configurator Section"
                      name="configuratorSection"
                      wrapperCol={{span: 16}}
                      labelCol={{span: 8}}
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                      label="Quote Section"
                      name="quoteSection"
                      wrapperCol={{span: 16}}
                      labelCol={{span: 8}}
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                      label="Markup"
                      name="markup"
                      style={!nonDiscounted ? {display: 'none'} : {}}
                      wrapperCol={{span: 16}}
                      labelCol={{span: 8}}
                    >
                      <InputNumber formatter={(value) => `${value}%`.replace('-', '')} parser={(value) => value!.replace('%', '')} style={{width: "100%"}}/>
                    </Form.Item>
                    <Form.Item
                      label="Non-discounted Type"
                      name="nonDiscountedType"
                      style={!nonDiscounted ? {display: 'none'} : {}}
                      wrapperCol={{span: 16}}
                      labelCol={{span: 8}}
                    >
                      <Radio.Group disabled={!nonDiscounted}>
                        <Radio value={NON_DISCOUNTED_TYPE.NET} style={{marginBottom: ".5rem"}}>Net Price</Radio>
                        <Radio value={NON_DISCOUNTED_TYPE.POST_NET}>Post Net Price</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      name="operationId"
                      label="Operation"
                      wrapperCol={{span: 16}}
                      labelCol={{span: 8}}
                    >
                      <Select
                        showSearch
                        optionFilterProp="label"
                        options={operationLst?.map(o => ({label:o.operationId, value:o.operationId }))}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Authorized Dealer"
                      name="authorizedCustomers"
                      wrapperCol={{span: 16}}
                      labelCol={{span: 8}}
                    >
                      <DealerMultipleSelector disabled={!hidden}/>
                    </Form.Item>

                    {/*
                      //todo
                    <div>Standard Assembly!</div>
                    */}
                    <Form.Item
                      label="Lead Time (Days)"
                      name="leadTimeDays"
                      wrapperCol={{span: 16}}
                      labelCol={{span: 8}}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>

                    {/*
                      //todo
                    <div>Custom Options and Missing Selections!</div>
                    */}
                    <Form.Item
                      label="Design Time (Weeks)"
                      name="designTimeWeeks"
                      wrapperCol={{span: 16}}
                      labelCol={{span: 8}}
                    >
                      <InputNumber style={{ width: "100%" }} disabled={!applyDesignAndProcurementTime}/>
                    </Form.Item>
                    <Form.Item
                      label="Procurement Time (Weeks)"
                      name="procurementTimeWeeks"
                      wrapperCol={{span: 16}}
                      labelCol={{span: 8}}
                    >
                      <InputNumber style={{ width: "100%" }} disabled={!applyDesignAndProcurementTime}/>
                    </Form.Item>
                    <Form.Item
                      label="Primary Engineer"
                      name="primaryEngineer"
                      wrapperCol={{span: 16}}
                      labelCol={{span: 8}}
                    >
                      <UserSingleSelector />
                    </Form.Item>
                    <Form.Item
                      label="Engineering Team"
                      name="engineeringTeam"
                      wrapperCol={{span: 16}}
                      labelCol={{span: 8}}
                    >
                      <SelectEngineeringTeamsButtonModal category={category} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      valuePropName="checked"
                      label="Allow multiple"
                      name="allowMultiple"
                      labelCol={{span: 15}}
                    >
                      <Checkbox />
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      label="Non-discounted"
                      name="nonDiscounted"
                      labelCol={{span: 15}}
                    >
                      <Checkbox/>
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      label="Exclude from ERP export?"
                      name="excludeERPExport"
                      labelCol={{span: 15}}
                    >
                      <Checkbox />
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      label="Hide?"
                      name="hidden"
                      labelCol={{span: 15}}
                    >
                      <Checkbox/>
                    </Form.Item>
                    <Form.Item
                      label="Apply Design/Procurement Time"
                      name="applyDesignProcurementTime"
                      valuePropName="checked"
                      labelCol={{span: 15}}
                    >
                      <Checkbox/>
                    </Form.Item>
                    <Form.Item
                      label="Cab?"
                      name="bomLocation"
                      valuePropName="checked"
                      labelCol={{span: 15}}
                    >
                      <Checkbox/>
                    </Form.Item>

                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Metadata" key="metadata" forceRender>
                <Form.List name="metadata" >
                  {(fields, { add, remove, move }) => <>
                    <Title level={4}>
                      Metadata
                      <PlusCircleOutlined onClick={(_e) => add({}, 0 )} style={{paddingLeft: ".5rem"}}  />
                    </Title>
                    <DndContext 
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragEnd={handleDragEnd(fields, move)}
                    >
                      <SortableContext 
                        items={fields.map(f => f.key.toString())}
                        strategy={verticalListSortingStrategy}
                      >
                        {fields.map((f, fieldNdx) => <SortableItem key={f.key.toString()} id={f.key.toString()}>
                          <>
                          <Divider />
                            <Row justify={"space-between"} style={{width: "35rem"}}>
                              <Col><MenuOutlined /></Col>
                              <Col><DeleteOutlined onClick={() => remove(fieldNdx)} /></Col>
                            </Row>

                            <Form.Item
                              name={[f.name, "id"]}
                              hidden={true}
                            >
                              <Input  style={{ width: 300 }} />
                            </Form.Item>

                            <Form.Item
                              name={[f.name, "sortOrder"]}
                              hidden={true}
                            >
                              <Input  style={{ width: 300 }} />
                            </Form.Item>

                            <Form.Item
                              rules={[{ required: true, message: "Name is required" }]}
                              label="Metadata Name"
                              name={[f.name, "name"]}
                            >
                              <BMReadOnly readOnly={isMetadataNew(f.name)} >
                              <Input  style={{ width: 300 }} />
                              </BMReadOnly>
                            </Form.Item>
                            <Form.Item
                              label="Field Type"
                              name={[f.name, "fieldType"]}
                              rules={[{ required: true, message: "Field Type is required" }]}
                            >
                              <BMReadOnly readOnly={isMetadataNew(f.name)} >
                                <Select
                                  style={{ width: 300 }}
                                  optionFilterProp="label"
                                  options={Object.values(FIELD_TYPES)?.map(fd => ({label:fd, value:fd }))}
                                />
                              </BMReadOnly>
                            </Form.Item>
                            <Form.Item shouldUpdate
                              label="Options"
                              name={[f.name, "selectOptions"]}
                              hidden={!isMetadataSelect(f.name)}
                            >
                              <Select 
                                style={{ width: 300 }}
                                optionFilterProp="label"
                                mode="tags" />
                            </Form.Item>
                            <Form.Item
                              valuePropName="checked"
                              label="Visible in Configurator"
                              name={[f.name, "visibleInConfigurator"]}
                            >
                              <Checkbox />
                            </Form.Item>
                          </>
                        </SortableItem>)}
                      </SortableContext>
                    </DndContext>

                  </>}
                </Form.List>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Rules" key="rules" forceRender>
                <Title level={3}>Category Rules </Title> 
                <Alert type="info" message={<>The rules have been moved <Link to="/rule-sets">here</Link></>}/>
              </Tabs.TabPane>
            </Tabs>
          </Form>
        </Spin>
      </div>

      <Modal 
        open={uploadFile.status == UPLOADING_STATUS.DONE || uploadFile.status === UPLOADING_STATUS.ERROR}
        onOk={() => setUploadFile({status: UPLOADING_STATUS.INIT})}
        onCancel={() => setUploadFile({status: UPLOADING_STATUS.INIT})}
        cancelButtonProps={{style :{display : 'none'}}}
        style={{minWidth: "40rem"}}
      >
        <Result
          status={uploadFile.status == UPLOADING_STATUS.DONE ? "success" : "error"}
          title={uploadFile.status == UPLOADING_STATUS.DONE ? "Upload Successful" : "Error"}
          subTitle={ !!uploadFile?.response?.length && <div style={{textAlign: "left"}}>
            <div>The following records had errors:</div>
            <ul>
              {uploadFile?.response?.map( (err,ndx) => <li key={`upload-msg-${ndx}`}>{err.message}</li>)}
            </ul>
          </div> }
        />
      </Modal>
    </div>
  );
};

export const SortableItem = (props:{
  id:string
  children:ReactElement
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.children}
    </div>
  );
}

export default CategoryView;

