import { Table } from "antd";
import Utils from "../../util/util";
import { Link } from "react-router-dom";
import { ColumnType, FilterValue, SortOrder, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import { Assembly, CategoryFilter, CategoryMetadata, FilterOption } from "../../api/models";


const AssembliesTable = (props: {
  categoryFilter: CategoryFilter | undefined,
  dataSource: Assembly[] | undefined,
  pagination: TablePaginationConfig,
  filterOptions: FilterOption[],
  loading?:boolean,
  tableOnChange: (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[] | undefined) => void,
  getSortingOrder: (val: string) => SortOrder | undefined,
  metadataValueSorting: (a: string | number | undefined, b: string | number | undefined) => number,
  filteredInfo: any,
}) => {

  type AssemblyColumn = ColumnType<Assembly>
  const {categoryFilter, dataSource, pagination, tableOnChange, getSortingOrder, filteredInfo, filterOptions, metadataValueSorting} = props;

  const getLabel = (assembly:Assembly) => {
    return assembly.label != null && assembly.label.length > 0
      ? assembly.label
      : assembly.bomDescription;
  };

  let columns: AssemblyColumn[] = [
    {
      title: "BOM",
      key: "bom",
      render: (asm) => (
        <span>
          {asm.bom}
          {asm.obsoletedAt != null ? " (Obsolete)" : ""}
        </span>
      ),
      sorter: {
        multiple: 1
      },
      sortOrder: getSortingOrder("bom")
    },
    {
      title: "Name",
      key: "name",
      render: (assembly) => (
        <Link to={"/assemblies/" + encodeURIComponent(assembly.id)}>
          {getLabel(assembly)}
        </Link>
      ),
      sorter: {
        multiple: 2
      },
      sortOrder: getSortingOrder("name")
    },
  ];

  const getColumnForMetadata = ( md:CategoryMetadata ) : AssemblyColumn => {

    const allFilters = filterOptions.find(fo => fo.mdId === md.id)?.filterOptions || [];

    return ({
      title: md.name || '',
      key: "md-" + md.id,
      render: (assembly) => {
        return (
          <span>
            {Utils.getMetadataValue(
              assembly.metadata.find((m) => m.categoryMetadata.id == md.id)
            ) || ""}
          </span>
        );
      },
      sorter: (asmA, asmB) => {

        const [ aVal, bVal ] = [ asmA, asmB ]
        .map( asm => asm.metadata.find( md1 => md1.categoryMetadata.id == md.id) )
        .map( md => Utils.getMetadataValue( md ) );

        return metadataValueSorting(aVal, bVal);
      },

      sortOrder: getSortingOrder("md-" + md.id),

      filters: allFilters.map((f) => {
        return { value: f, text: f };
      }),

      filterSearch: true,

      filteredValue: filteredInfo["md-" + md.id] || null,

      onFilter: (value, record ) => {
        const m = record.metadata.find( md1 => md1.categoryMetadata.id == md.id );
        const metaValue = Utils.getMetadataValue(m);

        // Should fix the data to assign valueBool meta for all metadata with false
        if (metaValue === "True" && value === "true") return true;
        if (metaValue == undefined && value === "false") return true;

        return metaValue == value;
      },

    });
  }

  const combineColumns = (cols1: AssemblyColumn[], cols2: AssemblyColumn[]) => {
    return cols1.concat(cols2);
  }

  const sortColumnByCategoryMetadataOrder = (a: CategoryMetadata | undefined, b: CategoryMetadata | undefined) => (a?.sortOrder || 0) - (b?.sortOrder || 0);

  const metadataColumns = categoryFilter?.metadata?.sort(sortColumnByCategoryMetadataOrder).map( md  => getColumnForMetadata( md ) ) || [];

  const finalColumns = combineColumns(columns, metadataColumns);

  return (
    <>
      <style>
        {`
          .ant-table-cell {
            background-color: white !important;
          }
        `}
      </style>
      <Table
        onChange={tableOnChange}
        pagination={pagination}
        bordered
        rowKey="id"
        columns={finalColumns}
        dataSource={dataSource}
        loading={props.loading}
      />
    </>
  );
}

export default AssembliesTable;
